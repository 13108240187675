<template>
  <div class="banner">
    <div class="swiper-container swiper-container1">
      <div class="swiper-wrapper">

        <div class="swiper-slide">
          <img src="../../../assets/img/sch.png">
        </div>
      </div>
    </div>
  </div>

  <div class="about">
    <div class="about-top">
      <p>{{ this.$MyConstant('Api_CONSTANT.menu.PublicMenu.famous.name_'+MY_CONSTANT.sqlWhereLang) }}</p>
      <img src="../../../assets/img/icon/jiantou.png">
      <em>{{this.$route.params.year}}{{ this.$MyConstant('Api_CONSTANT.menu.PublicMenu.famous.name_'+MY_CONSTANT.sqlWhereLang) }}</em>

    </div>
    <div class="contentMiddle">
      <!-- 左边 -->
      <div class="contentLeft">


        <!--        <el-select-v2-->
        <!--            v-model="value"-->
        <!--            style="width: 240px"-->
        <!--            multiple-->
        <!--            filterable-->
        <!--            remote-->
        <!--            :remote-method="fetchData"-->
        <!--            clearable-->
        <!--            :options="options"-->
        <!--            :loading="loading"-->
        <!--            placeholder="Please enter a keyword"-->
        <!--        />-->

        <div class="famous-left">

          <div class="list-top" style="font-family: 'CN-Medium';">

            <input name="cp_name" v-model="keyword" type="text" id="cp_name" value="" :placeholder="this.$MyConstant('Lang.CompanyName')">
            <select v-if="this.$MyConstant('sqlWhereLang')=='cn'" id="company_type" name="company_type" v-model="nature">
              <option value="">--{{ this.$MyConstant('Lang.CompanyProperty') }}--</option>
              <option  :value="item.nature" v-for=" item in this.$MyConstant('Api_CONSTANT.DimensionLists.company_nature')">
                {{item.nature}}
              </option>
            </select>

            <select v-if="this.$MyConstant('sqlWhereLang')=='en'" id="company_type" name="company_type" v-model="nature">
              <option value="">--{{ this.$MyConstant('Lang.CompanyProperty') }}--</option>
              <option  value="State-Owned Enterprises">State-Owned Enterprises</option>
              <option  value="Domestic-Funded Private Enterpries">Domestic-Funded Private Enterpries</option>
              <option  value="Sino-Foreign Equity Joint Ventures">Sino-Foreign Equity Joint Ventures</option>
              <option  value="Wholly Foreign-Owned Enterprises">Wholly Foreign-Owned Enterprises</option>
              <option  value="Hong Kong, Macao, Taiwan-Invested Enterprises">Hong Kong, Macao, Taiwan-Invested Enterprises</option>
            </select>

            <select id="hall" v-model="hall" name="hall">
              <option value="">--{{ this.$MyConstant('Lang.hall') }}--</option>
              <option value="1" selected="selected">1{{ this.$MyConstant('Lang.hallNum') }}</option>
              <option value="2">2{{ this.$MyConstant('Lang.hallNum') }}</option>
              <option value="3">3{{ this.$MyConstant('Lang.hallNum') }}</option>
              <option value="4">4{{ this.$MyConstant('Lang.hallNum') }}</option>
              <option value="5">5{{ this.$MyConstant('Lang.hallNum') }}</option>
              <option value="6">6{{ this.$MyConstant('Lang.hallNum') }}</option>
              <option value="7">7{{ this.$MyConstant('Lang.hallNum') }}</option>
              <option value="8">8{{ this.$MyConstant('Lang.hallNum') }}</option>
              <option value="9">9{{ this.$MyConstant('Lang.hallNum') }}</option>
              <option value="10">{{ this.$MyConstant('Lang.FloorTwo') }}</option>
              <option value="11"> {{ this.$MyConstant('Lang.hall5Two') }}</option>
              <option value="12"> {{ this.$MyConstant('Lang.hall6Two') }}</option>
            </select>

            <!-- <em class="sub_search">查询</em> -->
            <button style="font-size: 17px;" @click="handleSearchClick" class="sub_search" name="sub">{{ this.$MyConstant('Lang.select') }}</button>


          </div>



          <h2 v-if="RExhibitors.length > 0">{{ this.$MyConstant('Lang.RecommendedExhibitors') }}</h2>
          <div v-if="RExhibitors.length > 0" class="list-muem">
            <ul>
              <li v-for="item in RExhibitors">
                <custom-link :to="'/FamousInfo/'+this.$route.params.year+'/'+this.$encryptString(item.user_id)">
                  {{ this.$MyConstant('sqlWhereLang')=='cn'?(item.cp_name||item.cp_en_name)  :(item.cp_en_name||item.cp_name)}}
                </custom-link>
              </li>
            </ul>
          </div>



          <h2>{{ this.$MyConstant('Lang.ExhibitorList') }}</h2>

          <div class="list-muem">
            <ul>
              <li v-for="item in Exhibitors">
                <custom-link :to="'/FamousInfo/'+this.$route.params.year+'/'+this.$encryptString(item.user_id)">
                  {{ this.$MyConstant('sqlWhereLang')=='cn'?(item.cp_name||item.cp_en_name)  :(item.cp_en_name||item.cp_name)}}
                </custom-link>
              </li>
            </ul>
          </div>

          <div class="pages">
            <a @click="OnPage(backPage)"><span class="back"></span></a>
            <a :class="{'current':item==page}" @click="OnPage(item)" v-for="item in pageNum">{{item}}</a>
            <a @click="OnPage(nextPage)">
              <span class="next"></span>
            </a>
          </div>

        </div>
      </div>

      <!-- 右边 -->
      <div class="about-right">
        <div class="about-right1" v-for="(item,key) in this.$MyConstant('Api_CONSTANT.menu.PublicMenu.famous.list')">
          <custom-link :style="{'color': this.$route.fullPath == '/'+MY_CONSTANT.setApiLang+item.url ? 'var(--uavdeep-color)' : ''}" :to="item.url">
            <img src="../../../assets/img/icon/sanjiao.png">
            <em>{{ item['name_'+MY_CONSTANT.sqlWhereLang] }}</em>
          </custom-link>
        </div>

        <Components :datas= '{
        "Template/About-right": "",
         }'>
        </Components>

      </div>

    </div>

  </div>

</template>

<script>
import Components from '../../../modules/Components.vue'
import api from "@/assets/js/api";
export default {
  components: {
    Components
  },
  data() {
    return {
      DownloadList:[],
      nature:this.$route.query.nature??'',
      hall:this.$route.query.hall??'',
      value: [], // 选中的值
      options: [], // 下拉选项
      loading: false, // 加载状态
      RExhibitors:[],
      Exhibitors:[],
      keyword:this.$route.query.keyword??'',
      page:this.$route.query.page??1,
      limit:12,
      limit_page:10,
      backPage:1,
      nextPage:2,
      pageNum:'',
    }
  },
  async created() {
    this.RExhibitors = await api.ajaxbaseUrlData('/api/User/InvitationLetterListSearch',{filter:JSON.stringify({is_recommend:1})});

  },
  watch: {
    '$route': {
      immediate: true, // 初始化时立即执行
      async handler() {
        this.limit_page=10
        this.fetchData()

      }
    }
  },
  methods: {
    async fetchData() {
      // cat_id:this.$route.query.cat_id??false,
      let where=this.$getApiWhere({
        limit: this.limit,
        page: this.page,
        order: '{"user_id":"desc"}',
        filter:'{"cp_name|cp_en_name":"' + this.keyword + '","cpse_exhibition.hall":"' + this.hall + '","nature":"' + this.nature + '"}',
        op: '{"cp_name|cp_en_name":"%*%","hall":"=","nature":"="}',
      });
      if(this.$route.params.year){ where['year']=this.$route.params.year;}
      const response = await      api.ajaxbaseUrlget(`/api/User/InvitationLetterListSearch`,where).finally(() => {
        // 请求完成后，设置加载状态为 false
        this.loading = false;
      });
      // if(response.data.obj.data.length==0){
      //
      //   this.$message.warning({
      //     message: '暂无数据',
      //     offset:400,duration:3000
      //   });
      //   return false;
      // }
      this.Exhibitors = response.data.obj.data;
      // this.count = response.data.obj.data.totalCount
      let  all_page = Math.ceil(response.data.obj.totalCount/this.limit);//总页数  向上取余ceil()  向下取余floor()

      if(all_page < this.limit_page){
        //如果总页数小于页面显示的页码数  则页面显示的页码数=总页数
        this.limit_page = all_page;
      }

      let  floor_page = Math.floor(this.limit_page/2);//求得中间页 向下取余floor()  2 23 4 56
      let start1_page = this.page - floor_page;//4 - 2
      let  end_page = this.page + floor_page;//4 + 2

      if(start1_page < 1){
        //如果假如 左边的页码小于1 那我们就从1开始显示$limit_page
        start1_page = 1;
        end_page = this.limit_page;

      }

      if(end_page > all_page){
        //如果假如 右边的页码大于我们的总页码  那我们就从总页码开始-显示页+1
        end_page = all_page;
        start1_page = all_page - this.limit_page +1;
      }

      let  pageNum=[];
      this.backPage = this.page-1==0?1:this.page-1;

      let i,page=0;
      for(i=start1_page;i<=end_page;i++){
        pageNum[page++]=i
      }
      this.pageNum=pageNum
      this.nextPage = (all_page-this.page==0)?all_page:(this.page+1);

    }
    ,
    OnPage(page){
      this.page=page
      this.fetchData();
    }
    ,
    handleSearchClick() {
      this.page=1
      this.limit_page=10
      // alert(this.keyword.trim());
      // if (this.keyword.trim() !== "") {
      this.fetchData()
      // }
    }

  }
};
</script>
<style scoped>
.famous-left  h2{
  opacity: 1;
  font-size: 21px;
  color: #8b8b8b;
  font-weight: 500;

  color: #000000;
  line-height: 35px;
  padding-bottom:9px;
  border-bottom: 2px solid #BEBEBE;
  margin-top: 30px;
  margin-bottom: 30px;
}

.famous-left .list-top label{
  margin-right: 17px;
}
.famous-left .list-top input{
  width: 160px;
  height: 35px;
  opacity: 1;
  margin-right: 15px;
  outline: none;
  font-size: 17px;

  font-weight: 500;

  color: #8b8b8b;
  line-height: 35px;

}
.famous-left .list-top select{
  width: 160px;
  height: 39px;
  opacity: 1;
  margin-right: 15px;
  outline: none;

  font-size: 17px;

  font-weight: 500;
  color: #8B8B8B;
  line-height: 35px;

}
.famous-left .list-top select option{


}
.famous-left .list-top .sub_search{
  width: 160px;
  height: 39px;
  opacity: 1;

  margin-right: 0px!important;
  background-color: var(--uavdeep-color);
  color: #fff;
  outline: none;
  border: none;
  cursor: pointer;


}



.news-search input {
  width: 246;
  height: 24px;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
}

.news-search img {
  position: absolute;
  top: 9px;
  right: 6px;
}

.list-muem ul{
  display: flex;
  flex-wrap: wrap;
}
.list-muem li{
  width: 32%;
  padding: 10px 0;
  border-bottom: 1.5px solid #909090;
  padding-right: 10px;
}
.list-muem li a{

  opacity: 1;
  font-size: 15px;

  font-weight: 500;

  color: #8b8b8b;
  line-height: 32px;


}
.list-muem li a:hover{
  color: #AF1E22;
}


/* 翻页 */
.pages {
  text-align: center;
  padding: 40px 0 20px 0px;
  font-family: arial;
  margin-top: 40px;
}

.pages a {
  display: block;
  float: left;
  margin: 0 3px;
  width: 25px;
  height: 25px;
  line-height: 25px;
  background: #ededed;
  font-size: 14px;
}

.pages a .back,
.pages a .next {
  background: #686765 url(../../../assets/img/icon/icon2.png) no-repeat;
  display: block;
  width: 25px;
  height: 25px;
}

.pages a:hover .back,
.pages a:hover .next {
  background: #cc0000 url(../../../assets/img/icon/icon2.png) no-repeat;
}

.pages a .back,
.pages a:hover .back {
  background-position: 6px -311px;
}

.pages a .next,
.pages a:hover .next {
  background-position: 7px -339px;
}

.pages a.current {
  color: #fff;
  background: #c5c5c5;
}


</style>
