import { MY_CONSTANT }  from './constants.js';
import { createStore } from 'vuex';
const store = createStore({
  state: {
    Search: {Keyword:'',useTemplate:'default'},
    MY_CONSTANT: MY_CONSTANT,
  },
  mutations: {
   //搜索数组
    Search (state,value) {
      state.Search=value
    },

    updateMY_CONSTANT(state, payload) {

      for (let key in payload) {
        if (key.includes('.')) {
          const keys = key.split('.');
          let obj = state.MY_CONSTANT;
          for (let i = 0; i < keys.length - 1; i++) {
            obj = obj[keys[i]];
          }
          obj[keys[keys.length - 1]] = payload[key];
        } else {
          state.MY_CONSTANT[key] = payload[key];
        }
      }

    },
    //改变语言数组
    async  updateMY_CONSTANTLang(state, lang) {

      store.commit('updateMY_CONSTANT',{
        'Lang':await import(`@/lang/${lang}.json`) ,
        'setApiLang':lang,
        'Api_CONSTANT.baseApiHeaders.Accept-Language':lang,
        'sqlWhereLang':lang == 'zh-cn' ? 'cn' : 'en'
      });

    }

    ,

    decrement(state) {
      state.count--
    }

    ,
    // 添加一个mutation，用于从localStorage中恢复状态
    restoreState(state, savedState) {
      state.count = savedState.count
      state.count2 = savedState.count2
    }
    // 在这里定义你的mutations
  },
  actions: {

    asyncIncrement({ commit }) {
      setTimeout(() => {
        commit('increment')
      }, 1000)
    },
    asyncDecrement({ commit }) {
      setTimeout(() => {
        commit('decrement')
      }, 1000)
    }
    ,
    // 添加一个action，用于保存状态到localStorage
    saveState({ state }) {
      localStorage.setItem('store', JSON.stringify(state))
    }
    ,
    // 添加一个action，用于从localStorage中恢复状态
    restoreState({ commit }) {
      const savedState = JSON.parse(localStorage.getItem('store'))
      if (savedState) {
        commit('restoreState', savedState)
      }
    }
    // 在这里定义你的actions
  },
  getters: {
    // 在这里定义你的getters
  }
});


// 在应用程序启动时，从localStorage中恢复状态
store.dispatch('restoreState')


// 在应用程序关闭时，保存状态到localStorage
window.addEventListener('beforeunload', () => {
  store.dispatch('saveState')
})

export default store;
