<template>
  <div class="banner">
    <div class="swiper-container swiper-container1">
      <div class="swiper-wrapper">

        <div class="swiper-slide">
          <img src="../../../assets/img/sch.png">
        </div>
      </div>
    </div>
  </div>

  <div class="about">
    <div class="about-top">
      <p>{{ MY_CONSTANT.Api_CONSTANT.menu.PublicMenu.news['name_'+MY_CONSTANT.sqlWhereLang]}}</p>
      <img src="../../../assets/img/icon/jiantou.png">
      <em>{{MY_CONSTANT.Api_CONSTANT.menu.PublicMenu.news.list[this.$route.query.cat_id==3?'tonggao':'news']['name_'+MY_CONSTANT.sqlWhereLang]}}</em>

    </div>
    <div class="about_news-bottom middle">

      <!-- 左边 -->
      <div class="news-left">


        <ul>
          <li>
            <div class="news-search">
<!--              搜索最新动态-->
              <input v-model="keyword" id=""
                     :placeholder="this.$route.query.cat_id==3?
                     MY_CONSTANT.Lang.search+MY_CONSTANT.Api_CONSTANT.menu.PublicMenu.news.list.tonggao['name_'+MY_CONSTANT.sqlWhereLang]
                     :MY_CONSTANT.Lang.search+MY_CONSTANT.Api_CONSTANT.menu.PublicMenu.news.list.news['name_'+MY_CONSTANT.sqlWhereLang]" value="">

                <img @click="handleSearchClick"  src="../../../assets/img/shouye/search.png">

            </div>
          </li>

          <li class="first-news" v-for="item in newsItemstop">
            <div class="first-news1">
              <custom-link :to="'/NewsInfo/' + item.article_id">
              <img :src="this.$MyConstant('StaticPath') + item.pic" alt="图片">
              </custom-link>
            </div>


            <div>
              <em>{{ this.$formatTime(item.add_time) }}</em>
              <custom-link :to="'/NewsInfo/' + item.article_id">
                {{item.title??''}}
              </custom-link>
              <custom-link class="more" :to="'/NewsInfo/'+item.article_id">
                查看详情
                <img src="../../../assets/img/icon/yjiantou.png">
              </custom-link>
            </div>
          </li>


          <li class="news-time" v-for="item in newsItems">
            <custom-link :to="'/NewsInfo/'+item.article_id">
              <span>{{ item.title  }}</span>
              <em>{{ this.$formatTime(item.add_time) }}</em>
            </custom-link>
          </li>




        </ul>

        <div class="pages">
          <a @click="OnPage(backPage)"><span class="back"></span></a>
          <a :class="{'current':item==page}" @click="OnPage(item)" v-for="item in pageNum">{{item}}</a>
          <a @click="OnPage(nextPage)">
            <span class="next"></span>
          </a>
        </div>
      </div>

      <!-- 右边 -->
      <div class="about-right">
        <div class="about-right1" v-for="(item,key) in menu">
        <custom-link :style="{'color': this.$route.fullPath == '/'+MY_CONSTANT.setApiLang+item.url ? 'var(--uavdeep-color)' : ''}" :to="item.url">
            <img src="../../../assets/img/icon/sanjiao.png">
            <em>{{ item['name_'+MY_CONSTANT.sqlWhereLang] }}</em>
          </custom-link>
        </div>

        <Components :datas= '{
        "Template/About-right": "",
         }'>
        </Components>

      </div>

    </div>

  </div>

</template>

<script>
import Components from '../../../modules/Components.vue'
import api from "@/assets/js/api";
export default {
  components: {
    Components
  },
  data() {
    return {
       newsItemstop:[],
       newsItems:[],
       keyword:this.$route.query.keyword??'',
       page:1,
       limit:11,
       limit_page:10,
       backPage:1,
       nextPage:2,
       pageNum:'',
       menu:this.$MyConstant('Api_CONSTANT.menu.PublicMenu.news.list')
    }
    },
  // created() {
  //
  //   this.fetchData()
  // },
  watch: {
    '$route': {
      immediate: true, // 初始化时立即执行
      async handler() {
        this.keyword=this.$route.query.keyword??''
        this.fetchData()

      }
    },
    'MY_CONSTANT.sqlWhereLang': {
      handler() {
        // 当路由参数变化时，重新请求接口并更新内容
        this.page=1
        this.limit_page=5
        this.fetchData();
      }
    }
  },
  methods: {
    async fetchData() {

      // cat_id:this.$route.query.cat_id??false,
      let where=this.$getApiWhere({
        limit: this.limit,
        page: this.page,
        order: '{"sort":"desc","add_time":"desc"}',
        filter: this.keyword ? '{"title|description|content":"' + this.keyword + '","lang":"'+this.$MyConstant('sqlWhereLang')+'"}' : '{"lang":"'+this.$MyConstant('sqlWhereLang')+'"}',
        op: this.keyword ? '{"title|description|content":"%*%","lang":"="}' : '',
      });
      where['cat_id']=this.$route.query.cat_id??false;

      const response = await api.getNewsData(where);
      if(response.data.obj.data.length==0){

        this.$message.warning({
          message: this.MY_CONSTANT.Lang.Nodata,
          offset:400,duration:3000
        });
        // return false;
      }
      this.newsItemstop=response.data.obj.data.slice(0,1);
      this.newsItems = response.data.obj.data.slice(1, 10);
      this.count = response.data.obj.data.totalCount
      let  all_page = Math.ceil(response.data.obj.totalCount/this.limit);//总页数  向上取余ceil()  向下取余floor()
      if(all_page < this.limit_page){
        //如果总页数小于页面显示的页码数  则页面显示的页码数=总页数
        this.limit_page = all_page;
      }
      let  floor_page = Math.floor(this.limit_page/2);//求得中间页 向下取余floor()  2 23 4 56
      let start1_page = this.page - floor_page;//4 - 2
      let  end_page = this.page + floor_page;//4 + 2

      if(start1_page < 1){
        //如果假如 左边的页码小于1 那我们就从1开始显示$limit_page
        start1_page = 1;
        end_page = this.limit_page;

      }
      if(end_page > all_page){
        //如果假如 右边的页码大于我们的总页码  那我们就从总页码开始-显示页+1
        end_page = all_page;
        start1_page = all_page - this.limit_page +1;
      }

      let  pageNum=[];
      this.backPage = this.page-1==0?1:this.page-1;
      let i,page=0;
      for(i=start1_page;i<=end_page;i++){
        pageNum[page++]=i
      }
      this.pageNum=pageNum
      this.nextPage = (all_page-this.page==0)?all_page:(this.page+1);

    }
    ,
    OnPage(page){
      this.page=page
      this.fetchData();
    }
    ,
    handleSearchClick() {
      // alert(this.keyword.trim());
      if (this.keyword.trim() !== "") {
        this.fetchData()
      }
    }
  }
};
</script>
<style scoped>


/* 2.最新动态 about_news*/
.about_news-bottom {
  display: flex;
  justify-content: space-between;
  margin-bottom: 134px;
}

.about_news-bottom .news-left {
  width: 75%;
  margin-top: 54px;
}

.about_news-bottom .news-left ul .first-news {
  display: flex;
  /* margin-bottom: 75px; */
  justify-content: space-between;
}

/* 最新动态搜索*/
.news-left ul li:first-child{
  /* background-color: #007AFF; */
  display: flex;
  /* justify-content: flex-end; */

}
.news-search{
  position: relative;
  padding: 6px 40px 6px 6px;
  /* background-color: #007AFF; */
  border: 1px solid #c5c5c5;

  margin-bottom: 30px;

}

.news-search input {
  width: 246;
  height: 24px;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
}

.news-search img {
  position: absolute;
  top: 9px;
  right: 6px;
}

.news-left ul .first-news div  {
  display: flex;
  flex-direction: column;
  width: 349px;
  height: 234px;


  margin-bottom: 30px;
}
.news-left ul .first-news div img {
  width: 100%;
  /* height: 234px; */
}



.news-left ul .first-news div em {
  margin-bottom: 20px;

  font-size: 13px;

  font-weight: 500;

  color: #8b8b8b;

}

.news-left ul .first-news div a {

  font-size: 21px;

  font-weight: 500;

  color: #b01e22;
  line-height: 32px;
  margin-bottom: 15px;

}

.news-left ul .first-news div .more {

  font-size: 13px;

  font-weight: 500;
  text-align: justifyLeft;
  color: #bebebe;
  line-height: 32px;
  display: block;
}

.news-left ul .first-news div .more img {
  width: 18px;
  height: 18px;
  margin-left: 9px;
  background-color: #000;
  border-radius: 50%;
  margin-bottom: -4px;
}

.news-left ul .first-news div .more:hover {

  font-weight: 500;
  color: #AF1E22;

}

.news-left ul .first-news div .more:hover img {
  width: 18px;
  height: 18px;
  margin-left: 9px;
  background-color: #AF1E22;
  border-radius: 50%;
  margin-bottom: -4px;

}

.about_news-bottom .news-left .news-time {}

.news-left .news-time a {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #afafaf;
  padding: 14px 0;
}

.news-left .news-time a span:hover {
  color: #AF1E22;
}

.news-left .news-time a em:hover {
  color: #AF1E22;
}

.news-left .news-time a span {

  font-size: 13px;

  font-weight: 500;

  color: #bebebe;
  line-height: 32px;
}

.news-left .news-time a em {

  font-size: 13px;

  font-weight: 500;

  color: #bebebe;
  line-height: 32px;
}

/* 翻页 */
.pages {
  text-align: center;
  padding: 40px 0 20px 0px;
  font-family: arial;
  margin-top: 40px;
}

.pages a {
  display: block;
  float: left;
  margin: 0 3px;
  width: 25px;
  height: 25px;
  line-height: 25px;
  background: #ededed;
  font-size: 14px;
}

.pages a .back,
.pages a .next {
  background: #686765 url(../../../assets/img/icon/icon2.png) no-repeat;
  display: block;
  width: 25px;
  height: 25px;
}

.pages a:hover .back,
.pages a:hover .next {
  background: #cc0000 url(../../../assets/img/icon/icon2.png) no-repeat;
}

.pages a .back,
.pages a:hover .back {
  background-position: 6px -311px;
}

.pages a .next,
.pages a:hover .next {
  background-position: 7px -339px;
}

.pages a.current {
  color: #fff;
  background: #c5c5c5;
}


</style>
