<template>

  <Components :datas='{
        "Template/Header": "",
      }'>
  </Components>

  <div class="loginContent">
  <div class="title">
    {{ MY_CONSTANT.Api_CONSTANT.menu.Usermenu.visitor_info.list.edit_password['name_'+MY_CONSTANT.sqlWhereLang] }}
  </div>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm"  class="demo-ruleForm" id="register_form">

          <el-form-item label="E-mail：" prop="email">
            <el-input class="input400" v-model="ruleForm.email" :placeholder="this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.Email')"></el-input>
          </el-form-item>


          <el-form-item :label="this.$MyConstant('Lang.VerificationCode')+'：'" prop="code">
            <div class="form-container" style="display: flex;align-items: center;">
            <el-input class="input150" v-model="ruleForm.code" :placeholder="this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.VerificationCode')"></el-input>
            <el-image @click="getCaptchaImage" style="display: inline-block; margin-left: 10px;width: 150px;height: 53px;
    top: -14px;" :src="captchaImage" fit="cover" :alt="this.$MyConstant('Lang.VerificationCode')"></el-image>
            </div>
          </el-form-item>


          <el-form-item :label="'\n'" >

            <el-button style="width: 100%;margin-top:41px;"  size="large"  @click="submitForm('ruleForm')" >{{ MY_CONSTANT.Lang.submit }}</el-button>
          </el-form-item>

          <div style="margin-top: 20px;">
            <custom-link  to="/UserLogin?s=0VtfsSftfswf" >{{ MY_CONSTANT.Lang.login }}</custom-link>
            <el-text style="padding:0px 10px; ">|</el-text>
            <custom-link to="/UserRegister">{{ MY_CONSTANT.Lang.register }}</custom-link>
<!--            <el-text style="float: right;" @click="setLang(MY_CONSTANT.setApiLang=='zh-cn'?'en-us':'zh-cn')"><el-icon><Switch /></el-icon>-->
<!--              {{ MY_CONSTANT.sqlWhereLang=='cn'?'EN':'中文' }}</el-text>-->
          </div>


        </el-form>
  </div>

  <br>
  <Components :datas='{
        "Template/Footer": "",
      }'>
  </Components>

</template>

<script>
import Components from '../../../modules/Components.vue'
import api from "@/assets/js/api";
export default {
  components: {
    Components
  },
  data() {

    return {
      captchaImage: '',
      ruleForm: {
        email:'',
        code: '',
      },
      rules: {
        email: [
          { required: true, message:()=> this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.Email'), trigger: 'blur'},
          { type: 'email', message:()=> this.$MyConstant('Lang.PleaseCorrect')+this.$MyConstant('Lang.Email'), trigger: ['blur', 'change'] },
        ],
       code: [
          {required: true, message:()=>  this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.VerificationCode'), trigger: 'blur'},

        ]
      }
    }
  },
 created() {
   ;
   this.getCaptchaImage()
  },
  methods: {
    getCaptchaImage() {
     this.captchaImage=this.$MyConstant('Api_CONSTANT.baseUrl')+'/index/index/CodeVerify?'+Math.random()
    },
    async submitForm(formName) {
      console.log(this);
      await this.$refs[formName].validate((valid) => {

        if (valid) {
          api.ajaxbaseUrl.post('/api/User/ForgotPassword',this.ruleForm,{headers:{'Accept-Language':this.MY_CONSTANT.setApiLang}})
              .then(response => {
                // 处理响应数据
                  if(response&&response.data.err==0){

                    this.$message.success({message:response.data.msg,offset:250,duration:1000});
                    let that=this
                    setTimeout(function (){    window.location.href='/'+that.MY_CONSTANT.setApiLang+'/UserLogin' },2000)


                  };
              })
              .catch(error => {
                // 处理错误
                console.log(error);
              })

        }
      });

    },

  }
};
</script>
<style scoped>
.loginContent{
  margin: 0 38px;
}
.loginContent .title{
  font-size: 15px;
  color: #606060;
  margin-bottom: 26px;
  margin: 26px 0px ;
}
:deep(.el-input__inner){
  font-size: 13px;
}
/deep/.el-input{
  border-radius: 0;
}

/deep/.el-form-item--small{
  margin-bottom: 30px;

}
/deep/.el-form-item--small .el-form-item__label {
  display: none;

}
/deep/.el-form-item__error {
  padding-left: 10px;
  font-size: 12px;
margin: 10px 0px;
}

/deep/.el-form-item.is-error .el-input__wrapper.is-focus{
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14)!important;
}
/*:deep(.el-form-item.is-error .el-input__wrapper.is-focus) { box-shadow: none !important;*/
/*}*/
/deep/.el-form-item.is-error .el-input__wrapper {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14);
}
/deep/.el-input__wrapper{
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14);
}

</style>
