<template>
  <Components :datas= '{
        "Template/Header": "",
      }'>
  </Components>
  <img style="width: 100%;height: 112px;" src="../../../assets/img/sch.png">

  <div class="middle">
    <div class="Pagetitle">
      {{ MY_CONSTANT.Api_CONSTANT.menu.PublicMenu.famous['name_'+MY_CONSTANT.sqlWhereLang]}}<el-icon><DArrowRight /></el-icon>
      <span>{{MY_CONSTANT.Api_CONSTANT.menu.PublicMenu.famous['name_'+MY_CONSTANT.sqlWhereLang]}}</span>
    </div>
    <div class="list-recommend">
      <div>
        <img :src="info.logo">
      </div>
      <ul>
        <li>
          <em>{{ MY_CONSTANT.Lang.CCompanyName }}：</em>
          {{ info.WithUser.cp_name}}
        </li>
        <li>
          <em>{{ MY_CONSTANT.Lang.ECompanyName }}：</em>
          {{ info.WithUser.cp_en_name}}
        </li>

        <li>
          <em>{{ MY_CONSTANT.Lang.product_type }}：</em>
          {{ info.WithUser.product_type }}
        </li>

        <li>
          <em>{{ MY_CONSTANT.Lang.main_exhibit }}：</em>
          {{ info.WithUser.main_exhibit }}
        </li>
        <li>
          <em>{{ MY_CONSTANT.Lang.hall }}：</em>
          {{ info.hall }}
        </li>
        <li>
          <em>{{ MY_CONSTANT.Lang.BoothNumber }}：</em>
          {{ info.exhibition }}
        </li>
        <li>
          <em>{{ MY_CONSTANT.Lang.ContactAddress }}：</em>
          {{ info.WithUser.link_address }}
        </li>
        <li>
          <em>{{ MY_CONSTANT.Lang.Cphone }}：</em>
          {{ info.WithUser.fax }}
        </li>
        <li>
          <em>E-mail：</em>
          <a target="_blank" :href="'mailto:'+info.WithUser.email">{{ info.WithUser.email }}</a>

        </li>
        <li>
          <em>{{ MY_CONSTANT.Lang.site }}：</em>
          <a target="_blank" :href="info.WithUser.website">{{ info.WithUser.website }}</a>
        </li>
        <li>
          <em>{{ MY_CONSTANT.Lang.CompanyIntroduction }}:</em>
          <br>
          {{ MY_CONSTANT.sqlWhereLang=='cn'?info.WithUser.cp_profile:info.WithUser.cp_en_profile }}
        </li>
      </ul>


    </div>

  </div>

  <br>
  <Components :datas='{
        "Template/Footer": "",
      }'>
  </Components>

</template>

<script>
import Components from '../../../modules/Components.vue'
import api from "@/assets/js/api";
import {decryptString} from "@/assets/js/globalFunctions";
export default {
  components: {
    Components
  },
  data() {
    return {
      info: {WithUser:{cp_name:''}},
      year:this.$route.params.year,
    }
  },
  async  created() {

    let info = await api.ajaxbaseUrlData('/api/User/ShowInfo',{
      user_id:this.$decryptString(this.$route.params.user_id),
      year:this.$route.params.year
    });

    info.logo=info.logo.includes('http')?info.logo:this.$MyConstant('StaticPath')+info.logo

    this.info=info


  },

};
</script>
<style scoped>
/* 2.展商的详情 */

 .list-recommend{

}
 .list-recommend div{
  display: flex;
  justify-content: center;
  margin-top: 52px;
  margin-bottom: 39px;
}
 .list-recommend div img{
  display: block;
  width: 200px;
}
 .list-recommend ul li{

  opacity: 1;
  font-size: 15px;

  font-weight: 500;

  color: #8b8b8b;
  line-height: 32px;
}
 .list-recommend ul li a{
  opacity: 1;
  font-size: 15px;

  font-weight: 500;

  color: #8b8b8b;
  line-height: 32px;
}
 .list-recommend ul li a:hover{
  color: #AF1E22;
}
 .list-recommend ul li:last-child{

  margin-top: 30px;
}


</style>
