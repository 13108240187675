<template>
<keep-alive>
  <component v-for="(component, index) in Templates" :key="index" :datas="datas[component['templateKey']]" :is="component"></component>
</keep-alive>

</template>
<script>



export default {

  props: {
    datas: {
      type: Object,
    },
  },
  data() {
    return {
      Templates: [],
      templateData:[]
    }
  }
  ,
  created() {
    // console.log(this.datas);
      this.loadComponent();
  }
  ,methods: {
    async loadComponent() {


// 动态导入组件
      for (let key in this.datas) {
        if (key.startsWith("modules")) {
          await import(`../${key}.vue`).then(Component => {
            // 给个识别的key，追加在default上面
            Component.default['templateKey'] = key;

            this.Templates.push(Component.default);
          });
        } else {
          await import(`../Pages/${this.$templatePlugin()}/${key}.vue`).then(Component => {
            // 给个识别的key，追加在default上面
            Component.default['templateKey'] = key;

            this.Templates.push(Component.default);
          });
        }
      }


    }
  }
  ,

  // watch: {
  //   datas: {
  //     handler: function(newVal, oldVal) {
  //       console.log(this.datas)
  //       // this.selectWhere=this.$getApiWhere(newVal.NewsSet);
  //       // this.newlist()
  //     },
  //     deep: true
  //   },
  //
  // },

}
</script>
