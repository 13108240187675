<template>
  <Components :datas= '{
        "Template/Header": "",
      }'>
  </Components>

  <div class="middle">
    <div class="Pagetitle">
      {{ MY_CONSTANT.Api_CONSTANT.menu.PublicMenu.news['name_'+MY_CONSTANT.sqlWhereLang]}}<el-icon><DArrowRight /></el-icon>
      <span>{{MY_CONSTANT.Api_CONSTANT.menu.PublicMenu.news.list[this.$route.query.cat_id==3?'tonggao':'news']['name_'+MY_CONSTANT.sqlWhereLang]}}</span>
    </div>

    <h2>{{ newsInfo.title }}</h2> <br>
    <div ref="imageContainer" class="HtmlContent" v-html="newsInfo.content"></div>

  </div>

  <br>
  <Components :datas='{
        "Template/Footer": "",
      }'>
  </Components>

</template>

<script>
import Components from '../../../modules/Components.vue'
import api from "@/assets/js/api";
export default {
  components: {
    Components
  },
  data() {
    return {
       newsInfo:[],
       menu:this.$MyConstant('Api_CONSTANT.menu.PublicMenu.news.list')
    }
    },
  created() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      const response = await api.getNewsInfo({id:this.$route.params.article_id});

      const modifiedContent = response.data.obj.data.content.replace(/<img[^>]+src=['"](?!http)[^'"]+['"]/g, (match) => {
        const src = match.match(/src=['"]([^'"]+)['"]/)[1];
        const domain = this.$MyConstant('StaticPath'); // 替换为您的域名
        return match.replace(src, domain + src);
      });

      response.data.obj.data.content= modifiedContent;
      this.newsInfo=response.data.obj.data


      this.$nextTick(() => {
        new this.$viewer(this.$refs.imageContainer);
      });


    }
  },
  mounted() {


  }
};
</script>
<style scoped>



</style>
