<template>
<!--    <public-herder  v-if="header_show"></public-herder>-->
  <Components v-if="this.$MyConstant('Equipment')=='pc'" :datas= '{
        "Template/Header": "",

      }'>
  </Components>
    <router-view  />
<!--    v-on:public_header="public_header" v-on:public_footer="public_footer"-->
    <!--    <public-footer  v-if="footer_show"></public-footer>-->
  <Components :datas= '{
        "Template/goToTop": "",
      }'>
  </Components>
  <Components  v-if="this.$MyConstant('Equipment')=='pc'" :datas= '{
        "Template/Footer": "",
      }'>
  </Components>
</template>

<script>
import Components from './modules/Components.vue'
// import PublicHeader from "./modules/Header"
// import PublicFooter from "./modules/Footer"
export default {
  name: 'App',
  data(){
    return{
      // header_show:true,
      // footer_show:true
    }
  },
  created() {
      //动态样式引入
      import(`@/assets/css/${this.$MyConstant('Equipment')}.css`);

  },
  methods:{
    //是否显示头部
    // public_header:function (bool) {
    //   this.header_show = bool;
    // },
    // //是否显示底部
    // public_footer:function (bool) {
    //   this.footer_show = bool;
    // }
  },

  components:{
    Components
    // PublicHeader,
    // PublicFooter
  }

}

</script>
