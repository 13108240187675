<template>
  <div class="banner">
    <div class="swiper-container swiper-container1">
      <div class="swiper-wrapper">

        <div class="swiper-slide">
          <img src="../../../assets/img/sch.png">
        </div>
      </div>
    </div>
  </div>

  <div class="about">
    <div class="about-top">
      <p>{{ this.$MyConstant('Api_CONSTANT.menu.PublicMenu.about.name_'+MY_CONSTANT.sqlWhereLang) }}</p>
      <img src="../../../assets/img/icon/jiantou.png">
      <em>{{ this.$MyConstant('Api_CONSTANT.menu.PublicMenu.about.list.history.name_'+MY_CONSTANT.sqlWhereLang) }}</em>

    </div>
    <div class="about-bottom middle">

      <!-- 左边 -->

        <div class="about-history-left">
          <ul id="nav">
            <li v-for="item in soncatList" :class="{'act':item.h_cat_id==son_cat_id}">
              <custom-link :to="'/History?id='+item.parent_id+'&son_cat_id='+item.h_cat_id">
              {{ this.$MyConstant('sqlWhereLang')=='cn'?item.h_cat_name:item.h_en_cat_name }}
              </custom-link>
            </li>
          </ul>
          <div id="container">
            <div class="tab">
              <div class="tab1">
                <div v-for="(item,key) in imgItems">
                  <el-image
                      style="width: 400px;height: 250px;"
                      :src="item"
                      :preview-src-list="[item]"
                      :initial-index="key"
                      lazy
                  />
                </div>

              </div>

            </div>

          </div>


        </div>

      <!-- 右边 -->
      <div class="about-right">
        <div class="about-right1" v-for="(item,key) in menu">
        <custom-link :style="{'color': 'history' == key ? 'var(--uavdeep-color)' : ''}" :to="item.url">
            <img src="../../../assets/img/icon/sanjiao.png">
            <em>{{ item['name_'+MY_CONSTANT.sqlWhereLang] }}</em>
          </custom-link>
        </div>

        <ul style="">
          <li class="about-right2" :class="{'aboutColor': item.h_cat_id==cat_id}" v-for="item in catList" >
            <custom-link v-if="item.parent_id == 0" :to="'/History?id='+item.h_cat_id">
              <em>{{ this.$MyConstant('sqlWhereLang')=='cn'?item.h_cat_name:item.h_en_cat_name }}</em>
            </custom-link>
          </li>

        </ul>


        <Components :datas= '{
        "Template/About-right": "",
         }'>
        </Components>

      </div>

    </div>

  </div>

</template>

<script>
import Components from '../../../modules/Components.vue'
import api from "@/assets/js/api";
export default {
  components: {
    Components
  },
  data() {
    return {
       cat_id:59,
       son_cat_id:59,
       catList:[],
       soncatList:[],
       imgItems:[],
       menu:this.$MyConstant('Api_CONSTANT.menu.PublicMenu.about.list')
    }
    },
  async created() {


  },
  methods: {
    async fetchData() {

      const response = await    api.ajaxbaseUrlget('/api/history/GetCat');
      this.catList= response.data.obj.data
      this.cat_id=this.$route.query.id?this.$route.query.id:this.catList[0].h_cat_id
      const response1 = await    api.ajaxbaseUrlget('/api/history/GetCat',{parent_id:this.cat_id});
      this.soncatList= response1.data.obj.data
      this.son_cat_id=this.$route.query.son_cat_id?this.$route.query.son_cat_id:this.soncatList[0].h_cat_id
      // console.log(this.catList[0]);


      const response2 = await    api.ajaxbaseUrlget('/api/history/GetHistory',{cat_id:this.son_cat_id});

      let imgItems=[];
      response2.data.obj.data.map((item, index) => {
       imgItems[index]=item.h_pic.includes('http')?item.h_pic:this.$MyConstant('StaticPath')+'UploadFiles/Images/Review/'+item.h_pic

      });
       // console.log(imgItems);
      this.imgItems=imgItems
    }
  },
  watch: {
    '$route': {
      immediate: true, // 初始化时立即执行
      handler() {
        // 当路由参数变化时，重新请求接口并更新内容
        this.fetchData();
      }
    }
  },
};
</script>
<style scoped>

/* 6 历史回顾about_history*/
.about-history-bottom {
  display: flex;
  justify-content: space-between;
  margin-bottom: 134px;
}

.about-history-left {
  width: 75%;
  margin-top: 47px;
}

.about-history-left ul {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 24px;

}

.about-history-left ul li {
  width: 17%;
  text-align: center;
  padding: 14px 0 11px;
  background-color: #F5F5F5;
  margin-right: 21px;
  margin-bottom: 21px;

  font-size: 21px;

  font-weight: 500;

  color: #8B8B8B;
  line-height: 35px;

}
.act {
  color: #fff !important;
  background-color: var(--uavdeep-color) !important;
}
.act  a{
  color: #fff;
}


.tab .tab1 {
  display: flex;
  /* justify-content: space-between; */
  flex-wrap: wrap;
}

.tab .tab1::after {
  content: "";
  display: block;
  width: 209px;
  height: 118px;
  visibility: hidden;
}

.tab .tab1 div {
  /* width: 209px;
  height: 118px; */
  margin-bottom: 10px;
  margin-right: 10px;
}

.tab .tab1 img {
  width: 400px;
  height: 250px;
  /*  max-width: 209px;
   max-height: 118px; */
}
</style>
