<template>
  <div class="banner">
    <div class="swiper-container swiper-container1">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <img src="../../../assets/img/sch.png">
        </div>
      </div>
    </div>
  </div>

  <div class="about">
    <div class="about-top">
      <p>{{ this.$MyConstant('Api_CONSTANT.menu.PublicMenu.exhibitor_info.name_'+MY_CONSTANT.sqlWhereLang) }}</p>
      <img src="../../../assets/img/icon/jiantou.png">
      <em>{{ this.$MyConstant('Lang.exhibitionRegister') }}</em>

    </div>
    <div class="contentMiddle">
      <!-- 左边 -->
      <div class="contentLeft">

        <el-form v-if="MY_CONSTANT.Api_CONSTANT.SizeConfig.site_reg_closed==0" :model="ruleForm" :rules="rules" ref="ruleForm"  class="demo-ruleForm" id="register_form">
          <el-form-item v-if="MY_CONSTANT.sqlWhereLang=='cn'" :label="MY_CONSTANT.Lang.CompanyName+'：'" prop="cp_name">
            <el-input class="input400 err400"  v-model="ruleForm.cp_name" :placeholder="MY_CONSTANT.Lang.exampleCCompanyName0" onkeyup=""></el-input>
          </el-form-item>
          <el-form-item v-if="MY_CONSTANT.sqlWhereLang=='en'" :label="MY_CONSTANT.Lang.ECompanyName+'：'" prop="cp_en_name">
            <el-input class="input400 err400"  v-model="ruleForm.cp_en_name" :placeholder="MY_CONSTANT.Lang.exampleECompanyName" onkeyup=""></el-input>
          </el-form-item>
          <br>
          <el-form-item label="E-mail：" prop="email">
            <el-input class="input400" v-model="ruleForm.email" :placeholder="MY_CONSTANT.Lang.PleaseEnter"></el-input>
          </el-form-item>
          <el-form-item :label="MY_CONSTANT.Lang.Password+'：'"  prop="password">
            <el-input class="input400" v-model="ruleForm.password" :placeholder="MY_CONSTANT.Lang.PleaseEnter" show-password></el-input>
          </el-form-item>

          <el-form-item :label="MY_CONSTANT.Lang.repeatPassword+'：'"  prop="re_password">
            <el-input class="input400" v-model="ruleForm.re_password" :placeholder="MY_CONSTANT.Lang.PleaseEnter" show-password></el-input>
          </el-form-item>
          <el-form-item :label="'\n'" >
            <el-button type="danger" id="submitForm"  @click="submitForm('ruleForm')" >{{ MY_CONSTANT.Lang.register }}</el-button>
          </el-form-item>
        </el-form>

      </div>


      <!-- 右边 -->
      <div class="about-right">
        <div class="about-right1" v-for="(item,key) in this.$MyConstant('Api_CONSTANT.menu.PublicMenu.exhibitor_info.list')">
          <custom-link :style="{'color': 'shouce' == key ? 'var(--uavdeep-color)' : ''}" :to="item.url">
            <img src="../../../assets/img/icon/sanjiao.png">
            <em>{{ item['name_'+MY_CONSTANT.sqlWhereLang] }}</em>
          </custom-link>
        </div>

        <Components :datas= '{
        "Template/About-right": "",
         }'>
        </Components>

      </div>

    </div>

  </div>

</template>

<script>
import Components from '../../../modules/Components.vue'
import {validateUser} from "@/assets/js/validation";
import api from "@/assets/js/api";
export default {
  components: {
    Components
  },
  data() {
    let validatePass2=((rule,value,callback)=>{
      if(value !=this.ruleForm.password){
        callback(new Error(this.$MyConstant('Lang.TwoPassword')));
      }else{
        callback();
      }
    });

    let validateCompanyName= async (rule, value, callback) => {

      const res = await  api.ajaxbaseUrl.post('/api/User/registerVerification', {field_value: '{"'+rule.field+'":"'+value+'"}'})
          .then(response => {
            return response;
          });
      if(res.data.err>0){
        let that=this
        setTimeout(function (){

          let element = document.getElementById('fanhui');
          if (!element){
            $('.err400~.el-form-item__error').append('<a id="fanhui" href="/UserLogin?s=0VtfsSftfswf">'+ '>>'+that.$MyConstant('Lang.backlogin')+'</a>');

          }
        },500);
        this.buttonType='info';
        callback(new Error(this.$MyConstant('Lang.repeatCp_name')));

      }
      callback();

    };

    return {

      data: [],
      ruleForm: {
        user_name: '',
        email: '',
        password: '',

      },
      rules: {
        cp_name: [
          { required: true, message: () =>  this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.CompanyName'), trigger: 'blur'},
          { min: 2, message: () => this.$MyConstant('Lang.CompanyName')+this.$MyConstant('Lang.Wronglength'), trigger: 'blur' },
          { validator: validateCompanyName, trigger: 'blur' }
        ],
        cp_en_name: [
          { required: true, message: () =>  this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.CompanyName'), trigger: 'blur'},
          { min: 2, message: () => this.$MyConstant('Lang.CompanyName')+this.$MyConstant('Lang.Wronglength'), trigger: 'blur' },
          { validator: validateCompanyName, trigger: 'blur' }
        ],
        email: [
          { required: true, message: () =>  this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.Email'), trigger: 'blur'},
          { type: 'email', message: () => this.$MyConstant('Lang.PleaseCorrect')+this.$MyConstant('Lang.Email'), trigger: ['blur', 'change'] },
          { validator: validateUser, trigger: 'blur' }
        ],
        password: [
          { required: true, message: () =>  this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.Password'), trigger: 'blur' },
          { min: 6, max: 30, message: () => this.$MyConstant('Lang.PasswordLength'), trigger: 'blur' },

        ],
        re_password: [
          { required: true, message: () =>  this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.Password'), trigger: 'blur' },
          { validator: validatePass2, trigger: 'blur' }

        ]


      }
    }
  },
  created() {

    if(this.$MyConstant('Api_CONSTANT.SizeConfig.site_reg_closed')==1){
      this.$message.error({message:this.$MyConstant('Lang.site_reg_closed'),offset:400,duration:10000});return;
    }
  },
  methods: {
    async submitForm(formName) {
      await this.$refs[formName].validate((valid) => {
        if(this.$MyConstant('Api_CONSTANT.SizeConfig.site_reg_closed')==1){
          this.$message.error(this.$MyConstant('Lang.site_reg_closed'));return;
        }
        if (valid) {
          api.ajaxbaseUrl.post('/api/User/Register',this.ruleForm,{headers:{'Accept-Language':this.MY_CONSTANT.setApiLang}})
              .then(response => {
                // 处理响应数据
                if(response&&response.data.err==0){
                  this.$message.success({message:response.data.msg,offset:400,duration:3000});

                  //直接把登录也搞进去了
                  api.ajaxbaseUrl.post('/api/User/Login', {
                    user_name:this.ruleForm.cp_name??this.ruleForm.cp_en_name,
                    password:this.ruleForm.password
                  },{headers:{'Accept-Language':this.MY_CONSTANT.setApiLang}})
                      .then(response => {
                        // 处理响应数据
                        if(response&&response.data.err==0){
                          this.$cookies.set("Cookie", response.data.obj.data, this.$MyConstant('Login.expires'));
                          let that=this
                          setTimeout(function (){ window.location.href='/'+that.MY_CONSTANT.setApiLang+'/UserReserve';},2000)

                        }
                      })



                };
              })

        }
      });

    },

  }
};
</script>
<style scoped>
/deep/#fanhui{
  color: var(--uavdeep-color);
  text-decoration: underline;
}


</style>
