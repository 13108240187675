<template>
  <div class="banner">
    <div class="swiper-container swiper-container1">
      <div class="swiper-wrapper">

        <div class="swiper-slide">
          <img src="../../../assets/img/sch.png">
        </div>
      </div>
    </div>
  </div>

  <div class="about">
    <div class="about-top">
      <p>{{ this.$MyConstant('Api_CONSTANT.menu.PublicMenu.exhibitor_info.name_'+MY_CONSTANT.sqlWhereLang) }}</p>
      <img src="../../../assets/img/icon/jiantou.png">
      <em>{{ this.$MyConstant('Api_CONSTANT.menu.Usermenu.visitor_info.list.edit_password.name_'+MY_CONSTANT.sqlWhereLang) }}</em>

    </div>
    <div class="contentMiddle">
      <!-- 左边 -->
      <div class="contentLeft">
        <div class="reg-hd">
          <h3>{{ this.$MyConstant('Api_CONSTANT.menu.Usermenu.visitor_info.list.edit_password.name_'+MY_CONSTANT.sqlWhereLang) }}</h3>
        </div>

        <el-form :model="ruleForm" :rules="rules" ref="ruleForm"  class="demo-ruleForm" id="register_form">

          <el-form-item label="E-mail：" prop="email">
            <el-input class="input400" v-model="ruleForm.email" :placeholder="MY_CONSTANT.Lang.PleaseEnter"></el-input>
          </el-form-item>


          <el-form-item :label="MY_CONSTANT.Lang.VerificationCode+'：'" prop="code">
            <div class="form-container" style="display: flex;align-items: center;">
            <el-input class="input150" v-model="ruleForm.code" :placeholder="MY_CONSTANT.Lang.PleaseEnter"></el-input>
            <el-image @click="getCaptchaImage" style="display: inline-block; margin-left: 10px;width: 100px;height: 40px;" :src="captchaImage" fit="cover" :alt="MY_CONSTANT.Lang.VerificationCode"></el-image>
            </div>
          </el-form-item>


          <el-form-item :label="'\n'" >
            <el-button type="danger"  @click="submitForm('ruleForm')" >{{ MY_CONSTANT.Lang.submit }}</el-button>
          </el-form-item>


        </el-form>
      </div>


      <!-- 右边 -->
      <div class="about-right">
        <div class="about-right1" v-for="(item,key) in this.$MyConstant('Api_CONSTANT.menu.PublicMenu.exhibitor_info.list')">
          <custom-link :style="{'color': 'edit_password' == key ? 'var(--uavdeep-color)' : ''}" :to="item.url">
            <img src="../../../assets/img/icon/sanjiao.png">
            <em>{{ item['name_'+MY_CONSTANT.sqlWhereLang] }}</em>
          </custom-link>
        </div>

        <Components :datas= '{
        "Template/About-right": "",
         }'>
        </Components>

      </div>

    </div>

  </div>

</template>

<script>
import Components from '../../../modules/Components.vue'
import api from "@/assets/js/api";
export default {
  components: {
    Components
  },
  data() {

    return {
      captchaImage: '',
      ruleForm: {
        email:'',
        code: '',
      },
      rules: {
        email: [
          { required: true, message: () => this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.Email'), trigger: 'blur'},
          { type: 'email', message: () => this.$MyConstant('Lang.PleaseCorrect')+this.$MyConstant('Lang.Email'), trigger: ['blur', 'change'] },
        ],
       code: [
          {required: true, message: () => this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.VerificationCode'), trigger: 'blur'},

        ]
      }
    }
  },
 created() {
   this.getCaptchaImage()
  },
  methods: {
    getCaptchaImage() {
     this.captchaImage=this.$MyConstant('Api_CONSTANT.baseUrl')+'/index/index/CodeVerify?'+Math.random()
    },
    async submitForm(formName) {
      console.log(this);
      await this.$refs[formName].validate((valid) => {

        if (valid) {
          api.ajaxbaseUrl.post('/api/User/ForgotPassword',this.ruleForm,{headers:{'Accept-Language':this.MY_CONSTANT.setApiLang}})
              .then(response => {
                // 处理响应数据
                  if(response&&response.data.err==0){

                    this.$message.success({message:response.data.msg,offset:400,duration:1000});
                    let that=this
                    setTimeout(function (){    window.location.href='/'+that.MY_CONSTANT.setApiLang+'/UserLogin' },2000)


                  };
              })
              .catch(error => {
                // 处理错误
                console.log(error);
              })

        }
      });

    },

  }
};
</script>
<style scoped>


</style>
