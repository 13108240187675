<template>
  <Components :datas= '{
        "Template/Header": "",
      }'>
  </Components>


  <div class="middle">
    <div class="Pagetitle">
      {{ MY_CONSTANT.Api_CONSTANT.menu.Usermenu.exhibition['name_'+MY_CONSTANT.sqlWhereLang]}}<el-icon><DArrowRight /></el-icon>
      <span>{{MY_CONSTANT.Api_CONSTANT.menu.Usermenu.exhibition.list.yudengji['name_'+MY_CONSTANT.sqlWhereLang]}}</span>
    </div>


    <div class="chanage_login-left1">
      <h2>{{ this.$MyConstant('Lang.approvedbooth') }}</h2>
      <table border="0" cellspacing="" cellpadding="">
        <tbody>
        <tr>
          <th>{{ this.$MyConstant('Lang.hall') }}</th>
          <th>{{ this.$MyConstant('Lang.BoothNumber') }}</th>
          <th>{{ this.$MyConstant('Lang.year') }}</th>
        </tr>

        <tr v-for="item in List">
          <th>{{ item.hall }}{{ this.$MyConstant('Lang.hallNum') }}</th>
          <th>{{ item.exhibition }}</th>
          <th>{{ item.year }}</th>
          <!--  <th>状态</th> -->
        </tr>

        </tbody>

      </table>
      <div v-if="!item"  style="text-align: center">
        <el-text>
          {{ MY_CONSTANT.Lang.Nodata }}
        </el-text>
      </div>
    </div>

  </div>


  <Components :datas='{
        "Template/Footer": "",
      }'>
  </Components>

</template>

<script>
import Components from '../../../modules/Components.vue'
import api, {ajaxbaseUrlData} from "@/assets/js/api";
export default {
  components: {
    Components
  },
  data() {
    return {
      List:[],
    }
  },
  async  created() {
    this.List= await api.ajaxbaseUrlData('/api/User/UserExhibition')
  },
  methods: {

  }
};
</script>
<style scoped>
.chanage_login-left1  h2{
  font-size: 21px;

  text-align: justifyLeft;
  color: #000000;

  margin: 54px 0;

}
.chanage_login-left1  h2:first-child{

}
.chanage_login-left1  table{
  width: 100%;
}
.chanage_login-left1  table tr{
  font-size: 13px;
  color: #8b8b8b;

}
.chanage_login-left1  table tr:first-child{
  background-color: var(--uavdeep-color)!important;
  font-size: 13px;
  color: #fff;

}

.chanage_login-left1 table tr:nth-child(odd){
  background: #F5F5F5;

}

.chanage_login-left1  table th{
  width: 200px;
  text-align: center;
  padding: 12px 0;
  border: 2px solid #fff;
}
.chanage_login-left1  table td{
  text-align: center;
  padding: 12px 0;
  border: 2px solid #fff;

}

</style>
