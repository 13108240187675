<template>
  <div class="banner">
    <div class="swiper-container swiper-container1">
      <div class="swiper-wrapper">

        <div class="swiper-slide">
          <img src="../../../assets/img/sch.png">
        </div>
      </div>
    </div>
  </div>

  <div class="about">
    <div class="about-top">
      <p>{{ this.$MyConstant('Api_CONSTANT.menu.PublicMenu.download.name_'+MY_CONSTANT.sqlWhereLang) }}</p>
      <img src="../../../assets/img/icon/jiantou.png">
      <em>{{ this.$MyConstant('Api_CONSTANT.menu.PublicMenu.download.list.shouce.name_'+MY_CONSTANT.sqlWhereLang) }}</em>

    </div>
    <div class="contentMiddle">
      <!-- 左边 -->
      <div class="contentLeft">

        <div class="download-left1" v-for=" item in DownloadList">
          <div class="download_hd">
            <img :src="this.$MyConstant('StaticPath')+item.pic">
          </div>

          <div class="download_hd2">
            <div>
              <h2>{{ item.title }}</h2>
              <em>{{ item.decr }}</em>
            </div>
            <a title="MY_CONSTANT.Lang.Download" :href="item.fileurl">
              <em>{{ MY_CONSTANT.Lang.Download }}</em>
            </a>
          </div>
        </div>

      </div>


      <!-- 右边 -->
      <div class="about-right">
        <div class="about-right1" v-for="(item,key) in this.$MyConstant('Api_CONSTANT.menu.PublicMenu.download.list')">
          <custom-link :style="{'color': this.$route.fullPath == '/'+MY_CONSTANT.setApiLang+item.url ? 'var(--uavdeep-color)' : ''}" :to="item.url">
            <img src="../../../assets/img/icon/sanjiao.png">
            <em>{{ item['name_'+MY_CONSTANT.sqlWhereLang] }}</em>
          </custom-link>
        </div>

        <Components :datas= '{
        "Template/About-right": "",
         }'>
        </Components>

      </div>

    </div>

  </div>

</template>

<script>
import Components from '../../../modules/Components.vue'
import api from "@/assets/js/api";
export default {
  components: {
    Components
  },
  data() {
    return {
      DownloadList:[],
    }
  },
  watch: {
    'MY_CONSTANT.sqlWhereLang': {
      immediate: true, // 初始化时立即执行
      handler() {
        // 当路由参数变化时，重新请求接口并更新内容
        this.fetchData();
      }
    }
  },
  methods: {
    async fetchData() {

      const response = await api.ajaxbaseUrlget('/api/public_view/Download',{class_id:this.$route.query.class_id,lang:this.$MyConstant('sqlWhereLang')});
      this.DownloadList=response.data.obj.data
    }

  }

};
</script>
<style scoped>
.download-left1{
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
}
.download-left1 .download_hd{
  width: 31%;

}
.download-left1 .download_hd img{
  width: 100%;
  height: 100%;
  /* border: 2px solid #007AFF; */

}
.download-left1  .download_hd2{
  width: 60%;
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #8B8B8B;
  align-items: flex-end;

}
.download-left1 .download_hd2 h2{

  opacity: 1;
  font-size: 21px;

  font-weight: 500;

  color: #000000;
  line-height: 35px;
  margin-bottom: 20px;

}
.download-left1 .download_hd2 em{

  opacity: 1;
  font-size: 13px;

  font-weight: 500;

  color: #bebebe;
  line-height: 32px;
  margin-bottom: 14px;

}
.download-left1 .download_hd2 a em{
  display: block;
  padding:8px 20px;

  background-color: #000;
  margin-bottom: 34px;


  opacity: 1;
  font-size: 15px;
  font-family: Source Han Sans CN, Source Han Sans CN-Bold;
  font-weight: 500;

  color: #ffffff;
  /* line-height: 30px; */
  letter-spacing: 1px;

}


</style>
