<template>
  <Components :datas='{
        "Template/Header": "",
        "Template/Swiper/MobileIndex": {filter: "{\"adcate_id\":\"3\"}", limit: 3},
      }'>
  </Components>

 <div style="background: var(--uav-button-bg-color);width: 100%;height: 33px;display: flex;align-items: center;justify-content:center;color: #fff;font-size: 12px;margin-top: -5px;padding-bottom: 5px;">
   <el-icon size="16"><Clock /></el-icon>
   <span id="demo" v-if="countdown > 0">
                       &nbsp;{{ this.$MyConstant('Lang.daojishi') }} {{ days }} {{ this.$MyConstant('Lang.day') }} {{
       hours
     }} {{ this.$MyConstant('Lang.hours') }} {{ minutes }} {{ this.$MyConstant('Lang.minutes') }} {{ seconds }} {{ this.$MyConstant('Lang.seconds') }}
                      </span>
   <span id="demo" v-else-if="countdown <= 0 && countdown > -4 * 24 * 60 * 60 * 1000">
                        展会进行中
                      </span>
   <span id="demo" v-else>
                        已结束
                      </span>

 </div>


  <div class="threeButton">
         <span>
        <custom-link :to="MY_CONSTANT.Api_CONSTANT.menu.PublicMenu.about.list.intro.url">
          <el-button  style="margin-right: 10px;">{{ MY_CONSTANT.Api_CONSTANT.menu.PublicMenu.about.list.intro['name_'+MY_CONSTANT.sqlWhereLang] }}&nbsp;&nbsp;<el-icon><DArrowRight /></el-icon></el-button>
        </custom-link>
        <custom-link :to="MY_CONSTANT.Api_CONSTANT.menu.PublicMenu.news.url">
          <el-button  style="margin-right: 10px;">{{  MY_CONSTANT.Api_CONSTANT.menu.PublicMenu.news.list.news['name_'+MY_CONSTANT.sqlWhereLang]}}&nbsp;&nbsp;<el-icon><DArrowRight /></el-icon></el-button>
        </custom-link>
        <custom-link :to="MY_CONSTANT.Api_CONSTANT.menu.PublicMenu.visitor.list.floorplan.url">
          <el-button  >{{ MY_CONSTANT.Api_CONSTANT.menu.PublicMenu.visitor.list.floorplan['name_'+MY_CONSTANT.sqlWhereLang]}}&nbsp;&nbsp;<el-icon><DArrowRight /></el-icon></el-button>
        </custom-link>
        </span>
  </div>



  <div class="middle">
  <el-row :gutter="24" style="display: flex;align-items: center;text-align: center">
    <el-col :span="12">
      <img src="../../../assets/img/shouye/nav1.png">
      <h3  class="numberVFX" style="color:var(--uavdeep-color);">{{ MY_CONSTANT.Api_CONSTANT.SizeConfig.exhibition_space }}</h3>
      <h4 style="color: #4d4d4d;" v-if="MY_CONSTANT.sqlWhereLang=='cn'">展览空间</h4>
      <p v-else style="color: #4d4d4d;text-align: center;font-size: 13px;">Exhibition area</p>
    </el-col>

    <el-col :span="12">
      <img src="../../../assets/img/shouye/nav2.png">
      <h3  class="numberVFX" style="color:var(--uavdeep-color);">{{ MY_CONSTANT.Api_CONSTANT.SizeConfig.exhibitorss }}</h3>
      <h4 style="color: #4d4d4d;" v-if="MY_CONSTANT.sqlWhereLang=='cn'">参展展商</h4>
      <p v-else style="color: #4d4d4d;text-align: center;font-size: 13px;">Exhibitors</p>
    </el-col>
    <el-col :span="12">
      <img src="../../../assets/img/shouye/nav3.png">
      <h3  class="numberVFX" style="color:var(--uavdeep-color);">{{ MY_CONSTANT.Api_CONSTANT.SizeConfig.professional_audience }}</h3>
      <h4 style="color: #4d4d4d;" v-if="MY_CONSTANT.sqlWhereLang=='cn'">专业观众</h4>
      <p v-else style="color: #4d4d4d;text-align: center;font-size: 13px;">Professional audiences</p>
    </el-col>
    <el-col :span="12">
      <img src="../../../assets/img/shouye/nav4.png">
      <h3  class="numberVFX" style="color:var(--uavdeep-color);">{{ MY_CONSTANT.Api_CONSTANT.SizeConfig.booth }}</h3>
      <h4 style="color: #4d4d4d;" v-if="MY_CONSTANT.sqlWhereLang=='cn'">展位</h4>
      <p v-else style="color: #4d4d4d;text-align: center;font-size: 13px;">Booths</p>
    </el-col>
  </el-row>
  </div>

  <div style="text-align: center;margin-top: 20px;color: grey;font-size: 12px;">
    {{ MY_CONSTANT.Lang.dataSource }}：{{ MY_CONSTANT.Api_CONSTANT.SizeConfig['site_name_'+MY_CONSTANT.sqlWhereLang] }}
  </div>


  <div class="middle" style="display: flex;
    align-items: center;">
    <img style="width: 50px;margin-right: 10px;" src="https://51kufei.oss-cn-shenzhen.aliyuncs.com/upload/20211023/2a8f8276390275da0641c4ec3cde2e16.gif"
         >
      <custom-link
          :to="MY_CONSTANT.Api_CONSTANT.SizeConfig['important_nocite_url_'+MY_CONSTANT.sqlWhereLang]">

        <span class="blink">
          {{ MY_CONSTANT.Api_CONSTANT.SizeConfig['important_nocite_'+MY_CONSTANT.sqlWhereLang] }}</span>
      </custom-link>


  </div>


  <div class="middle">

    <img style="width: 100%;" :src="require(`@/assets/img/shouye/h1_`+this.$MyConstant('sqlWhereLang')+`.png`)">

  <img style="width: 100%;" src="../../../assets/img/shouye/t2.png">

  <div class="about-right-zi">

    <p v-if="MY_CONSTANT.sqlWhereLang=='cn'">展会基本信息</p>
    <p v-else>EXHIBITION BASIC INFORMATION</p><br>
    <p>{{ MY_CONSTANT.Lang.Exhibitiondate }}：{{ this.$ExhibitionDate() }}（{{ MY_CONSTANT.Lang.AnnualTerm }}）</p>
    <p>{{ MY_CONSTANT.Lang.ExhibitionAddress }}：{{
        MY_CONSTANT.Api_CONSTANT.SizeConfig['exhibition_address_info_' + MY_CONSTANT.sqlWhereLang]
      }}</p>
    <p>{{ MY_CONSTANT.Lang.ExhibitionArea }}：{{ MY_CONSTANT.Api_CONSTANT.SizeConfig.exhibition_space }}㎡</p>
    <p>{{ MY_CONSTANT.Lang.Exhibitors }}：{{
        MY_CONSTANT.Api_CONSTANT.SizeConfig.exhibitorss
      }}</p>


  </div>
  </div>


      <div class="middle">
      <img style="width: 100%;" :src="require(`@/assets/img/shouye/h2_`+this.$MyConstant('sqlWhereLang')+`.png`)">
     <div class="shipin">
      <div v-html="this.$MyConstant('Api_CONSTANT.SizeConfig.index_video_html_'+this.$MyConstant('sqlWhereLang'))">
      </div>
    </div>
      </div>




      <div class="middle">
        <img style="width: 100%;" :src="require(`@/assets/img/shouye/h3_`+this.$MyConstant('sqlWhereLang')+`.png`)">

    <div style="margin:20px 0px;display: flex;align-items: center;justify-content: space-between">
      <span style="font-size: 1rem;color:var(--uavdeep-color);font-weight: 700; "><el-icon size="18"><Reading/></el-icon>{{ MY_CONSTANT.Api_CONSTANT.menu.PublicMenu.news.list.news['name_'+MY_CONSTANT.sqlWhereLang] }}</span>
      <custom-link style="font-size: 0.8rem;color:var(--uavdeep-color);font-weight: 700;"
                   to="/News">
        more>>>
      </custom-link>

    </div>

<!--    <Components :datas='{-->
<!--       "modules/mNewsList": {-->
<!--          NewsSet:{cat_id: 1,limit:5,order:"{\"add_time\":\"desc\"}"},-->
<!--           style:"height:800px;overflow-y: auto;",-->
<!--           handleScroll:true-->
<!--        },-->
<!--      }'>-->
<!--    </Components>-->
        <el-card   class="news-card" shadow="hover" v-for="s in newsItems" >
          <el-row>
            <el-col :span="9">
              <div class="image-container">
                <custom-link  :to="'/NewsInfo/' +s.article_id" ><img class="news-image" :src="this.$MyConstant('StaticPath')+s.pic" ></custom-link>
              </div>
            </el-col>
            <el-col :span="13">
              <div class="news-details">
                <custom-link  :to="'/NewsInfo/' +s.article_id" >
                  <h3 class="news-title">{{ s.title }}</h3>
                  <!--          <p class="news-description">{{ s.description }}</p>-->
                  <p class="news-time">{{ this.$formatTime(s.add_time) }}</p>
                </custom-link>
              </div>
            </el-col>
          </el-row>
        </el-card>


  </div>


  <div class="sch">
    <div class="middle">

      <div class="sch-left" style="color:var(--uavdeep-color);"
           v-html="this.$MyConstant('Api_CONSTANT.SizeConfig.index_center_'+this.$MyConstant('sqlWhereLang'))">

      </div>
      <br>
      <div style="text-align: center;">
      <custom-link :to="MY_CONSTANT.Api_CONSTANT.menu.PublicMenu.exhibitor_info.list.login.url">
        <el-button size="large">{{ MY_CONSTANT.Lang.clickToExhibit }}&nbsp;&nbsp;<el-icon><DArrowRight /></el-icon></el-button>
      </custom-link>
      </div>

    </div>
  </div>



      <div class="middle">
        <img style="width: 100%;" :src="require(`@/assets/img/shouye/h5_`+this.$MyConstant('sqlWhereLang')+`.png`)">

    <Components :datas='{
        "Template/Swiper/MobileExhibit": {limit: "60",year:selectYear-1},

      }'>
    </Components>
  </div>


  <div class="middle">
    <img style="width: 100%" :src="require(`@/assets/img/shouye/h4_`+MY_CONSTANT.sqlWhereLang+`.png`)">
  </div>
  <div class="middle" style="margin: 0 12px;">
    <el-row :gutter="24">
      <el-col :span="8" v-for="(s,index) in partner"
              style="width: 100%;height: auto;padding:10px;display: flex;align-items: center">
        <div style="margin: 0 auto;">
          <a :href="s.url">
            <img :style="{ 'width': index === partner.length - 1 ? '40%' : '100%',
                       'padding-left': index === partner.length - 1 ? '35%' : '0'
        }" :src="s.img">
          </a>
        </div>
      </el-col>
    </el-row>

  </div>
  <Components :datas='{
        "Template/Footer": "",
      }'>
  </Components>

</template>
<script>
import Components from '../../../modules/Components.vue'
import api from "@/assets/js/api";


export default {
  components: {
    Components
  },
  computed: {
    days() {
      return Math.floor(this.countdown / (24 * 60 * 60 * 1000));
    },
    hours() {
      return Math.floor((this.countdown % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));
    },
    minutes() {
      return Math.floor((this.countdown % (60 * 60 * 1000)) / (60 * 1000));
    }
  },

  data() {
    return {
      countdown: 0,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      newsItems:[],
      selectYear: null,
      currentYear: null,
      currentMonth: null,
      currentDay: null,
      currentEndDay: null,
      partner: null
    }
  },
  watch:
      {
        'MY_CONSTANT.sqlWhereLang': {
          immediate: true, // 初始化时立即执行
          async handler() {
            const response = await    api.getNewsData(this.$getApiWhere({limit:3,filter:JSON.stringify({lang:this.$MyConstant('sqlWhereLang')}),order: '{"sort":"desc","add_time":"desc"}'}));
            this.newsItems = response.data.obj.data;

          }
        }
      },
  async created() {

    this.partner = await api.ajaxbaseUrlData('/api/public_view/partner', {lang: this.MY_CONSTANT.sqlWhereLang});
    const date = new Date(this.$MyConstant('Api_CONSTANT.SizeConfig.ini_cpse_date').split(" ")[0]);

    const enddate = new Date(this.$MyConstant('Api_CONSTANT.SizeConfig.ini_cpse_enddate').split(" ")[0]);
    this.currentYear = date.getFullYear();
    this.currentMonth = date.getMonth() + 1;
    this.currentDay = date.getDate();
    this.currentEndDay = enddate.getDate();
    //当前年
    this.selectYear = (new Date()).getFullYear()

    const endTime = new Date(this.$MyConstant('Api_CONSTANT.SizeConfig.ini_cpse_date').split(" ")[0]).getTime(); // 替换成你的展会结束时间
    this.updateCountdown(endTime);
    setInterval(() => {
      this.updateCountdown(endTime);
    }, 1000);
  },
  methods: {
    updateCountdown(endTime) {
      const now = new Date().getTime();
      this.countdown = endTime - now;

      if (this.countdown > 0) {
        this.days = Math.floor(this.countdown / (1000 * 60 * 60 * 24));
        this.hours = Math.floor(
            (this.countdown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        this.minutes = Math.floor(
            (this.countdown % (1000 * 60 * 60)) / (1000 * 60)
        );
        this.seconds = Math.floor((this.countdown % (1000 * 60)) / 1000);
      }
    },

  },
  mounted() {


    //数字转动
    const numberVFXElements = document.querySelectorAll('.numberVFX');
    function getRandomValue(max) {
      return Math.floor(Math.random() * (max + 1));
    }
    numberVFXElements.forEach((numberVFXElement) => {
      const originalValue = parseInt(numberVFXElement.textContent, 10);
      const hasPlusSign = numberVFXElement.textContent.includes("+");
      const decreasedValue = originalValue - getRandomValue(originalValue);
      const newValue = decreasedValue + (hasPlusSign ? "+" : "");
      numberVFXElement.textContent = newValue;
      numberVFXElement.classList.add('numberVFX');
      const interval = setInterval(() => {
        const currentValue = parseInt(numberVFXElement.textContent, 10);
        const newValue = currentValue + getRandomValue(originalValue - currentValue);
        numberVFXElement.textContent = newValue + (hasPlusSign ? "+" : "");
      }, 400);

      setTimeout(() => {
        numberVFXElement.textContent = originalValue + (hasPlusSign ? "+" : "");
        clearInterval(interval);
      }, 3000);
    });
    //结束

    // 导航固定
    $(window).on('scroll', function () {
      var w = $(document).scrollTop();//获取滚动条到顶部的垂直高度
      if (w >= 1) {
        $(".head").hide();
        // $("").css();
        $(".head-menu").css({
          "position": "fixed",
          "top": "0px",
          "left": "0px",
          "z-index": "1000px",
          "margin-top": "0px"
        });
      } else {

        $(".head").show();
        // $(".head-menu").css({"position":"fixed","top":"90px","left":"0px","z-index":"100px"});
        $(".head-menu").css({
          "position": "fixed",
          "top": "0px",
          "left": "0px",
          "z-index": "1000px",
          "margin-top": "93px"
        });

      }
    });
  }

};
</script>

<style scoped>
.threeButton{
  background:url(../../../assets/img/shouye/f109d0d.png);width: 100%;height:111px;display: flex;align-items: center;justify-content:center;color: #fff;font-size: 12px;margin-top: -5px;
}
.news-card {
  margin-bottom: 20px;
  padding: 0px;

}

.image-container {
  display: flex;
  align-items: center;
}

.news-image {
  width: 100%;
  height: auto;
}

.news-details {
  padding-left: 10px;
}

.news-title {
  color:#232529;
  font-size: 0.95rem;
  font-weight: bold;
  margin-bottom: 10px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;

}

.news-time {
  font-size: 9.62px;
  color: #5C5F66;
}
.el-card {
  --el-card-padding: 0; /* 设置内边距为 0 */
}

/*文字撑开特效*/
#demo{
  animation:showup 6s forwards;
}
@keyframes showup{
  from{
    letter-spacing: 0px;
    font-size: 5px;
    filter:blur(2px);
  }
  to
  {
    letter-spacing: 0px;
    font-size: 12px;
    filter:blur(0px);
  }
}
</style>
