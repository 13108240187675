<template>
  <div class="banner">
    <div class="swiper-container swiper-container1">
      <div class="swiper-wrapper">

        <div class="swiper-slide">
          <img src="../../../assets/img/sch.png">
        </div>
      </div>
    </div>
  </div>

  <div class="about">
    <div class="about-top">
      <p>{{ this.$MyConstant('Api_CONSTANT.menu.Usermenu.exhibition.name_cn') }}</p>
      <img src="../../../assets/img/icon/jiantou.png">
      <em>{{ this.$MyConstant('Api_CONSTANT.menu.Usermenu.exhibition.list.shouce.name_cn') }}</em>

    </div>
    <div class="contentMiddle">
      <!-- 左边 -->
      <div class="contentLeft">

      </div>


      <!-- 右边 -->
      <div class="about-right">
        <div class="about-right1" v-for="(item,key) in this.$MyConstant('Api_CONSTANT.menu.Usermenu.exhibition.list')">
          <custom-link :style="{'color': this.$route.fullPath == '/'+MY_CONSTANT.setApiLang+item.url ? 'var(--uavdeep-color)' : ''}" :to="item.url">
            <img src="../../../assets/img/icon/sanjiao.png">
            <em>{{ item.name_cn }}</em>
          </custom-link>
        </div>

        <Components :datas= '{
        "Template/About-right": "",
         }'>
        </Components>

      </div>

    </div>

  </div>

</template>

<script>
import Components from '../../../modules/Components.vue'
import api from "@/assets/js/api";
export default {
  components: {
    Components
  },
  data() {
    return {
      DownloadList:[],
    }
  },
  async  created() {
    const response = await api.ajaxbaseUrlget('/api/public_view/Download',{class_id:this.$route.query.class_id,lang:this.$MyConstant('sqlWhereLang')});
    this.DownloadList=response.data.obj.data
  },
  methods: {

  }
};
</script>
<style scoped>


</style>
