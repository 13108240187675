<template>
  <div class="banner">
    <div class="swiper-container swiper-container1">
      <div class="swiper-wrapper">

        <div class="swiper-slide">
          <img src="../../../assets/img/sch.png">
        </div>
      </div>
    </div>
  </div>
  <div class="about">
    <div class="about-top">
      <p>{{ this.$MyConstant('Api_CONSTANT.menu.Usermenu.exhibition.name_'+MY_CONSTANT.sqlWhereLang) }}</p>
      <img src="../../../assets/img/icon/jiantou.png">
      <em>{{ this.$MyConstant('Api_CONSTANT.menu.Usermenu.exhibition.list.edit_profile.name_'+MY_CONSTANT.sqlWhereLang) }}</em>

    </div>
    <div class="contentMiddle">
      <!-- 左边 -->
      <div class="contentLeft">

        <!-- 左边 -->
        <el-form  v-if="this.$cookies.isKey('Cookie')" :model="ruleForm" :rules="rules" ref="ruleForm"  class="demo-ruleForm" id="register_form">


          <el-form-item :label="MY_CONSTANT.Lang.linkman+'：'" prop="link_name">
            <el-input class="input307" v-model="ruleForm.link_name"    :placeholder="this.$MyConstant('Lang.PleaseEnter')"    ></el-input>

          </el-form-item>


          <el-form-item :label="this.$MyConstant('Lang.mobile')+'：'" prop="mobile">
            <el-input class="input307" v-model="ruleForm.mobile" @keyup="strReplace('mobile',{'\\D': ' ',' ': ''})"  :placeholder="this.$MyConstant('Lang.PleaseEnter')"></el-input>
          </el-form-item>

          <el-form-item :label="this.$MyConstant('Lang.WechatID')+'：'" prop="weixin">
            <el-input class="input307" v-model="ruleForm.weixin" :placeholder="this.$MyConstant('Lang.PleaseEnter')" @keyup="strReplace('weixin',{' ': ''})"   onkeyup="this.value=this.value.replace(/[\u4E00-\u9FA5]/g,'')" ></el-input>
          </el-form-item>

          <el-form-item label="E-mail" prop="email">
            <el-input class="input307" v-model="ruleForm.email" :placeholder="this.$MyConstant('Lang.PleaseEnter')" @keyup="strReplace('email',{' ': ''})"></el-input>
          </el-form-item>

          <el-form-item :label="this.$MyConstant('Lang.company_fun')+'（'+this.$MyConstant('Lang.Multiplechoice')+'）：'" prop="company_fun" :validate-on-model-update="false" class="Linheight28">

            <el-checkbox-group  v-model="ruleForm.company_fun"    style="width: 585px;">
              <el-checkbox  style="width: 260px;"   class="myRedCheckBox"  @change="handleRolecompany_fun" v-for="company_funs in company_funs" :label="company_funs" name="company_fun"  :key="company_funs">{{company_funs}}</el-checkbox>
            </el-checkbox-group>

            <el-form-item :label="'\n'" label-width="0px" styles="position: absolute;left: -12px;" v-if="company_funs_add" prop="company_fun_add" >
              <el-input class="input307" v-model="ruleForm.company_fun_add" :placeholder="this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.otherConten')"></el-input>
            </el-form-item>
          </el-form-item>

          <div style="width: 270px;text-align: right;margin-bottom:30px;display: inherit; ">
            <label style="font-size: 18px;font-weight: normal;line-height: 26px;letter-spacing: 0em;">{{this.$MyConstant('Lang.ExhibitorInformation')}}</label>
          </div>




          <el-form-item :label="this.$MyConstant('Lang.CCompanyName')+'：'" prop="cp_name">
            <!--                        '[^\\u4e00-\\u9fa5]': '',-->
            <el-input class="input400" @keyup="strReplace('cp_name',{' ': ''})" v-model="ruleForm.cp_name" placeholder="例：深圳市安博会展有限公司" onkeyup=""></el-input>

          </el-form-item>
          <el-form-item :label="this.$MyConstant('Lang.ECompanyName')+'：'" prop="cp_en_name">

            <el-input class="input400"  v-model="ruleForm.cp_en_name" @keyup="strReplace('cp_en_name',{'[\\u4E00-\\u9FA5]': '','\\s+':' '})" placeholder="例：Shenzhen CPSE Exhibition Co., Ltd" ></el-input>

          </el-form-item>


          <el-form-item :label="this.$MyConstant('Lang.SCCompanyName')+'：'" prop="company_shortname">

            <el-input class="input400" maxlength="4"  @keyup="strReplace('company_shortname',{'[^\\u4e00-\\u9fa5]': '',' ': ''})"  v-model="ruleForm.company_shortname" placeholder="例：安博会展"  onkeyup=""></el-input><br>
            <div class="el_input__tip" style="">
<!--              字数不超过4个汉字，此项将展示于会刊的中文版展馆导览图中。-->
            </div>
          </el-form-item>
          <el-form-item :label="this.$MyConstant('Lang.SECompanyName')+'：'" prop="company_en_shortname">

            <el-input class="input400"  v-model="ruleForm.company_en_shortname" placeholder="例：CPSE"  @keyup="strReplace('company_en_shortname',{'[\\u4e00-\\u9fa5]': ''})" maxlength="12"></el-input><br>
            <div class="el_input__tip" style="">
<!--              字数不超过12个字符（计空格），此项将展示于会刊的英文版展馆导览图中，英文简称请尽可能简洁，否则将导致印刷后显示字体过小、难以辨认。-->
            </div>

          </el-form-item>




          <el-form-item :label="this.$MyConstant('Lang.NationRegion')+'：'" prop="selecteAddress">

            <el-cascader
                size="large"
                :options="pcaTextArr"
                v-model="ruleForm.selecteAddress">
            </el-cascader>

          </el-form-item>

          <el-form-item v-if="this.$MyConstant('sqlWhereLang')=='cn'" :label="this.$MyConstant('Lang.addressInfo')+'：'"  prop="link_address">

            <el-input class="input400"  v-model="ruleForm.link_address" @keyup="strReplace('link_address',{' ': ''})" :placeholder="this.$MyConstant('Lang.PleaseEnter')" onkeyup=""></el-input>

          </el-form-item>


          <el-form-item v-if="this.$MyConstant('sqlWhereLang')=='en'" :label="this.$MyConstant('Lang.addressInfo')+'：'" prop="link_en_address" class="Linheight28">

            <el-input type="textarea" @keydown.enter.native="handleTextareaKeydown" v-model="ruleForm.link_en_address" :placeholder="this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.Conten')" @keyup="strReplace('link_en_address',{'[\\u4E00-\\u9FA5]': '','\\n':' ','\\s+':' '})"></el-input>

          </el-form-item>

          <el-form-item :label="this.$MyConstant('Lang.Postalcode')+'：'" prop="zipcode">

            <el-input class="input307" v-model="ruleForm.zipcode"  :placeholder="this.$MyConstant('Lang.PleaseEnter')"   @keyup="strReplace('zipcode',{' ': ''})"></el-input>

          </el-form-item>

          <el-form-item :label="this.$MyConstant('Lang.Cphone')+'：'" prop="zuoji">
            <div class="zuoji" style="display: inline-flex;align-items: center;">
              <el-input size="min" :style="{'width': MY_CONSTANT.sqlWhereLang === 'cn' ? '138px' : '170px'}" v-model="ruleForm.phone1" :placeholder="this.$MyConstant('Lang.areaCode')" @keyup="strReplace('phone1',{' ': ''})"></el-input>
              <span style="margin: 0px 5px;">-</span>
              <el-input  v-model="ruleForm.phone" :placeholder="this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.landline')" @keyup="strReplace('phone',{' ': ''})"></el-input>
            </div>
          </el-form-item>


          <el-form-item :label="this.$MyConstant('Lang.company_types')+'：'" prop="company_types">
            <el-select class="select400" size="large"  v-model="ruleForm.company_types" :placeholder="this.$MyConstant('Lang.PleaseSelect')+this.$MyConstant('Lang.company_types')"  @change="handleRolecompany_types">
              <el-row v-for="(el, index) in company_types" :key="index">
                <el-col v-if="index % 2 == 1" :span="12">
                  <el-option :label="company_types[index-1]" :value="company_types[index-1]"> </el-option>
                </el-col>
                <el-col v-if="index % 2 == 1 || (index % 2 == 0 && index == company_types.length-1)" :span="12">
                  <el-option :label="el" :value="el"> </el-option>
                </el-col>
              </el-row>
            </el-select>
          </el-form-item>

          <el-form-item v-if="show_company_bank" :label="this.$MyConstant('Lang.Agencybrand')+'：'" prop="company_bank">
            <el-input class="input307" v-model="ruleForm.company_bank" @keyup="strReplace('company_bank')" :placeholder="this.$MyConstant('Lang.PleaseEnter')"></el-input>
          </el-form-item>
          <el-form-item v-if="show_company_type" :label="this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.other')+this.$MyConstant('Lang.company_types')+'：'" :placeholder="this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.otherConten')"  prop="company_type_qita">
            <el-input class="input307" v-model.trim="ruleForm.company_type_qita"  :placeholder="this.$MyConstant('Lang.PleaseEnter')">
            </el-input>
          </el-form-item>


          <el-form-item :label="this.$MyConstant('Lang.company_nature')+'：'" prop="nature">
            <el-select class="select400" v-model="ruleForm.nature" size="large"  :placeholder="this.$MyConstant('Lang.PleaseSelect')+this.$MyConstant('Lang.company_nature')" >

              <el-row v-if="this.$MyConstant('sqlWhereLang')=='cn'" v-for="(el, index) in this.$MyConstant('Api_CONSTANT.DimensionLists.company_nature')" :key="index">
                <el-col v-if="index % 2 == 1" :span="12">
                  <el-option :label="this.$MyConstant('Api_CONSTANT.DimensionLists.company_nature')[index-1].nature" :value="this.$MyConstant('Api_CONSTANT.DimensionLists.company_nature')[index-1].nature"> </el-option>
                </el-col>
                <el-col v-if="index % 2 == 1 || (index % 2 == 0 && index == this.$MyConstant('Api_CONSTANT.DimensionLists.company_nature').length-1)" :span="12">
                  <el-option :label="el.nature" :value="el.nature"> </el-option>
                </el-col>
              </el-row>

              <el-row  v-if="this.$MyConstant('sqlWhereLang')=='en'">
                <el-col :span="14">
                  <el-option label="State-Owned Enterprises"  :value="'State-Owned Enterprises'"> </el-option>
                  <el-option label="Domestic-Funded Private Enterpries"  :value="'Domestic-Funded Private Enterpries'"> </el-option>
                  <el-option label="Sino-Foreign Equity Joint Ventures"  :value="'Sino-Foreign Equity Joint Ventures'"> </el-option>
                </el-col>
                <el-col :span="14">
                  <el-option label="Wholly Foreign-Owned Enterprises"   :value="'Wholly Foreign-Owned Enterprises'"> </el-option>
                  <el-option label="Hong Kong, Macao, Taiwan-Invested Enterprises"   :value="'Hong Kong, Macao, Taiwan-Invested Enterprises'"> </el-option>
                </el-col>
              </el-row>

            </el-select>
          </el-form-item>

          <el-form-item :label="this.$MyConstant('Lang.listedOrNot')+'：'" prop="finance">
            <el-select v-if="this.$MyConstant('sqlWhereLang')=='cn'" size="large" class="select400" v-model="ruleForm.finance" :placeholder="this.$MyConstant('Lang.PleaseSelect')+this.$MyConstant('Lang.listedOrNot')" >
              <el-option  :label="s.finance" v-for="s in this.$MyConstant('Api_CONSTANT.DimensionLists.company_finance')" :value="s.finance"> </el-option>
            </el-select>
            <el-select v-if="this.$MyConstant('sqlWhereLang')=='en'" size="large"  class="select400" v-model="ruleForm.finance" :placeholder="this.$MyConstant('Lang.PleaseSelect')+this.$MyConstant('Lang.listedOrNot')" >
              <el-option label="Listed Company"  :value="'Listed Company'"></el-option>
              <el-option label="Non-Listed Company"   :value="'Non-Listed Company'"> </el-option>
              <el-option label="Non-Enterprise"   :value="'Non-Enterprise'"> </el-option>
            </el-select>

          </el-form-item>

          <el-form-item size="large" :label="MY_CONSTANT.Lang.EnterpriseHonours+'：'" style="" prop="honor"
                        :rules="honorsRules">
            <div v-for="(honorItem, index) in this.ruleForm.honor" :key="index"
                 style="width: 115%;">
              <div style="display: flex;margin-bottom: 10px;">
                <el-select :class="{
                                     'error-shadow': !isHonorSelected[index]&&!ruleForm.havehonor,
                                     'success-shadow': isHonorSelected[index]||!ruleForm.havehonor
                                   }"
                           :disabled="!ruleForm.havehonor" v-model="honorItem.year" size="large"
                           style="margin-right:10px;width: 180px;"
                           :placeholder="MY_CONSTANT.Lang.SelectedYear">
                  <el-option v-for="year in honorYears" :value="year" :key="year">{{ year }}</el-option>
                </el-select>
                <el-select :class="{
                                     'error-shadow': !isHonorSelected[index]&&!ruleForm.havehonor,
                                     'success-shadow': isHonorSelected[index]||!ruleForm.havehonor
                                   }"
                           :disabled="!ruleForm.havehonor" v-model="honorItem.honor" size="large"
                           :placeholder="MY_CONSTANT.Lang.SelecteHonour">
                  <el-option v-for="Key in MY_CONSTANT.Lang.honorArr" :label="Key" :value="Key"></el-option>
                </el-select>
                  <span
                      @click="removeHonor(index)"
                      style="padding-left: 10px; font-size: 16px; color: #7e7e7e;width: 100px;"
                      v-if="index>0"
                  >
                    <el-icon><Minus /></el-icon>
                  </span>
                  <span
                      @click="addHonor(index)"
                      style="padding-left: 10px;font-size: 16px; color: #7e7e7e;width: 100px;display: flex;justify-items: center;align-items: center;"
                      v-else
                  >
                  <el-icon><Plus /></el-icon>{{ MY_CONSTANT.Lang.Add }}
                  </span>

              </div>
            </div>



<!--            <div class="el_input__tip" style="padding: 10px;font-size: 14px;width: 100%;">-->
<!--              <el-text @click="addHonor(index)">+{{ MY_CONSTANT.Lang.AddHonour }}</el-text>-->
<!--            </div>-->

            <div>
              <el-radio-group
                  v-model="ruleForm.havehonor"
                  @click="haveHonor($event)"
              >
                <el-radio :label="0">{{ MY_CONSTANT.Lang.NotSelectedHonours }}</el-radio>
              </el-radio-group>
            </div>
          </el-form-item>



          <el-form-item :label="this.$MyConstant('Lang.Website')+'：'" prop="website" :rules="websiteRules" style="">

            <el-select :disabled="ruleForm.havewebsite==0" class="optionsWebsite" size="large" style="width: 100px;" v-model="websitehttp" @change="handleRolewebsite"   :placeholder="this.$MyConstant('Lang.PleaseSelect')">
              <el-option label="http://" value="http://"></el-option>
              <el-option label="https://" value="https://"></el-option>
            </el-select>
            <el-input  :disabled="ruleForm.havewebsite==0" :placeholder="this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.Conten')" @keyup="strReplace('website',{' ': ''})" v-model="ruleForm.website" class="input-with-select"> </el-input>
            <br>
            <div class="el_input__tip" style="">
              {{MY_CONSTANT.Lang.WebsiteDesc}}
            </div>
            <div>

            <el-radio-group
                v-model="ruleForm.havewebsite"
                @click="haveWebSize($event)"
            >
              <el-radio :label="0">{{MY_CONSTANT.Lang.WebsiteNo}}</el-radio>
            </el-radio-group>

            </div>
          </el-form-item>



          <el-form-item label="Logo" prop="logo">


<!--            <el-upload-->
<!--                class="avatar-uploader"-->
<!--                :action="MY_CONSTANT.Api_CONSTANT.baseUrl+'/api/upload/a'"-->
<!--                :before-upload="beforeAvatarUpload"-->
<!--                :on-success="imgUploadSuccess"-->
<!--                :before-remove="removeUpload"-->
<!--                :headers="uploadHeaders"-->
<!--            >-->
<!--              <el-button size="small" ><i class="el-icon-upload2"></i>&nbsp;&nbsp;<span class="uploadText">{{this.$MyConstant('Lang.uploadFile')}}</span></el-button>-->

<!--            </el-upload>-->

            <el-button size="large"  @click="uploadPicture"><i class="el-icon-upload2"></i>&nbsp;&nbsp;<span class="uploadText">{{this.$MyConstant('Lang.uploadFile')}}</span></el-button>

            <div style="color:#999"  class=" logo">{{this.$MyConstant('Lang.LogoUploadDesc')+this.$MyConstant('Upload.logo.size')+'M,'+this.$MyConstant('Upload.logo.width')+'*'+this.$MyConstant('Upload.logo.height')}}</div>


            <img style="width: 200px" v-show="ruleForm.logo" :src="ruleForm.logo" class="avatar">

            <br>



            <el-radio-group
                v-model="ruleForm.logo"
                @click="haveLogo($event)"
            >
              <el-radio :label="this.$MyConstant('User.defaultLogo')">{{MY_CONSTANT.Lang.CNoLogo}}</el-radio>
            </el-radio-group>


          </el-form-item>



          <el-form-item :label="MY_CONSTANT.Lang.service+'（'+MY_CONSTANT.Lang.Multiplechoice+'）：'" prop="service"  class="Linheight28" >


            <el-checkbox-group  v-model="ruleForm.service"  style="">
              <el-checkbox   class="myRedCheckBox"  @change="handleRoleservice" v-for="services in services" :label="services" name="service"  :key="services">{{services}}</el-checkbox>
            </el-checkbox-group>

            <br>
            <el-form-item :label="'\n'" v-if="services_add" prop="service_add"  label-width="0px" styles="position: absolute;left: -12px;">
              <el-input class="input307" v-model="ruleForm.service_add" :placeholder="this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.otherConten')"></el-input>
            </el-form-item>

          </el-form-item>


          <el-form-item :label="this.$MyConstant('Lang.product_type')+'（'+this.$MyConstant('Lang.Multiplechoice')+'）：'" prop="product_type"  class="Linheight28" >
            <el-checkbox-group  v-model="ruleForm.product_type"  style="">
              <el-checkbox   class="myRedCheckBox"  @change="handleRoleproduct_type" v-for="product_types in product_types" :label="product_types" name="product_type"  :key="product_types">{{product_types}}</el-checkbox>
            </el-checkbox-group>

            <el-form-item :label="'\n'" v-if="product_types_add" prop="product_type_add"  label-width="0px" styles="position: absolute;left: -12px;">
              <el-input class="input307" v-model="ruleForm.product_type_add" :placeholder="this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.otherConten')"></el-input>
            </el-form-item>

          </el-form-item>

          <el-form-item :label="this.$MyConstant('Lang.Purposeofexhibition')+'\n'+'（'+this.$MyConstant('Lang.Multiplechoice')+'）：'" prop="exhibition_aim"  class="Linheight28" >
            <el-checkbox-group  v-model="ruleForm.exhibition_aim"  style="">
              <el-checkbox   class="myRedCheckBox"  @change="handleRoleexhibition_aim" v-for="exhibition_aims in exhibition_aims" :label="exhibition_aims" name="exhibition_aim"  :key="exhibition_aims">{{exhibition_aims}}</el-checkbox>
            </el-checkbox-group>

            <el-form-item :label="'\n'" v-if="exhibition_aims_add" prop="exhibition_aim_add"  label-width="0px" styles="position: absolute;left: -12px;">
              <el-input class="input307" v-model="ruleForm.exhibition_aim_add" :placeholder="this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.otherConten')"></el-input>
            </el-form-item>

          </el-form-item>

          <el-form-item :label="this.$MyConstant('Lang.channel')" prop="channel" class="Linheight28">
            <el-checkbox-group  v-model="ruleForm.channel"  style="">
              <el-checkbox   class="myRedCheckBox"  @change="handleRolechannel" v-for="channels in channels" :label="channels" name="channel"  :key="channels">{{channels}}</el-checkbox>
            </el-checkbox-group>


            <el-form-item :label="'\n'" v-if="channels_add" prop="channel_add"  label-width="0px" styles="position: absolute;left: -12px;">
              <el-input class="input307" v-model="ruleForm.channel_add" :placeholder="this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.otherConten')"></el-input>
            </el-form-item>

          </el-form-item>

          <div style="width: 270px;text-align: right;margin-bottom:30px;display: inherit; ">
            <label style="font-size: 18px;font-weight: normal;line-height: 26px;letter-spacing: 0em;">{{ MY_CONSTANT.Lang.exhibitionInformation }}</label>
          </div>





          <el-form-item :label="this.$MyConstant('Lang.fascia')" prop="fascia">

            <el-input class="input564"   @keyup="strReplace('fascia',{'\\s+': ' '})" v-model="ruleForm.fascia" :placeholder="this.$MyConstant('Lang.cp_nameNrand')" ></el-input><br>
            <div class="el_input__tip" style="width:564px;">
              {{this.$MyConstant('Lang.showCp_nameNrand')}}
            </div>

          </el-form-item>

          <el-form-item :label="this.$MyConstant('Lang.fascia_en')" prop="fascia_en">

            <el-input class="input564"  v-model="ruleForm.fascia_en" :placeholder="this.$MyConstant('Lang.cp_nameNrand')" @keyup="strReplace('fascia_en',{'[\\u4E00-\\u9FA5]': '','\\s+': ' '})"></el-input><br>
            <div class="el_input__tip" style="width:564px;">
              {{this.$MyConstant('Lang.showCp_nameNrand')}}
            </div>

          </el-form-item>


          <el-form-item :label="this.$MyConstant('Lang.cp_profile')" prop="cp_profile" class="Linheight28">　　

            <el-input type="textarea" v-model="ruleForm.cp_profile" @keyup="strReplace('cp_profile',{'\\s+': ' ','\\n':''})" @keydown.enter.native="handleTextareaKeydown"  :placeholder="this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.Conten')" onkeyup="" ></el-input>
            <!--                        maxlength="250"-->

            <div class="el_textarea__tip" style="">
              {{this.$MyConstant('Lang.cp_profileDesc')}}
            </div>
          </el-form-item><br><br>

          <el-form-item :label="this.$MyConstant('Lang.cp_profilehuikang')" prop="cp_en_profile" class="Linheight28">

            <el-input type="textarea" @keyup="strReplace('cp_en_profile',{'\\s+': ' ','\\n':''})" v-model="ruleForm.cp_en_profile" @keydown.enter.native="handleTextareaKeydown" :placeholder="this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.Conten')" onkeyup=""></el-input>



            <div class="el_textarea__tip" style="">
              <span class="textareaLength cp_en_profile">({{ruleForm.cp_en_profile==null?0:ruleForm.cp_en_profile.length}}/250)</span>
              {{this.$MyConstant('Lang.cp_profilehuikangDesc')}}
            </div>

          </el-form-item><br><br>


          <el-form-item :label="this.$MyConstant('Lang.cp_profileE')" prop="cp_bulletin_profile" class="Linheight28">

            <el-input type="textarea" v-model="ruleForm.cp_bulletin_profile" @keyup="strReplace('cp_bulletin_profile',{'[\\u4e00-\\u9fa5]': '','\\n':' ','\\s+':' ','\/+':'/','\\\\':'','#':''})" @keydown.enter.native="handleTextareaKeydown" :placeholder="this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.Conten')"  ></el-input>

            <div class="el_textarea__tip" style="">
              {{this.$MyConstant('Lang.cp_profileDesc')}}
            </div>
          </el-form-item><br><br>

          <el-form-item :label="this.$MyConstant('Lang.cp_profileEhuikang')"  prop="cp_en_bulletin_profile" class="Linheight28">

            <el-input type="textarea" v-model="ruleForm.cp_en_bulletin_profile"  @keyup="strReplace('cp_en_bulletin_profile',{'[\\u4e00-\\u9fa5]': '','\\n':' ','\\s+':' ','\/+':'/','\\\\':'','#':''})" @keydown.enter.native="handleTextareaKeydown" :placeholder="this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.Conten')" ></el-input>

            <div class="el_textarea__tip" style="">
              <span class="textareaLength cp_en_bulletin_profile">({{ruleForm.cp_en_bulletin_profile==null?0:ruleForm.cp_en_bulletin_profile.length}}/500)</span>
              {{this.$MyConstant('Lang.cp_profileEhuikangDesc')}}
            </div>
          </el-form-item><br><br>

          <el-form-item :label="this.$MyConstant('Lang.formMain_exhibit')" prop="main_exhibit" label-width="" class="Linheight28">
            <div class="zhuyaoproduct">
              <el-input label-width="200px" @keyup="strReplace('main_exhibit',{'　　':'',' ': ''})" maxlength="30"  v-model="ruleForm.main_exhibit" :placeholder="this.$MyConstant('Lang.PleaseEnter')" ></el-input>
              <!--                            onkeyup="value=value.replace(/[^\w\u4E00-\u9FA5]/g, '')"-->
            </div>
            <div class="el_textarea__tip" style="">
              {{this.$MyConstant('Lang.formMain_exhibitDesc')}}
            </div>

          </el-form-item><br><br>

          <el-form-item :label="this.$MyConstant('Lang.formMain_exhibitE')" prop="main_en_exhibit" class="Linheight28">

            <el-input  type="textarea" @keyup="strReplace('main_en_exhibit',{'[\\u4e00-\\u9fa5]': '','\\n':' ','\\s+':' ','\/+':'/','\\\\':'','#':''})"  @keydown.enter.native="handleTextareaKeydown" v-model="ruleForm.main_en_exhibit" :placeholder="this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.Conten')" ></el-input>

            <div class="el_textarea__tip" style="">
              <span class="textareaLength main_en_exhibit">({{ruleForm.main_en_exhibit==null?0:ruleForm.main_en_exhibit.length}}/100)</span>
              {{this.$MyConstant('Lang.formMain_exhibitEDesc')}}
            </div>
          </el-form-item><br><br>

          <el-form-item :label="'\n'" >
            <el-button v-if="this.$MyConstant('Api_CONSTANT.SizeConfig.site_edit_closed')== 0 " type="danger"  @click="submitForm('ruleForm')" >
              {{MY_CONSTANT.Lang.submit}}</el-button>
          </el-form-item>

        </el-form>

      </div>
      <!-- 右边 -->
      <div class="about-right">
        <div class="about-right1" v-for="(item,key) in this.$MyConstant('Api_CONSTANT.menu.Usermenu.exhibition.list')">
          <custom-link :style="{'color': 'edit_profile' == key ? 'var(--uavdeep-color)' : ''}" :to="item.url">
            <img src="../../../assets/img/icon/sanjiao.png">
            <em>{{ item['name_'+MY_CONSTANT.sqlWhereLang] }}</em>
          </custom-link>
        </div>

        <Components :datas= '{
        "Template/About-right": "",
         }'>
        </Components>

      </div>

    </div>

  </div>

  <!--  :title="MY_CONSTANT.setApiLang=='zh-cn'?'温馨提示!':'Warm reminder!'"-->
  <el-dialog :close-on-click-modal="false"  :show-close="false" v-model="First" width="500">
    <div>
      <h4>{{MY_CONSTANT.setApiLang=='zh-cn'?'尊敬的展商':'Dear exhibitor'}}：</h4>
      <p style="text-indent: 2em;margin-top: 5px;">
        {{
          MY_CONSTANT.setApiLang=='zh-cn'?
              ' 请务必完善展商中心相关资料，届时将用于网站及会刊宣传！':
              'Please be sure to improve the relevant information of the exhibitor center, which will be used for the website and the promotion of the conference!'
        }}
      </p>
    </div>
    <template #footer>
      <div class="dialog-footer">
        <el-button type="danger" @click="First=false">
          {{
            MY_CONSTANT.setApiLang=='zh-cn'?'确认':'Confirm'
          }}
        </el-button>
      </div>
    </template>
  </el-dialog>

  <!-- 剪裁组件弹窗 -->
  <el-dialog
      :title="MY_CONSTANT.Lang.Upload+'logo'"
      v-model="cropperModel"
      width="100%"
      style="max-width: 1000px;"
      center
  >

    <upload-cropper
        @uploadImgSuccess = "handleUploadSuccess"
        ref="child">
    </upload-cropper>

  </el-dialog>

</template>

<script >
import Components from '../../../modules/Components.vue'
import validate, {validateBigEetter} from "@/assets/js/validation";
import api from "@/assets/js/api";
import  { pcaTextArr }   from 'element-china-area-data';//regionData 地标是维度值
import uploadCropper from "./VueCropper";
import {MyConstant} from "@/assets/js/globalFunctions";
export default {
  components: {
    Components,
    uploadCropper
  },
  data() {


    const validatezuoji =  (rule, value, callback) => {

      // if(this.ruleForm.phone1===''){
      //   callback(new Error(this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.areaCode')));
      // }
      if(this.ruleForm.phone===''){
        callback(new Error(this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.landline')));
      }
      callback();


    };

    const validateHonor = (rule, value, callback) => {
      if (!this.ruleForm.havehonor) {
        callback();
      }
      let hasError = false;let errMsg = this.$MyConstant('Lang.PleaseSelect');
      const honorChoices = [];
      const unselectedIndexes = []; // 用于存储未选择或重复的荣誉项的索引

      this.ruleForm.honor.forEach((honor, index) => {
        // 初始化 isHonorSelected 为 true
        this.isHonorSelected[index] = true;

        // 检查每一项荣誉是否已选择
        if (!honor.year || !honor.honor) {
          hasError = true;
          unselectedIndexes.push(index); // 记录未选择的荣誉项的索引
        }

        // 创建一个荣誉选择的字符串表示，以便比较
        const honorChoice = `${honor.year}-${honor.honor}`;

        // 检查是否已经存在相同的荣誉选择
        if (honorChoices.includes(honorChoice)) {
          hasError = true;
          errMsg = this.$MyConstant('Lang.HonourExists')
          unselectedIndexes.push(index); // 记录重复的荣誉项的索引
        }

        // 如果没有找到重复，将当前荣誉选择添加到列表中
        honorChoices.push(honorChoice);
      });

      // 更新 isHonorSelected 数组，将未选择或重复的荣誉项标记为 false
      unselectedIndexes.forEach(index => {
        this.isHonorSelected[index] = false;
      });

      // 根据 hasError 的值决定是否调用 callback 函数
      if (hasError) {
        callback(new Error(errMsg));
      } else {
        callback();
      }
    };

    return {
      JumpPath:this.$route.query.s?window.atob(this.$route.query.s):'/UserEdit',
      First:this.$route.query.First,
      websitehttp:'http://',
      pcaTextArr:[],
      ruleForm:[],
      company_funs:[],
      company_funs_add:false,
      services:[],
      company_types:[],
      product_types_add:false,
      product_types:[],
      exhibition_aims_add:false,
      exhibition_aims:[],
      channels_add:false,
      channels:[],
      services_add:false,
      show_company_bank:false,
      show_company_type:false,
      autoPlaySave:false,
      cropperModel:false,
      isHonorSelected: [],
      rules: {
        link_name: [
          { required: true, message: () => this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.linkman'), trigger: 'blur'},
          // {pattern: /^[\u4e00-\u9fa5A-Za-z]+$/, message: () => '请输入中英文',trigger: ['change','blur'] }

        ],

        mobile: [
          { required: true, message: () => this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.mobile'), trigger: 'blur' },
          { validator: validate.validateMobile, trigger: 'blur' },
        ],
        email: [
          { required: true, message: () => this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.Email'), trigger: 'blur'},
          { type: 'email', message: () => this.$MyConstant('Lang.PleaseCorrect')+this.$MyConstant('Lang.Email'), trigger: ['blur', 'change'] },
          { validator: validate.validateBigEetter, trigger: 'blur' },
          { validator: validate.validateUser, trigger: 'blur' }
        ],
        company_fun: [
          { type: 'array', required: true, message: () => this.$MyConstant('Lang.PleaseSelectOne')+this.$MyConstant('Lang.company_fun'), trigger: ['change','blur'] },
          { validator: validate.validateSelect, trigger: 'blur' },
        ]
        ,  company_fun_add: [

          { required: true, message: () => this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.other'), trigger: ['change','blur'] }

        ],
        company_types: [

          { required: true, message: () => this.$MyConstant('Lang.PleaseSelect')+this.$MyConstant('Lang.company_types'), trigger: 'blur' },

        ],
        company_bank: [

          { required: true, message: () => this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.Agencybrand'), trigger: ['change','blur'] }

        ],  company_type_qita: [

          { required: true, message: () => this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.other'), trigger: ['change','blur'] }

        ],nature: [

          { required: true, message: () => this.$MyConstant('Lang.PleaseSelect')+this.$MyConstant('Lang.company_nature'), trigger: ['change','blur'] }

        ] , finance: [

          { required: true, message: () => this.$MyConstant('Lang.PleaseSelect')+this.$MyConstant('Lang.listedOrNot'), trigger: ['change','blur'] }

        ], service:  [

            { type: 'array', required: true, message: () => this.$MyConstant('Lang.PleaseSelectOne')+this.$MyConstant('Lang.service'), trigger: ['change','blur'] },
            { validator: validate.validateSelect, trigger: 'blur' },

           ]  ,  service_add: [

          { required: true, message: () => this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.other'), trigger: ['change','blur'] }

          ],product_type: [

          { type: 'array', required: true, message: () => this.$MyConstant('Lang.PleaseSelectOne')+this.$MyConstant('Lang.product_type'), trigger: ['change','blur'] },
          { validator: validate.validateSelect, trigger: 'blur' },

        ]
        ,  product_type_add: [

          { required: true, message: () => this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.other'), trigger: ['change','blur'] }

        ]

        ,exhibition_aim: [
          { type: 'array', required: true, message: () => this.$MyConstant('Lang.PleaseSelectOne')+this.$MyConstant('Lang.Purposeofexhibition'), trigger: ['change','blur'] },
          { validator: validate.validateSelect, trigger: 'blur' },

        ]
        ,  exhibition_aim_add: [

          { required: true, message: () => this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.other'), trigger: ['change','blur'] }

        ]
        ,channel: [

          { type: 'array', required: true, message: () => this.$MyConstant('Lang.PleaseSelectOne'), trigger: ['change','blur'] },
          { validator: validate.validateSelect, trigger: 'blur' },

        ]
        ,  channel_add: [

          { required: true, message: () => this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.other'), trigger: ['change','blur'] }

        ]
        ,cp_name: [
          { required: this.$MyConstant('sqlWhereLang')=='cn', message: () => this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.CCompanyName'),trigger: 'blur'},
          { validator: validate.validateCn, trigger: ['change','blur'] },
          { validator: validate.validateUser, trigger: 'blur' }
        ],cp_en_name: [
          { required: true, message: () => this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.ECompanyName'), trigger: 'blur'},
          { validator: validate.validateEng, trigger: ['change','blur'] },
        ],company_shortname: [
          { required: this.$MyConstant('sqlWhereLang')=='cn', message: () => this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.SCCompanyName'), trigger: 'blur'},
          { validator: validate.validateCn, trigger: ['change','blur'] },
        ],company_en_shortname: [
          { required: true, message: () => this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.SECompanyName'), trigger: 'blur'},
          { validator: validate.validateEng, trigger: ['change','blur'] },
        ],link_address: [
          { required: true, message: () => this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.addressInfo'), trigger: 'blur'},
          { validator: validate.validateCn, trigger: ['change','blur'] },
        ],link_en_address: [
          { required: true, message: () => this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.addressInfo'), trigger: 'blur'},
          { validator: validate.validateEng, trigger: ['change','blur'] },

        ]
        ,zipcode: [
          { required: true, message: () => this.$MyConstant('Lang.PleaseEnter'), trigger: 'blur'},
          { validator: validate.validateCn, trigger: ['change','blur'] }
        ]
        ,
        zuoji: [
          { required: true,validator: validatezuoji, trigger: 'blur'},
          { validator: validate.validateCn, trigger: ['change','blur'] },
        ],
        logo: [
          { required: true,message: this.$MyConstant('Lang.PleaseUpload')+'logo', trigger: ['change','blur']}
        ],fascia: [
          { required: this.$MyConstant('sqlWhereLang')=='cn', message: () => this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.fascia'), trigger: 'blur'},
          { validator: validate.validateCn, trigger: ['change','blur'] },
        ],fascia_en: [
          { required: true, message: () => this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.fascia_en'), trigger: 'blur'},
          { validator: validate.validateEng, trigger: ['change','blur'] }
        ],cp_profile: [
          { required: true, message: () => this.$MyConstant('Lang.PleaseEnter'), trigger: 'blur'},
          { validator: validate.validateCn, trigger: ['change','blur'] },

        ],cp_en_profile: [
          { required: true, message: () => this.$MyConstant('Lang.PleaseEnter'), trigger: 'blur'},
          { validator: validate.validLength.bind(250), trigger: ['change','blur']},
          { validator: validate.validateCn, trigger: ['change','blur'] },
        ],cp_bulletin_profile: [
          { required: true, message: () => this.$MyConstant('Lang.PleaseEnter'), trigger: 'blur'},
          { validator: validate.validateEng, trigger: ['change','blur'] },
        ],cp_en_bulletin_profile: [
          { required: true, message: () => this.$MyConstant('Lang.PleaseEnter'), trigger: 'blur'},
          { validator: validate.validLength.bind(500), trigger: ['change','blur']},
          { validator: validate.validateEng, trigger: ['change','blur'] },
        ],main_exhibit: [
          { required: true, message: () => this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.main_exhibit'), trigger: 'blur'}
        ],main_en_exhibit: [
          { required: true, message: () => this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.main_exhibit'), trigger: 'blur'},
          { validator: validate.validLength.bind(100), trigger: ['change','blur']},
          { validator: validate.validateEng, trigger: ['change','blur'] },
        ],selecteAddress: [
          { required: true, message: () => this.$MyConstant('Lang.PleaseSelect'), trigger: 'blur'},
        ],
        honor: [
          { validator: validateHonor,  trigger: ['change','blur']},
        ]

      }
    }
  },
 async created() {



  },
  mounted() {

  },
  methods: {
    async jiazaiArr(){

      if(this.$MyConstant('Api_CONSTANT.SizeConfig.site_edit_closed')==1){
        this.$message.error(this.$MyConstant('Lang.site_edit_closed'));
      }
      const response= await  api.ajaxbaseUrlget('/api/User/userInfo')
      this.ruleForm=response.data.obj.data
      //json解
      this.ruleForm.honor=JSON.parse(this.ruleForm.honor)?JSON.parse(this.ruleForm.honor):[{}];
       // console.log(this.ruleForm.honor)
      if(this.ruleForm.company_types=='经销代理'||this.ruleForm.company_types=='Distributor& Agents'){
        this.show_company_bank=true;
      }
      let company_funarr = this.ruleForm.company_fun.concat(this.$MyConstant('Lang.company_funsArr'));
      let company_funset = new Set(company_funarr);
      this.company_funs = Array.from(company_funset);



      let exhibition_aimarr = this.ruleForm.exhibition_aim.concat(this.$MyConstant('Lang.exhibition_aimsArr'));
      let exhibition_aimset = new Set(exhibition_aimarr);
      this.exhibition_aims = Array.from(exhibition_aimset);

      let channelarr = this.ruleForm.channel.concat(this.$MyConstant('Lang.channelsArr'));
      let channelset = new Set(channelarr);
      this.channels = Array.from(channelset);


      let  apiservice=this.$MyConstant('sqlWhereLang')=='cn'?this.$MyConstant('Api_CONSTANT.DimensionLists.company_service').map(function(item){ return item['service'];}):
          ['Smart City', 'Intelligent Transportation', 'Smart Justice', 'Smart Urban Management', 'Smart Community / Park / Building','Smart Governance', 'Smart Education', 'Smart Healthcare', 'Emergency Management', 'Digital Village', 'Others'];
      let service = this.ruleForm.service.concat(apiservice);
      let services = new Set(service);
      this.services = Array.from(services);

      let company_type=this.$MyConstant('sqlWhereLang')=='cn'?this.$MyConstant('Api_CONSTANT.DimensionLists.company_type').map(function(item){ return item['company_type'];}):this.$MyConstant('Lang.company_typesArr');

      if(!this.$empty(this.ruleForm.company_types)){
        company_type=[this.ruleForm.company_types].concat(company_type);
      }
      let company_types = new Set(company_type);
      this.company_types = Array.from(company_types);

      let product_type =this.ruleForm.product_type.concat(this.$MyConstant('Lang.product_typeArr'));
      let product_types = new Set(product_type);
      this.product_types = Array.from(product_types);

      this.ruleForm.selecteAddress = [
                                      this.ruleForm.country,
                                      this.ruleForm.province,
                                      this.ruleForm.city,
                                      this.ruleForm.area
                                     ].filter(Boolean);
      this.pcaTextArr= await api.ajaxbaseUrlData('api/public_view/getRegionsTree')
      this.pcaTextArr[0]['children']=pcaTextArr
      //开启自动保存
      //延迟1秒等程序完成再保存
      setTimeout(()=>{
        this.$refs['ruleForm'].clearValidate()
        this.autoPlaySave=true
      },2000)
      //延迟0.5秒等程序完成再清除
      setTimeout(()=>{
        this.$refs['ruleForm'].clearValidate()
      },100)

    }
    ,
    addHonor() {
      if (this.ruleForm.honor.length < this.honorYears.length*this.MY_CONSTANT.Lang.honorArr.length) {
        this.ruleForm.honor.push({});
        this.ruleForm.havehonor = 1;
      }
    },
    removeHonor(index) {

      if (this.ruleForm.honor.length > 1) {
      this.ruleForm.honor.splice(index, 1);
      }

    },
    haveHonor(e) {
      if (e.target.parentElement.classList.contains('is-checked')) {
        setTimeout(()=>{
          if (this.ruleForm.havehonor == 0) {
            this.ruleForm.havehonor = 1;
          }
        },100)
      }else{
        this.ruleForm.havehonor = 0;
        this.ruleForm.honor = [{}];
        setTimeout(()=>{
          this.$refs['ruleForm'].clearValidate('honor');
        },100)
      }
    },
    //封面设置
    uploadPicture(){
      this.cropperModel = true;
    },
    //图片上传成功后
    handleUploadSuccess (data){

      this.ruleForm.logo = data;

      this.cropperModel = false;
    },
    arrayDuibi(newValue,oldValue) {
      for(let key in newValue){
        if(newValue.hasOwnProperty(key)){

          // console.log(key)
          if(JSON.stringify(newValue[key])!=JSON.stringify(oldValue[key])){
            return key;

          }
        }
      }

    }
    ,
    haveWebSize(e){
      if (e.target.parentElement.classList.contains('is-checked')) {
        setTimeout(()=>{
          if (this.ruleForm.havewebsite == 0) {
            this.ruleForm.havewebsite = 1;
            this.ruleForm.website=this.websitehttp;
          }
        },100)
      }else{
        this.ruleForm.havewebsite = 0;
        this.ruleForm.website='';
        setTimeout(()=>{
          this.$refs['ruleForm'].clearValidate('website');
        },100)
      }
    },
    haveLogo(e){

      if (e.target.parentElement.classList.contains('is-checked')) {
        let that=this
        setTimeout(function (){  //得慢0.1秒改，不然改不掉....坑
          if (that.ruleForm.logo == that.$MyConstant('User.defaultLogo')) {
            that.ruleForm.logo = "";
          } else {
            that.ruleForm.logo = that.$MyConstant('User.defaultLogo');
          }
        },200)
      }


    },
    // strReplace(v,replacements='{}') {
    //延迟处理
     strReplace(v,replacements='{}') {
       setTimeout(() => {

      // 要进行替换的原始字符串
      var originalString = this.ruleForm[v];

      // 使用 replace() 函数进行多字符串替换
      for (var key in replacements) {
        if (replacements.hasOwnProperty(key)) {
          originalString = originalString.replace(new RegExp(key, "g"), replacements[key]);

        }
      }

      //判断英文第一个是否存在空格
      var jiancha=originalString.charAt(0);
      if(jiancha==' ') {
        originalString= originalString.replace(jiancha,'');
      }

      this.ruleForm[v]=originalString;

       }, 1000);

    }
    ,




    handleTextareaKeydown() {
      // const e = window.event || arguments[0]
      // if (e.key === 'Enter' || e.code === 'Enter' || e.keyCode === 13) {
      //     e.returnValue = false
      //     return false
      // }
    },
    removeUpload(file, fileList){

      this.ruleForm.logo='';
      let that=this
      setTimeout(function (){
        $('.logo').css('color','#999');
        $('.logo').html(that.$MyConstant('Lang.LogoUploadDesc')+that.$MyConstant('Upload.logo.size')+'M,'+that.$MyConstant('Upload.logo.width')+'*'+that.$MyConstant('Upload.logo.height'));
      },5000)
    },
    imgUploadSuccess(res, file) {
      console.log(res);
      // this.imageUrl = URL.createObjectURL(file.raw);
      this.imageUrl = res.obj.data.url;
      this.ruleForm.logo=res.obj.data.url;
      $('.logo').css('color','green');
      $('.logo').html(this.$MyConstant('Lang.uploadFile')+' '+this.$MyConstant('Lang.success'));
      $('.uploadText').html(this.$MyConstant('Lang.LogoUploadDesc')+this.$MyConstant('Upload.logo.size')+'M,'+this.$MyConstant('Upload.logo.width')+'*'+this.$MyConstant('Upload.logo.height'));

    },
    beforeAvatarUpload(file) {
      console.log(file.type);

      const isJPGorPNG = file.type === 'image/jpg'||file.type === 'image/png'||file.type === 'image/jpeg';



      if (!isJPGorPNG) {
        $('.logo').css('color','#F56C6C');
        $('.uploadText').html(this.$MyConstant('Lang.reupload'));

        this.$message.error(this.$MyConstant('Lang.UploadRestriction'));return false;
      }


      const isLt5M = file.size / 1024 / 1024 < this.$MyConstant('Upload.logo.size');
      if (!isLt5M) {
        $('.logo').css('color','#F56C6C');
        this.$message.error(this.$MyConstant('Lang.UploadSiteDesc')+this.$MyConstant('Upload.logo.size')+'M!');
        $('.uploadText').html(this.$MyConstant('Lang.reupload'));return false;
      }


      return this.checkImageWH(file, this.$MyConstant('Upload.logo.width'), this.$MyConstant('Upload.logo.height'));
      // return  isLt5M;
    },

    // 配置图片像素
    checkImageWH(file, width, height) {
      let that = this;
      return new Promise(function (resolve, reject) {
        let filereader = new FileReader();
        filereader.onload = (e) => {
          let src = e.target.result;
          const image = new Image();
          image.onload = function () {

            if ( this.width != width || this.height != height) {
              $('.logo').css('color','#F56C6C');
              that.$message.warning(
                  that.$MyConstant('Lang.LogoUploadDesc')+that.$MyConstant('Upload.logo.size')+'M,'+that.$MyConstant('Upload.logo.width')+'*'+that.$MyConstant('Upload.logo.height')
              );
              $('.uploadText').html(this.$MyConstant('Lang.reupload'));
              reject(); return false;
            }


            resolve();

          };
          image.onerror = reject;
          image.src = src;
        };
        filereader.readAsDataURL(file);
      });
    },
    handleRolewebsite(value){

      let replacew=this.ruleForm.website.replace('http://','');
      // console.log(replacew.replace('https://',''));
      this.ruleForm.website=value+replacew.replace('https://','');

    },
    handleRolecompany_fun(value,e)
    {
      if(e.srcElement._value=='其他'||e.srcElement._value=='Others'){
        if(value){
          this.company_funs_add=true;
        }else{
          this.company_funs_add=false;
        }
      }


    },
    handleRoleservice(value,e)
    {
      if(e.srcElement._value=='其他'||e.srcElement._value=='Others'){
        if(value){
          this.services_add=true;
        }else{
          this.services_add=false;
        }
      }


    },
    handleRoleproduct_type(value,e)
    {
      if(e.srcElement._value=='其他'||e.srcElement._value=='Others'){
        if(value){
          this.product_types_add=true;
        }else{
          this.product_types_add=false;
        }
      }

    },
    handleRoleexhibition_aim(value,e)
    {
      if(e.srcElement._value=='其他'||e.srcElement._value=='Others'){
        if(value){
          this.exhibition_aims_add=true;
        }else{
          this.exhibition_aims_add=false;
        }
      }

    },
    handleRolechannel(value,e)
    {
      if(e.srcElement._value=='其他'||e.srcElement._value=='Others'){
        if(value){
          this.channels_add=true;
        }else{
          this.channels_add=false;
        }
      }

    },
    handleRolecompany_types(value,e)
    {

      if(value=='经销代理'||value=='Distributor& Agents'){
        this.show_company_bank=true;
      }else{
        this.show_company_bank=false;
        this.ruleForm.company_bank='';
      }


      if(value=='其他'||value=='Others'){
        this.show_company_type=true;

      }else{
        this.show_company_type=false;
        this.ruleForm.company_type_qita='';
      }



    },
   async submitForm(formName) {

    await  this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.ruleForm);

          this.ruleForm.submitted=1;
          api.ajaxbaseUrl.post('/api/User/userEdit',this.ruleForm,{headers:{'Accept-Language':this.MY_CONSTANT.setApiLang}})
              .then(response => {
                if(response.data.err>0){
                  this.$message.error({message:'提交失败：'+response.data.msg,offset:400,duration:3000});
                }else{
                  this.$message.success({message:response.data.msg,offset:400,duration:3000});
                }

              });


        } else {

          this.$nextTick(() => {
            // 获取错误节点
            let isError = this.$refs[formName].$el.getElementsByClassName('is-error')
            isError[0].scrollIntoView({
              // 滚动到指定节点
              // 值有start,center,end，nearest，当前显示在视图区域中间
              block: 'center',
              // 值有auto、instant,smooth，缓动动画（当前是慢速的）
              behavior: 'smooth',
            })
          })

          console.log('error submit!!');
          return false;
        }
      });

    },
    resetForm(formName) {
      this.$refs[formName].resetFields();

    },

   //自动保存
    autosave(newValue,oldValue){

      //找出有变化的key
      let key=  this.arrayDuibi(newValue,oldValue);

      //修改过保存设置10秒后保存
      let timestamp = Date.now();
      let cachedData = localStorage.getItem('savetimestamp');
      if (!cachedData) {
        localStorage.setItem('savetimestamp', timestamp);return;
      }

      if ((timestamp-cachedData)/1000>10) {
        this.$refs['ruleForm'].validateField(key,(valid) => {
          console.log(key);
          console.log(valid);
          //无报错直接保存缓存
          if (valid) {

              api.ajaxbaseUrl.post('/api/User/userEdit',this.ruleForm,{headers:{'Accept-Language':this.MY_CONSTANT.setApiLang}})

            localStorage.setItem('savetimestamp', timestamp);

          }
        })

      }


    }

  },
  computed:{
    honorYears() {
      const currentYear = new Date().getFullYear();
      const years = [];
      for (let year = 2020; year <= currentYear; year++) {
        years.push(year);
      }
      return years;
    },
    honorsRules() {
      return [
        {required: this.ruleForm.havehonor,  message: () => this.$MyConstant('Lang.PleaseSelect'), trigger: 'blur'},
      ]
    },
    websiteRules() {
      return [
        {required: this.ruleForm.havewebsite, message: () => this.$MyConstant('Lang.PleaseEnter'), trigger: 'blur'},
        { validator: (rule, value, callback)=>{
            //如果存在特殊符号则返回错误
            if (/[~`!#$%^&*+=\-\[\]\\';,{}|\\"<>\?]/.test(value)) {
              return callback(new Error(this.$MyConstant('Lang.noSpecialSymbol')));
            }
            return callback();
          }, trigger:  ['change','blur']},
        {pattern: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/[\]@!\$&'\*\+,;=.]+$/, message: () => this.$MyConstant('Lang.PleaseCorrect')+this.$MyConstant('Lang.site'), trigger: 'blur'},
      ]
    },

    pullStreamListWatch: function () {
      // 其实程序做到这一步就可以监听到pullStreamList数据的变化了，再用JSON.parse做数据还原方便后边数据处理。
      //return JSON.stringify(this.pullStreamList);
      return JSON.parse(JSON.stringify(this.ruleForm));
    }
  }
  ,
  watch:
      {
        pullStreamListWatch: {
          handler(newValue, oldValue) {

          if(this.autoPlaySave&&this.$MyConstant('Api_CONSTANT.SizeConfig.site_edit_closed')==0) this.autosave(newValue, oldValue)
      },
      deep: true
    },
        'MY_CONSTANT.sqlWhereLang': {
          immediate: true, // 初始化时立即执行
          handler() {
            //切换语言时候确定哪个必填
            this.rules.cp_name[0].required=this.$MyConstant('sqlWhereLang')=='cn'
            this.rules.company_shortname[0].required=this.$MyConstant('sqlWhereLang')=='cn'
            this.rules.fascia[0].required=this.$MyConstant('sqlWhereLang')=='cn'
            this.rules.cp_profile[0].required=this.$MyConstant('sqlWhereLang')=='cn'
            this.rules.cp_en_profile[0].required=this.$MyConstant('sqlWhereLang')=='cn'
            this.rules.main_exhibit[0].required=this.$MyConstant('sqlWhereLang')=='cn'
            // 当路由参数变化时，重新请求接口并更新内容
            this.jiazaiArr();
          }
        }
  },


};
</script>
<style scoped>
/deep/.error-shadow .el-select__wrapper{
  box-shadow: 0 0 0 1px #ff4949 inset;
}
/deep/.success-shadow .el-select__wrapper{
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.14) inset;
}
</style>
