<template>
  <Components :datas= '{
        "Template/Header": "",
      }'>
  </Components>
  <img style="width: 100%;height: 112px;" src="../../../assets/img/sch.png">
  <div class="middle">
    <div class="Pagetitle">
      {{ MY_CONSTANT.Api_CONSTANT.menu.PublicMenu[this.$route.params.menu]['name_'+MY_CONSTANT.sqlWhereLang]}}<el-icon><DArrowRight /></el-icon>
      <span v-for="(item,key) in menu"><span  v-if="this.$route.params.cat == key">{{ item['name_'+MY_CONSTANT.sqlWhereLang] }}</span></span>
    </div>

    <div class="HtmlContent"   ref="imageContainer" v-html="MY_CONSTANT.sqlWhereLang=='cn'?content:en_content"></div>
</div>


  <Components :datas='{
        "Template/Footer": "",
      }'>
  </Components>

</template>

<script>
import Components from '../../../modules/Components.vue'
import api from "@/assets/js/api";
export default {
  components: {
    Components
  },
  data() {
    return {
       content:'',
       en_content:'',
       menu:null,
       viewer: null, // 用于存储 Viewer 实例
    }
    },
  methods: {
    async fetchData() {
      const response = await    api.ajaxbaseUrlget('/api/page/GetPage',this.$route.params);

// 使用正则表达式匹配并修改所有 <img> 元素的 src 属性
      const modifiedContent = response.data.obj.data.content.replace(/<img[^>]+src=['"](?!http)[^'"]+['"]/g, (match) => {
        const src = match.match(/src=['"]([^'"]+)['"]/)[1];
        const domain = this.$MyConstant('StaticPath'); // 替换为您的域名
        return match.replace(src, domain + src);
      });

      this.content = modifiedContent;

      // 使用正则表达式匹配并修改所有 <img> 元素的 src 属性
      const modifiedContenten = response.data.obj.data.en_content.replace(/<img[^>]+src=['"](?!http)[^'"]+['"]/g, (match) => {
        const src = match.match(/src=['"]([^'"]+)['"]/)[1];
        const domain = this.$MyConstant('StaticPath'); // 替换为您的域名
        return match.replace(src, domain + src);
      });

      this.en_content = modifiedContenten;

      this.$nextTick(() => {
        //存在销毁才生效
        if (this.viewer) {
          this.viewer.destroy();
          this.viewer = null;
        }
        this.viewer = new this.$viewer(this.$refs.imageContainer);
      });

    }
  },
  watch: {
    '$route': {
      immediate: true, // 初始化时立即执行
      handler() {
        this.menu=this.$MyConstant('Api_CONSTANT.menu.PublicMenu.'+this.$route.params.menu+'.list')
        // 当路由参数变化时，重新请求接口并更新内容
        this.fetchData();
      }
    }
  },
};
</script>

