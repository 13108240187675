<template>
  <Components :datas='{
        "Template/Header": "",
      }'>
  </Components>
  <div class="middle">
  </div>

  <Components :datas='{
        "Template/Footer": "",
      }'>
  </Components>

</template>

<script>
import Components from '../../../modules/Components.vue'
import api from "@/assets/js/api";

export default {
  components: {
    Components
  },
  data() {
    return {}
  },
  async created() {

  },
  methods: {}
};
</script>
<style scoped>

</style>
