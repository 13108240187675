<template>
  <div className="banner">
    <div className="swiper-container swiper-container1">
      <div className="swiper-wrapper">

        <div className="swiper-slide">
          <img src="../../../assets/img/sch.png">
        </div>
      </div>
    </div>
  </div>

  <div className="about">
    <div className="about-top">
      <p>{{ this.$MyConstant('Api_CONSTANT.menu.PublicMenu.visitor.name_'+MY_CONSTANT.sqlWhereLang) }}</p>
      <img src="../../../assets/img/icon/jiantou.png">
      <em>{{ this.$MyConstant('Api_CONSTANT.menu.PublicMenu.visitor.list.floorplan.name_'+MY_CONSTANT.sqlWhereLang) }}</em>

    </div>
    <div className="about-bottom middle">

      <!-- 左边 -->
      <div class="serve_map-left">
        <ul id="nav">


          <li :class="{'act':item.id==(this.$route.query.id||68)}" v-for="(item,key) in floorplanItems" >
            <custom-link  :style="{ color: item.id == (this.$route.query.id||68) ? '#fff' : '#8B8B8B' }" :to="'/Floorplan?id='+item.id">{{ item.img_name }}</custom-link>
          </li>

        </ul>
        <div id="container">

          <div class="tab">
            <div class="tab1">

                <el-image

                    :src="floorplanImg"
                    :preview-src-list="[floorplanImg]"
                    :initial-index="0"
                    lazy
                />

            </div>


          </div>


        </div>


      </div>


      <!-- 右边 -->
      <div className="about-right">
        <div className="about-right1" v-for="(item,key) in menu">
          <custom-link :style="{'color': this.$route.path == '/'+MY_CONSTANT.setApiLang+item.url ? 'var(--uavdeep-color)' : ''}" :to="item.url">
            <img src="../../../assets/img/icon/sanjiao.png">
            <em>{{ item['name_'+MY_CONSTANT.sqlWhereLang] }}</em>
          </custom-link>
        </div>

        <Components :datas= '{
        "Template/About-right": "",
         }'>
        </Components>
      </div>

    </div>

  </div>

</template>

<script>
import Components from '../../../modules/Components.vue'
import api from "@/assets/js/api";

export default {
  components: {
    Components
  },
  data() {
    return {
      content: '',
      menu: this.$MyConstant('Api_CONSTANT.menu.PublicMenu.visitor.list'),
      floorplanItems:[],
      floorplanImg:'',
    }
  },
  async created() {
  console.log(this.$route)
  },
  methods: {
    async fetchData() {
      const response = await api.ajaxbaseUrlget('/api/Floorplan/GetFloorplan');

      let pageItem=[],page=0;

      // if(!this.$route.query.id){
      //   this.floorplanImg= this.$MyConstant('StaticPath')+response.data.obj.data[0].pic
      // }

      response.data.obj.data.map((item, index) => {

        if (item.cat_id== 1&&item.lang==this.$MyConstant('sqlWhereLang')) {
          pageItem[page]=item;
          //图片
          if(this.$route.query.id&&this.$route.query.id==item.id){
            this.floorplanImg=item.pic.includes('http')?item.pic:this.$MyConstant('StaticPath')+item.pic
          }
          page++
        }

      });

      // console.log( this.$route.query.id)




      this.floorplanItems = pageItem;
    }
  },
  watch: {
    '$route': {
      immediate: true, // 初始化时立即执行
      handler() {
        // 当路由参数变化时，重新请求接口并更新内容
        this.fetchData();
      }
    },
    'MY_CONSTANT.sqlWhereLang': {
      handler() {
        // 当路由参数变化时，重新请求接口并更新内容
        this.fetchData();
      }
    }
  },
};
</script>

<style scoped>

/* 1.观众登记 */

/* 2展位图 serve_map*/
.serve_map-bottom {
  display: flex;
  justify-content: space-between;
  margin-bottom: 134px;
}

.serve_map-left {
  width: 75%;
  margin-top: 47px;

}
.serve_map-left ul {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 24px;

}

.serve_map-left ul li {
  width: 10%;
  text-align: center;
  padding: 14px 0 11px;
  background-color: #F5F5F5;
  margin-right: 8px;
  margin-bottom: 6px;

  font-size: 13px;

  font-weight: 500;

  color: #8B8B8B;
  line-height: 35px;

}

.act {
  color: #fff !important;
  background-color:var(--uavmedium-color) !important;
}

.serve_map-left .tab .tab1 {
  display: flex;
  /* justify-content: space-between; */
  flex-wrap: wrap;
}

.serve_map-left .tab .tab1::after {
  content: "";
  display: block;
  width: 209px;
  height: 118px;
  visibility: hidden;
}

.serve_map-left .tab .tab1 div {
  /* width: 209px;
  height: 118px;
  margin-bottom: 10px;
  margin-right: 10px; */
}

.serve_map-left .tab .tab1 img {
  width: 100%;
}

</style>
