<template>
  <div class="banner">
    <div class="swiper-container swiper-container1">
      <div class="swiper-wrapper">

        <div class="swiper-slide">
          <img src="../../../assets/img/sch.png">
        </div>
      </div>
    </div>
  </div>

  <div class="about">
    <div class="about-top">
      <p>{{ this.$MyConstant('Api_CONSTANT.menu.Usermenu.exhibition.name_'+MY_CONSTANT.sqlWhereLang) }}</p>
      <img src="../../../assets/img/icon/jiantou.png">
      <em>{{ this.$MyConstant('Api_CONSTANT.menu.Usermenu.exhibition.list.yudengji.name_'+MY_CONSTANT.sqlWhereLang) }}</em>

    </div>
    <div class="contentMiddle">
      <!-- 左边 -->
      <div class="contentLeft">

        <div class="chanage_login-left1">
          <h2>{{ this.$MyConstant('Lang.approvedbooth') }}</h2>
          <table border="0" cellspacing="" cellpadding="">
            <tbody>
            <tr>
              <th>{{ this.$MyConstant('Lang.hall') }}</th>
              <th>{{ this.$MyConstant('Lang.BoothNumber') }}</th>
              <th>{{ this.$MyConstant('Lang.year') }}</th>
            </tr>

            <tr v-for="item in List">
              <th>{{ item.hall }}{{ this.$MyConstant('Lang.hallNum') }}</th>
              <th>{{ item.exhibition }}</th>
              <th>{{ item.year }}</th>
              <!--  <th>状态</th> -->
            </tr>

            </tbody>

          </table>

        </div>


      </div>


      <!-- 右边 -->
      <div class="about-right">
        <div class="about-right1" v-for="(item,key) in this.$MyConstant('Api_CONSTANT.menu.Usermenu.exhibition.list')">
          <custom-link :style="{'color': this.$route.fullPath == '/'+MY_CONSTANT.setApiLang+item.url ? 'var(--uavdeep-color)' : ''}" :to="item.url">
            <img src="../../../assets/img/icon/sanjiao.png">
            <em>{{ item['name_'+MY_CONSTANT.sqlWhereLang] }}</em>
          </custom-link>
        </div>

        <Components :datas= '{
        "Template/About-right": "",
         }'>
        </Components>

      </div>

    </div>

  </div>

</template>

<script>
import Components from '../../../modules/Components.vue'
import api, {ajaxbaseUrlData} from "@/assets/js/api";
export default {
  components: {
    Components
  },
  data() {
    return {
      List:[],
    }
  },
  async  created() {
    this.List= await api.ajaxbaseUrlData('/api/User/UserExhibition')
  },
  methods: {

  }
};
</script>
<style scoped>
.chanage_login-left1  h2{
  font-size: 21px;

  text-align: justifyLeft;
  color: #000000;

  margin: 54px 0;

}
.chanage_login-left1  h2:first-child{

}
.chanage_login-left1  table{
  width: 100%;
}
.chanage_login-left1  table tr{
  font-size: 13px;
  color: #8b8b8b;

}
.chanage_login-left1  table tr:first-child{
  background-color: var(--uavdeep-color)!important;
  font-size: 13px;
  color: #fff;

}

.chanage_login-left1 table tr:nth-child(odd){
  background: #F5F5F5;

}

.chanage_login-left1  table th{
  width: 200px;
  text-align: center;
  padding: 12px 0;
  border: 2px solid #fff;
}
.chanage_login-left1  table td{
  text-align: center;
  padding: 12px 0;
  border: 2px solid #fff;

}

</style>
