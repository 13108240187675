<template>
  <Components :datas= '{
        "Template/Swiper/Demo1": {filter: "{\"adcate_id\":\"3\"}", limit: 3},

      }'>
  </Components>

  <div class="banner-bootom">
    <div class="middle">

      <custom-link :to="this.$MyConstant('Api_CONSTANT.menu.PublicMenu.visitor.list.login.url')">
        <!-- <a href="javascript:void(0)"> -->
        <p>{{ this.$MyConstant('Api_CONSTANT.menu.PublicMenu.visitor.list.login.name_'+MY_CONSTANT.sqlWhereLang) }}</p>
      </custom-link>
      <custom-link :to="MY_CONSTANT.Api_CONSTANT.menu.PublicMenu.exhibitor_info.list.reserve.url">
        <p>{{ this.$MyConstant('Api_CONSTANT.menu.Usermenu.exhibition.list.reserve.name_'+MY_CONSTANT.sqlWhereLang) }}</p>
      </custom-link>
    </div>
  </div>


  <div class="nav">
    <div class="middle">

      <ul>
        <li>
          <img src="../../../assets/img/shouye/nav1.png">
          <h3 class="numberVFX">{{ this.$MyConstant('Api_CONSTANT.SizeConfig.exhibition_space') }}</h3>
          <h4 v-if="this.$MyConstant('sqlWhereLang')=='cn'">展览空间</h4>
          <p>Exhibition area</p>
        </li>
        <li>
          <img src="../../../assets/img/shouye/nav2.png">
          <h3 class="numberVFX">{{ this.$MyConstant('Api_CONSTANT.SizeConfig.exhibitorss') }}</h3>
          <h4 v-if="this.$MyConstant('sqlWhereLang')=='cn'">参展展商</h4>
          <p>Exhibitors</p>
        </li>
        <li>
          <img src="../../../assets/img/shouye/nav3.png">
          <h3 class="numberVFX">{{ this.$MyConstant('Api_CONSTANT.SizeConfig.professional_audience') }}</h3>
          <h4 v-if="this.$MyConstant('sqlWhereLang')=='cn'">专业观众</h4>
          <p>Professional audiences</p>
        </li>
        <li>
          <img src="../../../assets/img/shouye/nav4.png">
          <h3 class="numberVFX">{{ this.$MyConstant('Api_CONSTANT.SizeConfig.booth') }}</h3>
          <h4 v-if="this.$MyConstant('sqlWhereLang')=='cn'">展位</h4>
          <p>Booths</p>
        </li>
      </ul>

    </div>

    <div style="text-align: center;margin-top: -40px;color: grey;">{{this.$MyConstant('Lang.dataSource')}}：{{this.$MyConstant('Api_CONSTANT.SizeConfig.index_data_source_'+this.$MyConstant('sqlWhereLang'))}}</div>

  </div>

  <div class="cont-bar">

    <div class="cont-bar-top">
      <div class="middle">
        <em>
          <custom-link  :to="this.$MyConstant('Api_CONSTANT.SizeConfig.important_nocite_url_'+this.$MyConstant('sqlWhereLang'))">
            <img src="https://51kufei.oss-cn-shenzhen.aliyuncs.com/upload/20211023/2a8f8276390275da0641c4ec3cde2e16.gif" class="img1">
            <p class="blink">{{this.$MyConstant('Api_CONSTANT.SizeConfig.important_nocite_'+this.$MyConstant('sqlWhereLang'))}}</p>
          </custom-link>
        </em>
        <span class="line"></span>
      </div>

    </div>


    <div class="cont-bar-bootom">
      <div class="middle">
        <ul>
          <li>
            <custom-link :to="this.$MyConstant('Api_CONSTANT.menu.PublicMenu.visitor.list.login.url')">
              <img src="../../../assets/img/shouye/cont1.png">
              <span>{{ this.$MyConstant('Api_CONSTANT.menu.PublicMenu.visitor.list.login.name_'+MY_CONSTANT.sqlWhereLang) }}</span>
            </custom-link>

          </li>
          <li>
            <custom-link :to="MY_CONSTANT.Api_CONSTANT.menu.PublicMenu.exhibitor_info.list.login.url">
              <img src="../../../assets/img/shouye/cont2.png">
              <span>{{ this.$MyConstant('Api_CONSTANT.menu.PublicMenu.exhibitor_info.list.login.name_'+MY_CONSTANT.sqlWhereLang) }}</span>
            </custom-link>
          </li>
          <li>
            <custom-link :to="this.$MyConstant('Api_CONSTANT.menu.PublicMenu.visitor.list.floorplan.url')">
              <img src="../../../assets/img/shouye/cont3.png">
              <span>{{ this.$MyConstant('Api_CONSTANT.menu.PublicMenu.visitor.list.floorplan.name_'+MY_CONSTANT.sqlWhereLang) }}</span>
            </custom-link>
          </li>
          <li>
            <custom-link to="http://jl.miceclouds.com/bookingquery.htm?id=1750" target="_blank">
              <img src="../../../assets/img/shouye/cont4.png">
              <span>{{this.$MyConstant('Lang.jiudianfuwu')}}</span>
            </custom-link>
          </li>
          <li>
            <a href="javascript:void(0)">
              <img src="../../../assets/img/shouye/cont5.png">


              <span id="demo" v-if="countdown > 0">
                  {{this.$MyConstant('Lang.daojishi')}} {{ days }} {{this.$MyConstant('Lang.day')}} {{ hours }} {{this.$MyConstant('Lang.hours')}} {{ minutes }} {{this.$MyConstant('Lang.minutes')}} {{ seconds }} {{this.$MyConstant('Lang.seconds')}}
                </span>
              <span id="demo" v-else-if="countdown <= 0 && countdown > -4 * 24 * 60 * 60 * 1000">
                  展会进行中
                </span>
              <span id="demo" v-else>
                  已结束
                </span>
            </a>
          </li>
        </ul>
      </div>

    </div>
  </div>

  <div class="about">
    <div class="hd">
      <div class="middle">

        <img :src="require(`@/assets/img/shouye/h1_`+this.$MyConstant('sqlWhereLang')+`.png`)">


      </div>
    </div>
    <div class="middle">
      <div class="about1">
        <div class="about-left">
          <img src="../../../assets/img/shouye/t2.png">
        </div>
        <div class="about-right-hd">
          <!-- <img src="../../../assets/img/shouye/t1.png" > -->
          <div class="about-right-zi">
            <h2>EXHIBITION <br>
              BASIC INFORMATION
            </h2>
            <em  v-if="this.$MyConstant('sqlWhereLang')=='cn'">展会基本信息</em><br>
            <div class="about-1">

              <p>{{this.$MyConstant('Lang.Exhibitiondate')}}：{{this.$ExhibitionDate()}}（{{this.$MyConstant('Lang.AnnualTerm')}}）</p>

              <p>{{this.$MyConstant('Lang.ExhibitionAddress')}}：{{this.$MyConstant('Api_CONSTANT.SizeConfig.exhibition_address_info_'+this.$MyConstant('sqlWhereLang')) }}</p>
              <p>{{this.$MyConstant('Lang.ExhibitionArea')}}：{{ this.$MyConstant('Api_CONSTANT.SizeConfig.exhibition_space') }}㎡</p>
              <p>{{this.$MyConstant('Lang.Exhibitors')}}：{{MY_CONSTANT.Api_CONSTANT.SizeConfig.exhibitorss }}</p>


            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

  <div class="history-video">
    <div class="hd">
      <div class="middle">

        <img :src="require(`@/assets/img/shouye/h2_`+this.$MyConstant('sqlWhereLang')+`.png`)">


      </div>
    </div>

    <div class="shipin">
      <div v-html="this.$MyConstant('Api_CONSTANT.SizeConfig.index_video_html_'+this.$MyConstant('sqlWhereLang'))" class="middle" >

      </div>
<!--      <video width="100%" height="600" controls="controls" poster="template/default/cn/img/video.jpg">-->
<!--        <source src="https://51kufei.oss-cn-shenzhen.aliyuncs.com/upload/chinadrone/2023.m4v" type="video/mp4">-->
<!--      </video>-->
<!--      <iframe style="width: 100%;height: 600px;" src="//player.bilibili.com/player.html?aid=664741062&bvid=BV1La4y1R7cU&cid=1366768902&p=1&autoplay=0"> </iframe>-->
    </div>




  </div>

  <div class="news">
    <div class="hd">
      <div class="middle">

        <img :src="require(`@/assets/img/shouye/h3_`+this.$MyConstant('sqlWhereLang')+`.png`)">
      </div>
    </div>
    <div class="middle">


      <ul>
        <li  v-for="s in newsItems">
          <custom-link  :to="'/NewsInfo/' +s.article_id" >
            <img :src="this.$MyConstant('StaticPath')+s.pic">
            <div class="news-list">
              <h2>{{ s.title }}</h2>
            </div>
          </custom-link>

        </li>


      </ul>
    </div>


  </div>


  <div class="sch">
    <div class="middle">

      <div class="sch-left" v-html="this.$MyConstant('Api_CONSTANT.SizeConfig.index_center_'+this.$MyConstant('sqlWhereLang'))">

      </div>
      <custom-link :to="MY_CONSTANT.Api_CONSTANT.menu.PublicMenu.exhibitor_info.list.login.url" class="sch-right">
        <em>{{this.$MyConstant('Lang.clickToExhibit')}} </em>
      </custom-link>


    </div>
  </div>


  <div class="exhibitor">
    <div class="hd">
      <div class="middle">

        <img :src="require(`@/assets/img/shouye/h5_`+this.$MyConstant('sqlWhereLang')+`.png`)">
      </div>
    </div>



    <Components :datas= '{
        "Template/Swiper/Demo2": {limit: "all",year:selectYear},

      }'>
    </Components>

  </div>


  <div class="parter">
    <div class="hd">
      <div class="middle">

        <img :src="require(`@/assets/img/shouye/h4_`+this.$MyConstant('sqlWhereLang')+`.png`)">
      </div>
    </div>
    <div class="swiper-container swiper-container3 swiper-container-horizontal">
      <div class="swiper-wrapper" style="transform: translate3d(0px, 0px, 0px);">

        <div class="spon" style="width: 100%;display: flex;justify-content: center;margin-top: 60px;margin-bottom: 60px;">

          <ul class="spon-ul">
            <li>
              <div>

                <a target="_blank" href="http://www.szspia.org/dist/#/">
                  <img src="https://51kufei.oss-cn-shenzhen.aliyuncs.com/upload/20210903/e96a5c91754f5ba225d3ef935eaf604f.png">
                </a>
              </div>

            </li>
            <li>
              <div>

                <a target="_blank" href="http://www.szuavia.org/">
                  <img src="https://51kufei.oss-cn-shenzhen.aliyuncs.com/upload/20210903/6db8105502cdbbc3916af990c362d2d3.png">
                </a>
              </div>

            </li>
            <li>
              <div>

                <a target="_blank" href="http://www.szitsa.org/dist/#/">
                  <img src="https://51kufei.oss-cn-shenzhen.aliyuncs.com/upload/20210903/1dd12788506e3d6d0213c0517d28414b.png">
                </a>
              </div>

            </li>
            <li>
              <div>

                <a target="_blank" href="http://www.swcia.org/">
                  <img src="https://51kufei.oss-cn-shenzhen.aliyuncs.com/upload/20210903/f2dc6654269f027c0bbff2cf535ad973.png">
                </a>
              </div>

            </li>

            <li>
              <div>

                <a target="_blank" href="http://www.cpsmedia.com.cn/">
                  <img src="http://www.cpsmedia.com.cn/nav/images/scope1.jpeg">
                </a>
              </div>

            </li>
            <li>
              <div>

                <a target="_blank" href="http://www.cps.com.cn/">
                  <img src="http://www.cpsmedia.com.cn/nav/images/scope2.jpeg">
                </a>
              </div>

            </li>
            <li>
              <div>

                <a target="_blank" title="警装网" href="http://cpspew.com">
                  <img src="https://51kufei.oss-cn-shenzhen.aliyuncs.com/upload/20210903/0aeb2e11f5e3e25c46e7c9dd2fb13086.png">
                </a>
              </div>

            </li>
            <li>
              <div>

                <a target="_blank" href="http://spe.cps.com.cn/ugv/index.html">
                  <img src="https://51kufei.oss-cn-shenzhen.aliyuncs.com/upload/20230130/9d7230410503eca4505cc5e4c9784ad4.png?t=001">
                </a>
              </div>

            </li>
            <li>
              <div>

                <a target="_blank" href="http://www.cn-its.com.cn/">
                  <img src="https://51kufei.oss-cn-shenzhen.aliyuncs.com/upload/20230130/02f4aa03763588fb75b0945281ea85be.png">
                </a>
              </div>

            </li>
            <li>
              <div>

                <a target="_blank" href="http://its-expo.com/">
                  <img src="https://51kufei.oss-cn-shenzhen.aliyuncs.com/upload/20210903/0dcb09dc2e588e64846a3d099a44acd8.png">
                </a>
              </div>

            </li>
            <li>
              <div>

                <a target="_blank" href="http://china-drone.com.cn/">
                  <img src="	http://www.cpsmedia.com.cn/nav/images/scope7.png">
                </a>
              </div>

            </li>
            <li>
              <div>

                <a target="_blank" href="http://droneworldcongress.com/">
                  <img src="http://www.cpsmedia.com.cn/nav/images/scope8.png">
                </a>
              </div>

            </li>
            <li>
              <div>

                <a target="_blank" href="http://gsialliance.net/contact.html">
                  <img src="	http://cpse.com/images/logo/aflm.png">
                </a>
              </div>

            </li>
            <li>
              <div>

                <a target="_blank" href="http://www.cpsforum.com.cn/">
                  <img src="https://51kufei.oss-cn-shenzhen.aliyuncs.com/upload/20210903/ca03201117727ad081b0d4338a884de1.png">
                </a>
              </div>

            </li>
            <li>
              <div>

                <a target="_blank" href="http://www.iuvs.cn">
                  <img src="http://cpsmedia.oss-cn-shenzhen.aliyuncs.com//cps/cps_upload_images/20230511/1683795395250055.jpg">
                </a>
              </div>

            </li>


          </ul>
        </div>

      </div>
      <!-- <div class="swiper-pagination3 bb3"></div> -->
      <!-- 分页器。如果放置在swiper-container外面，需要自定义样式。 -->
      <span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span></div>

  </div>
</template>
<script>
import Components from '../../../modules/Components.vue'
import api from "@/assets/js/api";


export default {
  components: {
    Components
  },
  computed: {
    days() {
      return Math.floor(this.countdown / (24 * 60 * 60 * 1000));
    },
    hours() {
      return Math.floor((this.countdown % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));
    },
    minutes() {
      return Math.floor((this.countdown % (60 * 60 * 1000)) / (60 * 1000));
    }
  },

  data() {
    return {
      countdown: 0,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      newsItems:[],
      selectYear:null,
      currentYear:null,
      currentMonth:null,
      currentDay:null,
      currentEndDay:null,
    }
  },

watch:
{

  'MY_CONSTANT.sqlWhereLang': {
  immediate: true, // 初始化时立即执行
    async handler() {

   const response = await    api.getNewsData(this.$getApiWhere({limit:3,filter:JSON.stringify({lang:this.$MyConstant('sqlWhereLang')}),order: '{"sort":"desc","add_time":"desc"}'}));
   this.newsItems = response.data.obj.data;

  }
}
},

async created() {
   const date = new Date(this.$MyConstant('Api_CONSTANT.SizeConfig.ini_cpse_date').split(" ")[0]);
   const enddate =new Date(this.$MyConstant('Api_CONSTANT.SizeConfig.ini_cpse_enddate').split(" ")[0]);
   this.currentYear = date.getFullYear();
   this.currentMonth= date.getMonth() + 1;
   this.currentDay= date.getDate();
   this.currentEndDay= enddate.getDate();
   //当前年
   this.selectYear= (new Date()).getFullYear()

   const endTime = new Date(this.$MyConstant('Api_CONSTANT.SizeConfig.ini_cpse_date').split(" ")[0]).getTime(); // 替换成你的展会结束时间
   this.updateCountdown(endTime);
   setInterval(() => {
     this.updateCountdown(endTime);
   }, 1000);


   const response = await    api.getNewsData(this.$getApiWhere({limit:3,filter:JSON.stringify({lang:this.$MyConstant('sqlWhereLang')}),order: '{"sort":"desc","add_time":"desc"}'}));

   this.newsItems = response.data.obj.data;
  },
  methods: {
    updateCountdown(endTime) {
      const now = new Date().getTime();
      this.countdown = endTime - now;

      if (this.countdown > 0) {
        this.days = Math.floor(this.countdown / (1000 * 60 * 60 * 24));
        this.hours = Math.floor(
            (this.countdown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        this.minutes = Math.floor(
            (this.countdown % (1000 * 60 * 60)) / (1000 * 60)
        );
        this.seconds = Math.floor((this.countdown % (1000 * 60)) / 1000);
      }
    },

  },
  mounted(){
    //数字转动
    const numberVFXElements = document.querySelectorAll('.numberVFX');
    function getRandomValue(max) {
      return Math.floor(Math.random() * (max + 1));
    }
    numberVFXElements.forEach((numberVFXElement) => {
      const originalValue = parseInt(numberVFXElement.textContent, 10);
      const hasPlusSign = numberVFXElement.textContent.includes("+");
      const decreasedValue = originalValue - getRandomValue(originalValue);
      const newValue = decreasedValue + (hasPlusSign ? "+" : "");
      numberVFXElement.textContent = newValue;
      numberVFXElement.classList.add('numberVFX');
      const interval = setInterval(() => {
        const currentValue = parseInt(numberVFXElement.textContent, 10);
        const newValue = currentValue + getRandomValue(originalValue - currentValue);
        numberVFXElement.textContent = newValue + (hasPlusSign ? "+" : "");
      }, 400);

      setTimeout(() => {
        numberVFXElement.textContent = originalValue + (hasPlusSign ? "+" : "");
        clearInterval(interval);
      }, 3000);
    });
    //结束


    // 导航固定
    $(window).on('scroll',function(){
      var w=$(document).scrollTop();//获取滚动条到顶部的垂直高度
      if(w>=1){
        $(".head").hide();
        // $("").css();
        $(".head-menu").css({"position":"fixed","top":"0px","left":"0px","z-index":"1000px","margin-top": "0px"});
      }else{

        $(".head").show();
        // $(".head-menu").css({"position":"fixed","top":"90px","left":"0px","z-index":"100px"});
        $(".head-menu").css({"position":"fixed","top":"0px","left":"0px","z-index":"1000px","margin-top": "93px"});

      }
    });
  }

};
</script>

<style scoped>
</style>
