export default {
  computed: {
    MY_CONSTANT() {
      return this.$store.state.MY_CONSTANT;
    }

  },
  watch:
      {
        MY_CONSTANT: {
          handler(newValue, oldValue) {

          },
          deep: true
        }
      }
   ,
    created() {

    },
    methods: {
   async setLang(lang,Refresh=false) {


       // 获取body内的所有元素
       // var body = document.body;
       // var elements = body.getElementsByTagName('*');
       const elements = document.querySelectorAll('a,p,h1,h2,h3,h4,h5,h6, span,label');

// 为每个元素添加class
       for (var i = 0; i < elements.length; i++) {
           // elements[i].classList.remove('animated');
           // void element.offsetWidth;
           // elements[i].classList.add('animated');
           // 检查元素是否有文本内容

           if (elements[i].textContent.trim() !== '') {

               // 如果没有文本内容，则移除特效类（可选）
               elements[i].classList.remove('animated');
               void elements[i].offsetWidth;
               elements[i].classList.add('animated');
           }

       }


       // setTimeout(()=>{
      // 公共点击事件处理方法
     localStorage.setItem("Accept-Language",lang);
     //如果需要刷新
     if(Refresh){this.$router.go(0);}


       this.$store.commit('updateMY_CONSTANTLang', lang);
     //更新标题
      setTimeout(()=>{this.setHeader();},500)

      // ;},10)

       //有表单错误显示的就清一下把，不然互换不了语言。。。
       if(this.$refs.ruleForm){ this.$refs.ruleForm.clearValidate()}

    //无刷新改变语言设置
       const changeLanguageInURL = (newLanguage) => {
           const url = new URL(window.location.href);
           const pathname = url.pathname.replace(/^\/[^\/]+/, `/${newLanguage}`);
           url.pathname = pathname;
           window.history.replaceState(null, null, url);
       }
       changeLanguageInURL(lang);

   },
  setHeader(title='',
            keyword= '',
            description= ''
            ){

          //改变标题
          document.title =title||this.MY_CONSTANT.Api_CONSTANT.SizeConfig['site_name_'+this.MY_CONSTANT.sqlWhereLang];
          // 设置页面关键字
          const keywords = document.querySelector('meta[name="keywords"]');
          keywords.content = keyword||this.MY_CONSTANT.Api_CONSTANT.SizeConfig['page_key_word_'+this.MY_CONSTANT.sqlWhereLang];
          // 设置页面描述
          const descriptions = document.querySelector('meta[name="description"]');
          descriptions.content =description||this.MY_CONSTANT.Api_CONSTANT.SizeConfig['page_desc_'+this.MY_CONSTANT.sqlWhereLang];

      },
        //封装动画
        apiIntersectionObserver(classnames) {

            const observer = new IntersectionObserver((entries) => {
                entries.forEach((entry, index) => {

                    if (entry.isIntersecting) {
                        entry.target.classList.add('middle-animated');
                        // 处理元素可见的逻辑
                    } else {
                        // 处理元素不可见的逻辑
                    }
                });
            });

            var body = document.body;
            var elements = body.querySelectorAll(classnames.map(className => '.' + className).join(','));
            this.$nextTick(() => {
                elements.forEach(element => {
                    observer.observe(element);
                });
            });



        }
  }

}