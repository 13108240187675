import { createRouter, createWebHistory,createWebHashHistory} from 'vue-router'
import { templatePlugin,MyConstant } from '../assets/js/globalFunctions.js';
import store from '../assets/js/store.js';
const routes = [
    // 404 路由配置
    {
        path: '/:catchAll(.*)',
        name: 'NotFound',
    },
{
    path: '/:lang?',
    name: 'Index',

    meta: { title: '首页' },
  }
 ,
  {
    path: '/:lang/SinglePage/:menu/:cat',
    name: 'SinglePage',

  },
    {
        path: '/:lang/NewsInfo/:article_id',
        name: 'NewsInfo',

    },

    {
        path: '/:lang/Famous/:year',
        name: 'Famous',

    }
    ,
    {
        path: '/:lang/FamousInfo/:year/:user_id',
        name: 'FamousInfo',

    }



]

//画蛇添足，但不得不画require.context动态报错
let files;
let template = templatePlugin()
// console.log(template);
switch (template) {
    case "mobile-en-us":
         files = require.context('../Pages/mobile-en-us/views', false, /\.vue$/);
        break;
    case "mobile-zh-cn":
         files = require.context('../Pages/mobile-zh-cn/views', false, /\.vue$/);
        break;
    case "pc-en-us":
         files = require.context('../Pages/pc-en-us/views', false, /\.vue$/);
        break;
    default:
         files = require.context('../Pages/pc-zh-cn/views', false, /\.vue$/);
        break;
}


files.keys().forEach(key => {
  const fileName = key.replace(/^\.\//, '').replace(/\.vue$/, '');
  if (fileName !== '') { // 如果文件名不为空
    const route = {
      path: (fileName === 'Index') ? '/:lang?' : `/:lang/${fileName}/`,
      name: `${fileName}`,
      meta: { cache: true },
      component: () => import(`../Pages/${template}/views/${fileName}.vue`),
      beforeEnter:async  (to, from, next) => {
            // 获取路由参数 lang，并设置项目语言

          const lang = MyConstant('apiLangArray').includes(to.params.lang) ? to.params.lang : MyConstant('setApiLang');
            //如果以前语言和路由语言不同，那就改变
          if(localStorage.getItem("Accept-Language")!=lang){
          store.commit('updateMY_CONSTANTLang',lang);
          }
            // 设置项目语言的
          localStorage.setItem("Accept-Language",lang);


            next();
        }
    };

    // 检查是否存在相同的 name
    const existingIndex = routes.findIndex(route => route.name === `${fileName}`);
    if (existingIndex !== -1) {
      // 合并已存在的路由对象
      routes[existingIndex] = {
        ...route,
        ...routes[existingIndex]
      };
    } else {
      routes.push(route);
    }
  }
});

   // console.log(routes);




//vue-cli-service lint   package.json修改后的
const router = createRouter({
   // history: createWebHistory(process.env.BASE_URL),//没有加速就用这个
  history: createWebHistory('/'),
  // createWebHistory没有# createWebHashHistory 有#
  routes,
  scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            // 如果有保存的位置，则恢复到保存的位置
            return savedPosition;
        } else {
            // 没有保存的位置，则滚动到顶部
            return { top: 0 };
        }
    },

})




export default router

