<template>

  <Components :datas='{
        "Template/Header": "",
      }'>
  </Components>

  <div class="loginContent">
    <div class="title">
      {{ MY_CONSTANT.Api_CONSTANT.menu.PublicMenu.exhibitor_info.list.login['name_'+MY_CONSTANT.sqlWhereLang] }}
    </div>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm"  class="demo-ruleForm" id="register_form">
      <el-form-item :label="MY_CONSTANT.Lang.Account+'：'" prop="user_name">
        <el-input size="large" class="my-input" v-model="ruleForm.user_name" :placeholder="MY_CONSTANT.Lang.AllLoginField" ></el-input>
      </el-form-item>
      <el-form-item :label="MY_CONSTANT.Lang.Password+'：'" prop="password">
        <el-input size="large" class="my-input" v-model="ruleForm.password" :placeholder="MY_CONSTANT.Lang.PleaseEnter+MY_CONSTANT.Lang.Password" show-password></el-input>
      </el-form-item>
      <el-button style="width: 100%;margin-top:41px;"  size="large"  @click="submitForm('ruleForm')" >{{ MY_CONSTANT.Lang.login }}</el-button>
      <div style="margin-top: 20px;">
        <custom-link  to="/UserForgotPassword" >{{ MY_CONSTANT.Lang.forgetAP }}</custom-link>
        <el-text style="padding:0px 10px; ">|</el-text>
        <custom-link to="/UserRegister">{{ MY_CONSTANT.Lang.register }}</custom-link>
<!--        <el-text style="float: right;" @click="setLang(MY_CONSTANT.setApiLang=='zh-cn'?'en-us':'zh-cn')"><el-icon><Switch /></el-icon>-->
<!--          {{ MY_CONSTANT.sqlWhereLang=='cn'?'EN':'中文' }}</el-text>-->
      </div>
    </el-form>
  </div>
  <br>
  <Components :datas='{
        "Template/Footer": "",
      }'>
  </Components>

</template>
<script>
import Components from '../../../modules/Components.vue'
import api from "@/assets/js/api";
import {decryptString} from "@/assets/js/globalFunctions";
export default {
  components: {
    Components
  },
  data() {
    return {
      newsInfo:[],
      menu:this.$MyConstant('Api_CONSTANT.menu.PublicMenu.exhibitor_info.list'),
      JumpPath:this.$route.query.s?this.$decryptString(this.$route.query.s):'/UserEdit',
      ruleForm: {
        user_name: '',
        password: '',

      },
      rules: {
        user_name: [
          { required: true, message: () => this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.AllLoginField'), trigger: 'blur'},

        ],
        password: [
          { required: true, message: () => this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.Password'), trigger: 'blur' },
          { min: 6, max: 30, message:() =>  this.$MyConstant('Lang.PasswordLength'), trigger: 'blur' },

        ]


      }
    }
  },
  created() {
    ;
//如果登录就直接跳
    let that=this

    if(this.$getCookie()){
      this.$message.success({message:this.$MyConstant('Lang.loginEd'),offset:250,duration:2000});
      setTimeout(function (){
        that.$router.push('/'+that.MY_CONSTANT.setApiLang+that.JumpPath)
      },2000)

    }
  },
  methods: {
   async submitForm(formName) {
     let that=this
     await this.$refs[formName].validate((valid) => {
        if (valid) {
          api.ajaxbaseUrl.post('/api/User/Login',this.ruleForm,{headers:{'Accept-Language':this.MY_CONSTANT.setApiLang}})
              .then(response => {
                // 处理响应数据
                if(response&&response.data.err==0){
                  this.$cookies.set("Cookie", response.data.obj.data, this.MY_CONSTANT.Login.expires);
                  this.$message.success({message:this.$MyConstant('Lang.loginSuccess'),offset:250,duration:3000});

                  setTimeout(function (){ window.location.href='/'+that.MY_CONSTANT.setApiLang+that.JumpPath;},2000)




                }
              })
              .catch(error => {
                // 处理错误
                console.log(error);
              })


        }
      });

    },

  }
};
</script>
<style scoped>
.loginContent{
  margin: 0 38px;
}
.loginContent .title{
  font-size: 15px;
  color: #606060;
  margin-bottom: 26px;
  margin: 26px 0px ;
}
:deep(.el-input__inner){
  font-size: 13px;
}
/deep/.el-input{
  border-radius: 0;
}

/deep/.el-form-item--small{
  margin-bottom: 30px;

}
/deep/.el-form-item--small .el-form-item__label {
  display: none;

}
/deep/.el-form-item__error {
  padding-left: 10px;
  font-size: 12px;
margin: 10px 0px;
}

/deep/.el-form-item.is-error .el-input__wrapper.is-focus{
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14)!important;
}
/*:deep(.el-form-item.is-error .el-input__wrapper.is-focus) { box-shadow: none !important;*/
/*}*/
/deep/.el-form-item.is-error .el-input__wrapper {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14);
}
/deep/.el-input__wrapper{
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14);
}


</style>
