import { createApp } from 'vue';
import ElementPlus from 'element-plus'
import './assets/css/global.css'
import VueCookies from "vue-cookies";
import store from './assets/js/store.js';
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import App from './App.vue';
import router from "./router/router.js"
import globalFunctionsPlugin from './assets/js/globalFunctionsPlugin.js';
//vuex变量放这
import globalComputedMixin from './assets/js/globalComputedMixin.js';


import CustomLink from './modules/CustomLink';

import 'viewerjs/dist/viewer.css';
import Viewer from 'viewerjs';

// import SwiperClass, {  } from 'Swiper'
// import VueAwesomeSwiper from 'vue-awesome-swiper'


import 'element-plus/theme-chalk/index.css';




// 创建app
const app = createApp(App)

app.config.globalProperties.$viewer = Viewer;
// app.config.globalProperties.$cookies = VueCookies;

app.use(VueCookies);
app.use(globalFunctionsPlugin);
app.use(store);
app.mixin(globalComputedMixin);
// 去除warn
app.config.warnHandler=()=>null



for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}



// import swiper module styles
// import 'swiper/css'
import vueAwesomeSwiper from "vue-awesome-swiper";
// more module style...

// use swiper modules
// SwiperClass.use([])


// app.use(VueAwesomeSwiper)
// 注入路由
app.use(router)

app.use(ElementPlus, { size: 'small', zIndex: 3000 ,
  locale: zhCn})

//a标签
app.component('custom-link', CustomLink);

// 挂载实例
app.mount('#app')








