import axios from 'axios';
import { MY_CONSTANT } from './constants.js';
import { encryptString } from './globalFunctions.js';
import { ElMessage } from 'element-plus';


// 创建一个 axios 实例
const ajax = axios.create({

});

// 创建一个 axios 实例
const ajaxbaseUrl = axios.create({
    baseURL: MY_CONSTANT.Api_CONSTANT.baseUrl,
    headers:MY_CONSTANT.Api_CONSTANT.baseApiHeaders
    // timeout: 5000, // 请求超时时间
});

// 请求拦截器
// ajaxbaseUrl.interceptors.request.use(config => {
//   // 添加自定义的请求头
//   // localStorage.getItem('Authorization')
//     config.headers=MY_CONSTANT.Api_CONSTANT.baseApiHeaders
//   // config.headers['Authorization'] = MY_CONSTANT.apiHeaders.Authorization;
//   // config.headers['access-token'] =getCookie('token');
//   // config.headers['Accept-Language']=localStorage.getItem("Accept-Language")??'zh-cn';
//   // 其他请求头的逻辑...
//   return config;
// }, error => {
//   return Promise.reject(error);
// });

// 添加响应拦截器
// 定义一个变量来标记是否已经执行过ElMessage
let isMessageShown = false;
ajaxbaseUrl.interceptors.response.use(function (response) {
    // 对返回的数据进行处理
    if(response.data.err==1003&& !isMessageShown){
        //要登录
        // 将标记设置为true，表示已经执行过ElMessage
        isMessageShown = true;
        ElMessage.warning({message:response.data.msg,offset:400,duration:3000});
        $cookies.remove("Cookie");
        // 获取当前网页的路径部分
        var path = window.location.pathname;
// 获取当前网页的查询参数部分
        var search = window.location.search;
// 拼接路径和查询参数
        var fullPath = path + search;
        setTimeout(function (){ window.location.href='/'+localStorage.getItem("Accept-Language")+'/UserLogin?s='+encryptString(fullPath.substring(6)) },2000)
        // return false;
    }
    //拦截错误直接返回
    if(response.data.err==1004){
        ElMessage.error({message:response.data.msg,offset:400,duration:3000});return false;
    }

    if(response.data.httpcode!=200){
        ElMessage.error({message:'网络出现小差',offset:400,duration:2000}); return false;
    }

    return response;
}, function (error) {
    return Promise.reject(error);
});

export function ajaxbaseUrlget(url,params){
    return   ajaxbaseUrl.get(url,{params});
}

export async function ajaxbaseUrlData(url,params,way='get'){
    const data = await (way === 'get' ? ajaxbaseUrl.get(url, { params }).then(response=>{
        return response.data.obj.data
    }): ajaxbaseUrl.post(url, params).then(response=>{
        return response.data.obj.data
    }));
    // console.log(data);
    return data;
}


// 资讯分类数据查询
export function getNewsData(params){

    return   ajaxbaseUrl.get('/api/Article/s',{params});

}

// 资讯分类查询
export function getNewsInfo(params){

    return   ajaxbaseUrl.get('/api/Article/info',{params});

}

// 资讯分类查询
export function getNewsCateInfo(params){

    return   ajaxbaseUrl.get('/api/sub.cate/info',{params});

}

// 导出你的接口方法
export default {
    ajaxbaseUrl,
    ajax,
    ajaxbaseUrlData,
    ajaxbaseUrlget,
    getNewsData,
    getNewsInfo,

};

