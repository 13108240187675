<template>
  <div class="banner">
    <div class="swiper-container swiper-container1">
      <div class="swiper-wrapper">

        <div class="swiper-slide">
          <img src="../../../assets/img/sch.png">
        </div>
      </div>
    </div>
  </div>

  <div class="about">
    <div class="about-top">
      <p>{{ this.$MyConstant('Api_CONSTANT.menu.Usermenu.exhibition.name_'+MY_CONSTANT.sqlWhereLang) }}</p>
      <img src="../../../assets/img/icon/jiantou.png">
      <em>{{ this.$MyConstant('Api_CONSTANT.menu.Usermenu.exhibition.list.edit_password.name_'+MY_CONSTANT.sqlWhereLang) }}</em>

    </div>
    <div class="contentMiddle">
      <!-- 左边 -->
      <div class="contentLeft">
        <div class="reg-hd">
          <h3>{{ this.$MyConstant('Api_CONSTANT.menu.Usermenu.exhibition.list.edit_password.name_'+MY_CONSTANT.sqlWhereLang) }}</h3>
        </div>

        <el-form :model="ruleForm" :rules="rules" ref="ruleForm"  class="demo-ruleForm" id="register_form">

          <el-form-item :label="MY_CONSTANT.Lang.OldPassword+'：'"  prop="oldpassword">
            <el-input class="input400" v-model="ruleForm.oldpassword" :placeholder="this.$MyConstant('Lang.PleaseEnter')" show-password></el-input>
          </el-form-item>

          <el-form-item :label="MY_CONSTANT.Lang.NewPassword+'：'"  prop="password">
            <el-input class="input400" v-model="ruleForm.password" :placeholder="this.$MyConstant('Lang.PleaseEnter')" show-password></el-input>
          </el-form-item>

          <el-form-item  :label="MY_CONSTANT.Lang.Confirm+' '+this.$MyConstant('Lang.NewPassword')+'：'" prop="checkPass">
            <el-input class="input400" v-model="ruleForm.checkPass" :placeholder="this.$MyConstant('Lang.PleaseEnter')" show-password></el-input>
          </el-form-item>


          <el-form-item :label="'\n'" >
            <el-button type="danger"  @click="submitForm('ruleForm')" >提交</el-button>
          </el-form-item>


        </el-form>
      </div>


      <!-- 右边 -->
      <div class="about-right">
        <div class="about-right1" v-for="(item,key) in this.$MyConstant('Api_CONSTANT.menu.Usermenu.exhibition.list')">
          <custom-link :style="{'color': 'edit_password' == key ? 'var(--uavdeep-color)' : ''}" :to="item.url">
            <img src="../../../assets/img/icon/sanjiao.png">
            <em>{{ item['name_'+MY_CONSTANT.sqlWhereLang] }}</em>
          </custom-link>
        </div>

        <Components :datas= '{
        "Template/About-right": "",
         }'>
        </Components>

      </div>

    </div>

  </div>

</template>

<script>
import Components from '../../../modules/Components.vue'
import api from "@/assets/js/api";
export default {
  components: {
    Components
  },
  data() {
    const validateNewPass= (rule, value, callback) => {
      if(value==this.ruleForm.oldpassword){
        callback(new Error(this.$MyConstant('Lang.NewOldRepeat')));
      }
      return  callback();
    };

    const validateTruePass= (rule, value, callback) => {
      if(value!=this.ruleForm.password){
        callback(new Error(this.$MyConstant('Lang.TwoPassword')));
      }
      return  callback();
    };
    return {
      ruleForm: {
        oldpassword:'',
        password: '',
        checkPass: '',
      },
      rules: {
        oldpassword: [
          {required: true, message: () =>  this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.OldPassword'), trigger: 'blur'},
          {min: 6, max: 30, message: () => this.$MyConstant('Lang.PasswordLength'), trigger: 'blur'},
        ],
        password: [
          {required: true, message: () =>  this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.NewPassword'), trigger: 'blur'},
          {min: 6, max: 30, message: () => this.$MyConstant('Lang.PasswordLength'), trigger: 'blur'},
          { validator: validateNewPass, trigger: 'blur' },
        ],
        checkPass: [
          {required: true, message: () =>  this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.NewPassword'), trigger: 'blur'},
          {min: 6, max: 30, message: () => this.$MyConstant('Lang.PasswordLength'), trigger: 'blur'},
          { validator: validateTruePass, trigger: 'blur' },
        ]
      }
    }
  },

  methods: {
    async submitForm(formName) {
      console.log(this);
      await this.$refs[formName].validate((valid) => {

        if (valid) {
          api.ajaxbaseUrl.post('/api/User/ChangePassword',this.ruleForm,{headers:{'Accept-Language':this.MY_CONSTANT.setApiLang}})
              .then(response => {
                if(response.data.err==0) this.$message.success(response.data.msg);
              })

        }
      });

    },

  }
};
</script>
<style scoped>


</style>
