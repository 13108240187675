// globalFunctionsPlugin.js

import {
    getApiWhere,
    formatTime,
    getCookie,
    empty,
    MyConstant,
    stringStarShow,
    encryptString,
    decryptString,
    templatePlugin,
    ExhibitionDate,
    equipment
} from './globalFunctions.js';

export default {
    install(app) {
        // 设置为全局属性
        app.config.globalProperties.$stringStarShow = stringStarShow;
        app.config.globalProperties.$MyConstant = MyConstant;
        app.config.globalProperties.$getApiWhere = getApiWhere;
        app.config.globalProperties.$formatTime = formatTime;
        app.config.globalProperties.$getCookie = getCookie;
        app.config.globalProperties.$empty = empty;
        app.config.globalProperties.$encryptString = encryptString;
        app.config.globalProperties.$decryptString = decryptString;
        app.config.globalProperties.$templatePlugin = templatePlugin;
        app.config.globalProperties.$ExhibitionDate = ExhibitionDate;
        app.config.globalProperties.$equipment = equipment;
        // 或者设置为原型属性
        app.provide('stringStarShow', stringStarShow);
        app.provide('MyConstant', MyConstant);
        app.provide('getApiWhere', getApiWhere);
        app.provide('formatTime', formatTime);
        app.provide('getCookie', getCookie);
        app.provide('empty', empty);
        app.provide('encryptString', encryptString);
        app.provide('decryptString', decryptString);
        app.provide('templatePlugin', templatePlugin);
        app.provide('ExhibitionDate', ExhibitionDate);
        app.provide('equipment', equipment);
    }
}
