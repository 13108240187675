<template>
  <div class="banner">
    <div class="swiper-container swiper-container1">
      <div class="swiper-wrapper">

        <div class="swiper-slide">
          <img src="../../../assets/img/sch.png">
        </div>
      </div>
    </div>
  </div>

  <div class="about">
    <div class="about-top">
      <p>{{ this.$MyConstant('Api_CONSTANT.menu.PublicMenu.famous.name_'+MY_CONSTANT.sqlWhereLang) }}</p>
      <img src="../../../assets/img/icon/jiantou.png">
      <em>{{this.$route.query.year}}{{ this.$MyConstant('Api_CONSTANT.menu.PublicMenu.famous.name_'+MY_CONSTANT.sqlWhereLang) }}</em>

    </div>
    <div class="contentMiddle">
      <!-- 左边 -->
      <div class="contentLeft">

          <div class="list-recommend">
            <div>
              <img :src="info.logo">
            </div>
            <ul>
              <li v-if="info.WithUser.cp_name">
                <em>{{ this.$MyConstant('Lang.CCompanyName') }}：</em>
                {{ info.WithUser.cp_name}}
              </li>
              <li v-if="info.WithUser.cp_en_name">
                <em>{{ this.$MyConstant('Lang.ECompanyName') }}：</em>
                {{ info.WithUser.cp_en_name}}
              </li>

              <li>
                <em>{{ this.$MyConstant('Lang.product_type') }}：</em>
                {{ info.WithUser.product_type }}
              </li>

              <li>
                <em>{{ this.$MyConstant('Lang.main_exhibit') }}：</em>
                {{ info.WithUser.main_exhibit }}
              </li>
              <li>
                <em>{{ this.$MyConstant('Lang.hall') }}：</em>
                {{ info.hall }}
              </li>
              <li>
                <em>{{ this.$MyConstant('Lang.BoothNumber') }}：</em>
                {{ info.exhibition }}
              </li>
<!--              <li>-->
<!--                <em>{{ this.$MyConstant('Lang.ContactAddress') }}：</em>-->
<!--                {{ info.WithUser.link_address }}-->
<!--              </li>-->
<!--              <li>-->
<!--                <em>{{ MY_CONSTANT.Lang.Cphone }}：</em>-->
<!--                <a :href="'tel:'+info.WithUser.phone1+'-'+info.WithUser.phone">{{ info.WithUser.phone1+'-'+info.WithUser.phone }}</a>-->
<!--              </li>-->
<!--              <li>-->
<!--                <em>E-mail：</em>-->
<!--                <a target="_blank" :href="'mailto:'+info.WithUser.email">{{ info.WithUser.email }}</a>-->

<!--              </li>-->
<!--              <li>-->
<!--                <em>{{ this.$MyConstant('Lang.site') }}：</em>-->
<!--                <a target="_blank" :href="info.WithUser.website && info.WithUser.website.includes('http') ? info.WithUser.website : '//' + (info.WithUser.website || '')">{{ info.WithUser.website }}</a>-->
<!--              </li>-->
              <li>
                <em>{{ this.$MyConstant('Lang.CompanyIntroduction') }}:</em>
                <br>
                {{ this.$MyConstant('sqlWhereLang')=='cn'?info.WithUser.cp_profile:info.WithUser.cp_en_profile }}
              </li>
            </ul>


          </div>


      </div>

      <!-- 右边 -->
      <div class="about-right">
        <div class="about-right1" v-for="(item,key) in this.$MyConstant('Api_CONSTANT.menu.PublicMenu.famous.list')">
          <custom-link :style="{'color': this.$route.params.year == item.url.slice(-4) ? 'var(--uavdeep-color)' : ''}" :to="item.url">
            <img src="../../../assets/img/icon/sanjiao.png">
            <em>{{ item['name_'+MY_CONSTANT.sqlWhereLang] }}</em>
          </custom-link>
        </div>

        <Components :datas= '{
        "Template/About-right": "",
         }'>
        </Components>

      </div>

    </div>

  </div>

</template>

<script>
import Components from '../../../modules/Components.vue'
import api from "@/assets/js/api";
import {decryptString} from "@/assets/js/globalFunctions";
export default {
  components: {
    Components
  },
  data() {
    return {
      info: {WithUser:{cp_name:''}},
      year:this.$route.params.year,
    }
  },
  async  created() {

    let info = await api.ajaxbaseUrlData('/api/User/ShowInfo',{
      user_id:this.$decryptString(this.$route.params.user_id),
      year:this.$route.params.year
    });

    info.logo=info.logo.includes('http')?info.logo:this.$MyConstant('StaticPath')+info.logo

    this.info=info

  },

};
</script>
<style scoped>
/* 2.展商的详情 */

 .list-recommend{

}
 .list-recommend div{
  display: flex;
  justify-content: center;
  margin-top: 52px;
  margin-bottom: 39px;
}
 .list-recommend div img{
  display: block;
  width: 200px;
}
 .list-recommend ul li{

  opacity: 1;
  font-size: 15px;

  font-weight: 500;

  color: #8b8b8b;
  line-height: 32px;
}
 .list-recommend ul li a{
  opacity: 1;
  font-size: 15px;

  font-weight: 500;

  color: #8b8b8b;
  line-height: 32px;
}
 .list-recommend ul li a:hover{
  color: #AF1E22;
}
 .list-recommend ul li:last-child{

  margin-top: 30px;
}


</style>
