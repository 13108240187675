<template>
  <div class="banner">
    <div class="swiper-container swiper-container1">
      <div class="swiper-wrapper">

        <div class="swiper-slide">
          <img src="../../../assets/img/sch.png">
        </div>
      </div>
    </div>
  </div>

  <div class="about">
    <div class="about-top">
      <p>{{ MY_CONSTANT.Api_CONSTANT.menu.PublicMenu.news['name_'+MY_CONSTANT.sqlWhereLang]}}</p>
      <img src="../../../assets/img/icon/jiantou.png">
      <em>{{MY_CONSTANT.Api_CONSTANT.menu.PublicMenu.news.list[this.$route.query.cat_id==3?'tonggao':'news']['name_'+MY_CONSTANT.sqlWhereLang]}}</em>

    </div>
    <div class="about_news_detail-bottom middle">

      <!-- 左边 -->
      <div class="news_detail-left">
        <h2>{{ newsInfo.title }}</h2>
            <div class="detail-content" v-html="newsInfo.content">
        </div>
      </div>

      <!-- 右边 -->
      <div class="about-right">
        <div class="about-right1" v-for="(item,key) in menu">
        <custom-link :style="{'color': 'news' == key ? 'var(--uavdeep-color)' : ''}" :to="item.url">
            <img src="../../../assets/img/icon/sanjiao.png">
            <em>{{ item['name_'+MY_CONSTANT.sqlWhereLang] }}</em>
          </custom-link>
        </div>

        <Components :datas= '{
        "Template/About-right": "",
         }'>
        </Components>

      </div>

    </div>

  </div>

</template>

<script>
import Components from '../../../modules/Components.vue'
import api from "@/assets/js/api";
export default {
  components: {
    Components
  },
  data() {
    return {
       newsInfo:[],
       menu:this.$MyConstant('Api_CONSTANT.menu.PublicMenu.news.list')
    }
    },
  created() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      const response = await api.getNewsInfo({id:this.$route.params.article_id});

      const modifiedContent = response.data.obj.data.content.replace(/<img[^>]+src=['"](?!http)[^'"]+['"]/g, (match) => {
        const src = match.match(/src=['"]([^'"]+)['"]/)[1];
        const domain = this.$MyConstant('StaticPath'); // 替换为您的域名
        return match.replace(src, domain + src);
      });

      response.data.obj.data.content= modifiedContent;
      this.newsInfo=response.data.obj.data

    }
  }
};
</script>
<style scoped>

.about_news_detail-bottom {
  display: flex;
  justify-content: space-between;
  margin-bottom: 134px;
}

.about_news_detail-bottom .news_detail-left {
  width: 75%;
  margin-top: 54px;
}

.news_detail-left {}

.news_detail-left h2 {
  text-align: center;
  padding-bottom: 20px;
  border-bottom: 2px solid #F4F4F4;
}

.news_detail-left .detail-content {
  margin-top: 30px;
}



</style>
