<template>

    <a v-if="isExternal" :href="to" target="_blank" :class="linkClass" :style="linkStyle">
      <slot></slot>
    </a>
    <router-link v-else :to="'/'+MY_CONSTANT.setApiLang+to" :class="linkClass" :style="linkStyle">
      <slot></slot>
    </router-link>

</template>

<script>
export default {
  name: 'CustomLink',
  props: {
    to: {
      type: String,
      required: true
    },
    class: {
      type: [String, Object, Array]
    },
    style: {
      type: [String, Object, Array]
    }
  },
  computed: {
    isExternal() {

      if (typeof this.to === 'object') {
        return false;
      }
      return this.to?this.to.startsWith('http') || this.to.startsWith('//'):true;
    },
    linkClass() {
      return this.class;
    },
    linkStyle() {
      return this.style;
    }
  }
}
</script>