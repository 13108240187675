<template>
  <div class="banner">
    <div class="swiper-container swiper-container1">
      <div class="swiper-wrapper">

        <div class="swiper-slide">
          <img src="../../../assets/img/sch.png">
        </div>
      </div>
    </div>
  </div>

  <div class="about">
    <div class="about-top">
      <p>{{ this.$MyConstant('Api_CONSTANT.menu.PublicMenu.'+this.$route.params.menu+'.name_'+MY_CONSTANT.sqlWhereLang) }}</p>
      <img src="../../../assets/img/icon/jiantou.png">
      <em v-for="(item,key) in menu"><span  v-if="this.$route.params.cat == key">{{ item['name_'+MY_CONSTANT.sqlWhereLang] }}</span></em>

    </div>
    <div class="about-bottom middle">

      <!-- 左边 -->
      <div class="about-left" v-html="this.$MyConstant('sqlWhereLang')=='cn'?content:en_content">

      </div>
      <!-- 右边 -->
      <div class="about-right">
        <div class="about-right1" v-for="(item,key) in menu">
        <custom-link :style="{'color': this.$route.params.cat == key ? 'var(--uavdeep-color)' : ''}" :to="item.url">
            <img src="../../../assets/img/icon/sanjiao.png">
            <em>{{ item['name_'+MY_CONSTANT.sqlWhereLang] }}</em>
          </custom-link>
        </div>

        <Components :datas= '{
        "Template/About-right": "",
         }'>
        </Components>

      </div>

    </div>

  </div>

</template>

<script>
import Components from '../../../modules/Components.vue'
import api from "@/assets/js/api";
export default {
  components: {
    Components
  },
  data() {
    return {
       content:'',
       en_content:'',
       menu:null
    }
    },
  methods: {
    async fetchData() {
      const response = await    api.ajaxbaseUrlget('/api/page/GetPage',this.$route.params);

// 使用正则表达式匹配并修改所有 <img> 元素的 src 属性
      const modifiedContent = response.data.obj.data.content.replace(/<img[^>]+src=['"](?!http)[^'"]+['"]/g, (match) => {
        const src = match.match(/src=['"]([^'"]+)['"]/)[1];
        const domain = this.$MyConstant('StaticPath'); // 替换为您的域名
        return match.replace(src, domain + src);
      });

      this.content = modifiedContent;

      // 使用正则表达式匹配并修改所有 <img> 元素的 src 属性
      const modifiedContenten = response.data.obj.data.en_content.replace(/<img[^>]+src=['"](?!http)[^'"]+['"]/g, (match) => {
        const src = match.match(/src=['"]([^'"]+)['"]/)[1];
        const domain = this.$MyConstant('StaticPath'); // 替换为您的域名
        return match.replace(src, domain + src);
      });

      this.en_content = modifiedContenten;

    }
  },
  watch: {
    '$route': {
      immediate: true, // 初始化时立即执行
      handler() {
        this.menu=this.$MyConstant('Api_CONSTANT.menu.PublicMenu.'+this.$route.params.menu+'.list')
        // 当路由参数变化时，重新请求接口并更新内容
        this.fetchData();
      }
    }
  },
};
</script>

