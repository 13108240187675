<template>

  <img style="width: 100%;height: 112px;" src="../../../assets/img/sch.png">

  <div className="middle">
    <div className="container">

      <p>{{ msg }}</p>
    </div>

  </div>

</template>

<script>
import api from "@/assets/js/api";

export default {

  data() {
    return {
      msg: ''
    }
  }
  ,
  async created() {
    this.msg=this.MY_CONSTANT.Lang.LogoutIng + '....'
    let that = this
    await api.ajaxbaseUrlget('/api/User/logout').then(response => {
      // 处理响应数据
      if (response.data.err == 0) {

        $cookies.remove("Cookie");
        setTimeout(function () {
          that.msg = that.MY_CONSTANT.Lang.LogoutSuccess + '。。。'
        }, 1000)
        // this.$message.warning({message:response.data.msg,offset:250,duration:3000});

      }

      setTimeout(function () {

        window.location.href = '/'
      }, 2000)


    })
  }
};
</script>

<style scoped>

.container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  margin-top: 400px;
  margin: 0 auto;
}

h1 {
  font-size: 24px;
  color: #666;
  margin-bottom: 20px;
}

p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 10px;
}
</style>
