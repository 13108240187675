<template>
  <div v-if="MY_CONSTANT.sqlWhereLang=='cn'" class="not-found">
    <h1>404</h1>
    <h2>页面未找到</h2>
    <p>抱歉，您访问的页面不存在。</p>
    <custom-link to="/">返回首页</custom-link>
  </div>
  <div v-else class="not-found">
    <h1>404</h1>
    <h2>Page Not Found</h2>
    <p>Sorry, the page you are looking for does not exist.</p>
    <custom-link to="/">Go back to Homepage</custom-link>
  </div>
</template>

<script>
import Components from '../../../modules/Components.vue'
import api from "@/assets/js/api";
export default {
  components: {
    Components
  },
  data() {
    return {

    }
  },
  async  created() {

  },
  methods: {

  }
};
</script>
<style scoped>
.not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 350px;
  text-align: center;
  font-family: Arial, sans-serif;
}

h1 {
  font-size: 5rem;
  margin-bottom: 1rem;
}

h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

a {
  font-size: 1.2rem;
  padding: 0.5rem 1rem;
  background-color: var(--uavmedium-color);
  color: #fff;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

a:hover {
  background-color: var(--uavdeep-color);
}
</style>
