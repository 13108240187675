<template>
  <Components :datas='{
        "Template/Header": "",
      }'>
  </Components>

  <div class="loginContent">
    <div class="title">
      {{ MY_CONSTANT.Lang.exhibitionRegister}}
    </div>
        <el-form v-if="MY_CONSTANT.Api_CONSTANT.SizeConfig.site_reg_closed==0" :model="ruleForm" :rules="rules" ref="ruleForm"  class="demo-ruleForm" id="register_form">
          <el-form-item v-if="MY_CONSTANT.sqlWhereLang=='cn'" :label="this.$MyConstant('Lang.CompanyName')+'：'" prop="cp_name">
            <el-input size="large" class="err400"  v-model="ruleForm.cp_name" :placeholder="MY_CONSTANT.Lang.exampleCCompanyName" onkeyup=""></el-input>
          </el-form-item>
          <el-form-item v-if="MY_CONSTANT.sqlWhereLang=='en'" :label="this.$MyConstant('Lang.ECompanyName')+'：'" prop="cp_en_name">
            <el-input size="large" class="err400"  v-model="ruleForm.cp_en_name" :placeholder="MY_CONSTANT.Lang.exampleECompanyName" onkeyup=""></el-input>
          </el-form-item>
          <br v-if="huanhang">
          <el-form-item label="E-mail：" prop="email">
            <el-input size="large"  v-model="ruleForm.email" :placeholder="MY_CONSTANT.Lang.PleaseEnter+MY_CONSTANT.Lang.Email"></el-input>
          </el-form-item>
          <el-form-item :label="this.$MyConstant('Lang.Password')+'：'"  prop="password">
            <el-input size="large"  v-model="ruleForm.password" :placeholder="MY_CONSTANT.Lang.PleaseEnter+MY_CONSTANT.Lang.Password" show-password></el-input>
          </el-form-item>

          <el-form-item :label="this.$MyConstant('Lang.repeatPassword')+'：'"  prop="re_password">
            <el-input size="large"  v-model="ruleForm.re_password" :placeholder="MY_CONSTANT.Lang.PleaseEnter+MY_CONSTANT.Lang.repeatPassword" show-password></el-input>
          </el-form-item>



    <el-button style="width: 100%;margin-top:41px;"  size="large"  @click="submitForm('ruleForm')" >{{ MY_CONSTANT.Lang.register }}</el-button>
    <div style="margin-top: 20px;">
      <custom-link  to="/UserLogin?s=0VtfsSftfswf" >{{ MY_CONSTANT.Lang.backlogin }}</custom-link>

<!--      <el-text style="float: right;" @click="setLang(MY_CONSTANT.setApiLang=='zh-cn'?'en-us':'zh-cn')"><el-icon><Switch /></el-icon>-->
<!--        {{ MY_CONSTANT.sqlWhereLang=='cn'?'EN':'中文' }}</el-text>-->

    </div>
   </el-form>
  </div>

  <br>
  <Components :datas='{
        "Template/Footer": "",
      }'>
  </Components>


</template>

<script>
import Components from '../../../modules/Components.vue'
import {validateUser} from "@/assets/js/validation";
import api from "@/assets/js/api";
export default {
  components: {
    Components
  },
  data() {
    let validatePass2=((rule,value,callback)=>{
      if(value !=this.ruleForm.password){
        callback(new Error(this.$MyConstant('Lang.TwoPassword')));
      }else{
        callback();
      }
    });

    let validateCompanyName= async (rule, value, callback) => {

      const res = await  api.ajaxbaseUrl.post('/api/User/registerVerification', {field_value: '{"'+rule.field+'":"'+value+'"}'})
          .then(response => {
            return response;
          });
      if(res.data.err>0){
        let that=this
        setTimeout(function (){
          if(that.$MyConstant('sqlWhereLang')=='en'){that.huanhang=true}

          let element = document.getElementById('fanhui');
          if (!element){
            $('.err400~.el-form-item__error').append('<a id="fanhui" href="/UserLogin?s=0VtfsSftfswf">'+ '>>'+that.$MyConstant('Lang.backlogin')+'</a>');

          }

        },500);
        callback(new Error(that.$MyConstant('Lang.repeatCp_name')));return;

      }
      this.huanhang=false
      callback();

    };
    return {
      huanhang:false,
      ruleForm: {
        user_name: '',
        email: '',
        password: '',

      },
      rules: {
        cp_name: [
          { required: true, message:()=>  this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.CompanyName'), trigger: 'blur'},
          { min: 2, message: ()=> this.$MyConstant('Lang.CompanyName')+this.$MyConstant('Lang.Wronglength'), trigger: 'blur' },
          { validator: validateCompanyName, trigger: 'blur' }
        ],
        cp_en_name: [
          { required: true, message:  ()=>  this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.CompanyName'), trigger: 'blur'},
          { min: 2, message: ()=> this.$MyConstant('Lang.CompanyName')+this.$MyConstant('Lang.Wronglength'), trigger: 'blur' },
          { validator: validateCompanyName, trigger: 'blur' }
        ],
        email: [
          { required: true, message: ()=>  this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.Email'), trigger: 'blur'},
          { type: 'email', message:()=>  this.$MyConstant('Lang.PleaseCorrect')+this.$MyConstant('Lang.Email'), trigger: ['blur', 'change'] },
          { validator: validateUser, trigger: 'blur' }
        ],
        password: [
          { required: true, message: ()=>  this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.Password'), trigger: 'blur' },
          { min: 6, max: 30, message: ()=> this.$MyConstant('Lang.PasswordLength'), trigger: 'blur' },

        ],
        re_password: [
          { required: true, message: ()=>  this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.repeatPassword'), trigger: 'blur' },
          { validator: validatePass2, trigger: 'blur' }

        ]


      }
    }
  },
  created() {
    ;
    if(this.$MyConstant('Api_CONSTANT.SizeConfig.site_reg_closed')==1){
      this.$message.error({message:this.$MyConstant('Lang.site_reg_closed'),offset:250,duration:10000});return;
    }
  },
  methods: {
    async submitForm(formName) {
      await this.$refs[formName].validate((valid) => {
        if(this.$MyConstant('Api_CONSTANT.SizeConfig.site_reg_closed')==1){
          this.$message.error(this.$MyConstant('Lang.site_reg_closed'));return;
        }
        if (valid) {
          api.ajaxbaseUrl.post('/api/User/Register',this.ruleForm,{headers:{'Accept-Language':this.MY_CONSTANT.setApiLang}})
              .then(response => {
                // 处理响应数据
                if(response&&response.data.err==0){
                  this.$message.success({message:response.data.msg,offset:250,duration:3000});

                  //直接把登录也搞进去了
                  api.ajaxbaseUrl.post('/api/User/Login', {
                    user_name:this.ruleForm.cp_name??this.ruleForm.cp_en_name,
                    password:this.ruleForm.password
                  },{headers:{'Accept-Language':this.MY_CONSTANT.setApiLang}})
                      .then(response => {
                        // 处理响应数据
                        if(response&&response.data.err==0){
                          this.$cookies.set("Cookie", response.data.obj.data, this.$MyConstant('Login.expires'));
                          let that=this
                          setTimeout(function (){ window.location.href='/'+that.MY_CONSTANT.setApiLang+'/UserReserve';},2000)

                        }
                      })


                };
              })

        }
      });

    },

  }
};
</script>
<style scoped>
.loginContent{
  margin: 0 38px;
}
.loginContent .title{
  font-size: 15px;
  color: #606060;
  margin-bottom: 26px;
  margin: 26px 0px ;
}
:deep(.el-input__inner){
  font-size: 13px;
}
/deep/.el-input{
  border-radius: 0;
}

/deep/.el-form-item--small{
  margin-bottom: 30px;

}
/deep/.el-form-item--small .el-form-item__label {
  display: none;

}
/deep/.el-form-item__error {
  padding-left: 10px;
  font-size: 12px;
margin: 10px 0px;
}

/deep/.el-form-item.is-error .el-input__wrapper.is-focus{
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14)!important;
}
/*:deep(.el-form-item.is-error .el-input__wrapper.is-focus) { box-shadow: none !important;*/
/*}*/
/deep/.el-form-item.is-error .el-input__wrapper {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14);
}
/deep/.el-input__wrapper{
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14);
}

/deep/#fanhui{
  color: var(--uavdeep-color);
  text-decoration: underline;
}

</style>
